import type { Coordinates } from '@soilsense/shared';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import 'moment-timezone';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import type { NameMap, TransformedData } from '../../../dataHandlers/SensorTransformer';
import { getChartColors } from './Config';
import type { ChartZoom, DatasetSpec, TimeSeriesSpec } from './TimeSeriesChart';
import { TimeSeriesChart } from './TimeSeriesChart';
import { calculateNightTimeAnnotations } from './utils/calculateNightTimeAnnotations';

export type MmChartData = Readonly<{
  data: readonly TransformedData[];
}>;

type Props = {
  mmChartData: MmChartData;
  chartZoom: ChartZoom;
  nameMap: NameMap;
  coordinates: Coordinates;
};

export const ChartJsMmChart: React.FC<Props> = observer(({ mmChartData, chartZoom, nameMap, coordinates }) => {
  const { data } = mmChartData;
  const intl = useIntl();

  const timeSeriesSpec = useMemo((): TimeSeriesSpec => {
    const { moistureColor } = getChartColors();
    const labels: number[] = [];
    const waterContentSpec: DatasetSpec = {
      label: intl.formatMessage({ id: 'water_content_mm', defaultMessage: 'Water Content (mm)' }),
      data: [],
      color: moistureColor.cableTop,
    };
    const fieldCapacitySpec: DatasetSpec = {
      label: intl.formatMessage({ id: 'field_capacity_mm', defaultMessage: 'Field Capacity (mm)' }),
      data: [],
      color: moistureColor.cableMiddle,
    };

    for (const each of data) {
      labels.push(each.timestamp);
      waterContentSpec.data.push(each.currentWaterContentMm ?? null);
      fieldCapacitySpec.data.push(each.fieldCapacityMm ?? null);
    }

    const firstTimestamp = moment(labels[0]);
    const lastTimestamp = moment(labels[labels.length - 1]);

    const timeUnit = lastTimestamp.diff(firstTimestamp, 'day') <= 3 ? 'hour' : 'day';
    const [nightTimeAnnotations] = calculateNightTimeAnnotations(
      moment(chartZoom.bounds?.[0] ?? firstTimestamp),
      moment(chartZoom.bounds?.[1] ?? lastTimestamp),
      coordinates
    );

    const maxWaterContent = Math.max(...waterContentSpec.data.filter((v): v is number => v !== null));
    const maxFieldCapacity = Math.max(...fieldCapacitySpec.data.filter((v): v is number => v !== null));
    const maxMm = Math.max(maxWaterContent, maxFieldCapacity);

    return {
      labels,
      chartZoom,
      datasetSpecs: [waterContentSpec, fieldCapacitySpec],
      timeUnit,
      annotations: nightTimeAnnotations,
      // aggregates: [
      //   {
      //     iconPath: waterIconPath,
      //     iconColor: 'rgb(0, 119, 190)',
      //     title: intl.formatMessage({ id: 'max_water_content', defaultMessage: 'Max Water Content' }),
      //     value: maxWaterContent,
      //     unit: 'mm',
      //   },
      //   {
      //     iconPath: waterIconPath,
      //     iconColor: 'rgb(255, 165, 0)',
      //     title: intl.formatMessage({ id: 'max_field_capacity', defaultMessage: 'Max Field Capacity' }),
      //     value: maxFieldCapacity,
      //     unit: 'mm',
      //   },
      // ],
      aggregates: [],
      rightAxisSpec: undefined,
      safeRangeOptions: undefined,
    };
  }, [data, chartZoom, coordinates, intl]);

  return (
    <TimeSeriesChart
      {...timeSeriesSpec}
      yLabel={intl.formatMessage({ id: 'water_content', defaultMessage: 'Water Content' })}
      yUnit='mm'
      yMax={Math.ceil(
        Math.max(
          ...timeSeriesSpec.datasetSpecs.flatMap((spec) => spec.data.filter((v): v is number => v !== null))
        ) * 1.1
      )}
      yMin={undefined}
      showCrosshair={false}
      flattenXAxisLabels={false}
      small={false}
    />
  );
});
