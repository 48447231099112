import type { IUserSettings } from 'components/Settings/settingsState';
import { userSettingsToFirebaseObj } from 'components/Settings/settingsState';
import type { DocumentReference } from 'firebase/firestore';
import { addDoc, updateDoc } from 'firebase/firestore';
import type { User } from 'interfaces/IUser';
import type FirebaseApi from './FirebaseApi';

class Users {
  constructor(private readonly api: FirebaseApi) {}

  public async addUser(user: User): Promise<DocumentReference> {
    return addDoc(this.api.getCollections().users(), user);
  }

  public async updateUser(userId: string, user: Partial<User>): Promise<void> {
    const userDoc = this.api.getDocRef(`users/${userId}`);
    await updateDoc(userDoc, user);
  }

  public async updateUserSettings(userId: string, userSettings: Partial<IUserSettings>): Promise<void> {
    const userDoc = this.api.getDocRef(`users/${userId}`);
    await updateDoc(userDoc, userSettingsToFirebaseObj(userSettings));
  }

  public async saveUserMessagingToken(userId: string, token: string): Promise<void> {
    const userDoc = this.api.getDocRef(`users/${userId}`);
    await updateDoc(userDoc, { messagingToken: token });
  }

  public getUser(uid: string): DocumentReference {
    return this.api.getUser(uid);
  }
}

export default Users;
