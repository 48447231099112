import type { FarmPrecipitationResponse, ObservationSite, SoilDataSource, ValueHistory } from '@soilsense/shared';
import { FARM_DECODER, getLastSoilDataSource } from '@soilsense/shared';
import { stringify } from 'csv-stringify/sync';
import deepEqual from 'deep-equal';
import type { DocumentReference } from 'firebase/firestore';
import { onSnapshot } from 'firebase/firestore';
import { isPointInPolygon } from 'geolib';
import { action, computed, makeObservable, observable, reaction, runInAction, toJS } from 'mobx';
import type { Moment } from 'moment';
import moment from 'moment';
import type { IValidatedDateRangeObj } from '../components/RangeDatePicker';
import type { IFarmSettings } from '../components/Settings/settingsState';
import type { IConfirmation } from '../firebase/Farms';
import type { Base } from '../firebase/base';
import type { Area } from '../interfaces/Area';
import type { FieldDetails, FieldWithId } from '../interfaces/Field';
import { getFieldCenter } from '../interfaces/Field';
import type { IFarm, IFarmWithoutId } from '../interfaces/IFarm';
import { DEFAULT_BOT_THRESHOLD, DEFAULT_TOP_THRESHOLD } from '../interfaces/ISensor';
import type { Eventually } from '../utils/Eventually';
import getErrorMessage from '../utils/getErrorMessage';
import {
  getIrrigationStatus,
  getMarkerColorForIrrigationStatus,
  highestPriorityIrrigationStatus,
} from '../utils/getMarkerColorFromReadings';
import { DataLoggerStatusStore } from './DataLoggerStatusStore';
import { GatewayInfoStore } from './GatewayInfoStore';
import type { PendingSiteUpdate, SiteDetails, SiteExportOptions } from './ObservationSiteStore';
import {
  ObservationSiteStore,
  moistureSensorName,
  percentLabels,
  salinityLabels,
  temperatureLabels,
} from './ObservationSiteStore';
import { fetchPrecipitation } from './Precipitation';
import type { UserStore } from './UserStore';
import { loadFromLocalStorage, saveToLocalStorage } from './utils/localStorage';

const STARTING_DAYS_INTO_PAST = 14;

export type FarmExportOptions = SiteExportOptions &
  Readonly<{
    includeArchivedSites: boolean;
  }>;

export class FarmStore {
  public readonly observationSiteStore: ObservationSiteStore;
  public readonly dataLoggerStatusStore: DataLoggerStatusStore;
  public readonly gatewayStatusStore: GatewayInfoStore;

  @observable private farmId: string | undefined = undefined;
  @observable private farms: Eventually<IFarm[]> | undefined;
  @observable private dateRange: IValidatedDateRangeObj = dateRangeFromIntervalOptions();
  @observable.ref private farmPrecipitation: Promise<FarmPrecipitationResponse> | undefined;
  private farmSubscription: (() => void) | undefined;

  constructor(private readonly firebase: Base, private readonly userStore: UserStore) {
    makeObservable(this);
    this.observationSiteStore = new ObservationSiteStore(this.userStore, this);
    this.dataLoggerStatusStore = new DataLoggerStatusStore(this.userStore, this.observationSiteStore);
    this.gatewayStatusStore = new GatewayInfoStore(this.firebase, this.userStore, this);

    const rememberedFarmId = loadFromLocalStorage('farmId');
    if (rememberedFarmId) {
      this.setSelectedFarmId(rememberedFarmId);
    }

    reaction(
      () => this.userStore.currentUser,
      () => this.updateAvailableFarms()
    );
  }

  @computed public get selectedFarmId(): string | undefined {
    return this.farmId;
  }

  @computed public get isLoading(): boolean {
    return this.farms?.status == 'pending';
  }

  @computed public get errorMessage(): string | undefined {
    return this.farms?.status == 'error' ? this.farms.errorMessage : undefined;
  }

  @computed public get availableFarms(): readonly IFarm[] {
    if (this.farms?.status == 'ready') {
      // if not production, only show dev farms
      if (process.env.REACT_APP_ONLY_DEV_FARMS == 'true') {
        return this.farms.value.filter((farm) => farm.dev === true);
      } else {
        return this.farms.value;
      }
    }
    return [];
  }

  @computed get selectedFarm(): IFarm | undefined {
    return this.availableFarms.find((farm) => farm.id == this.selectedFarmId);
  }

  @computed private get availableFarmsAndIndex(): readonly [IFarm[], number] | undefined {
    if (this.farms?.status != 'ready') {
      return undefined;
    }
    const availableFarms = this.farms.value;
    const farmIndex = availableFarms.findIndex((farm) => farm.id == this.selectedFarmId);
    if (farmIndex == -1) {
      return undefined;
    }
    return [availableFarms, farmIndex];
  }

  @computed get isAdmin(): boolean {
    const user = this.userStore.currentUser;
    const farmPermission = user?.farmPermissions.find((permission) => permission.id == this.farmId);
    return farmPermission?.isAdmin ?? user?.isAdmin ?? false;
  }

  @computed get selectedDates(): IValidatedDateRangeObj {
    return this.dateRange;
  }

  @computed get intervalDays(): number {
    return intervalDaysFromDateRange(this.dateRange);
  }

  @computed get precipitation(): Promise<FarmPrecipitationResponse> | undefined {
    return this.farmPrecipitation;
  }

  @computed private get fields(): readonly FieldWithId[] {
    if (this.selectedFarm?.fields == undefined) {
      return [];
    }
    const { fields } = this.selectedFarm;
    const fieldIds = Object.keys(this.selectedFarm.fields);
    const result: FieldWithId[] = [];
    for (const fieldId of fieldIds) {
      const field = fields[fieldId];
      result.push({ id: fieldId, ...field });
    }
    return result;
  }

  @computed get siteIdToFieldId(): ReadonlyMap<string, string | undefined> {
    return new Map(
      this.observationSiteStore.allSiteDetails.map(({ site: { id, coordinates } }) => {
        for (const field of this.fields) {
          if (isPointInPolygon(coordinates, [...field.path])) {
            return [id, field.id];
          }
        }
        return [id, undefined];
      })
    );
  }

  @computed get areas(): readonly Area[] {
    const fieldIdToSites: Map<string, SiteDetails[]> = new Map(this.fields.map(({ id }) => [id, []]));
    for (const site of this.observationSiteStore.activeSiteDetails) {
      const fieldId = this.siteIdToFieldId.get(site.site.id);
      if (fieldId != undefined) {
        const sites = fieldIdToSites.get(fieldId);
        if (sites != undefined) {
          sites.push(site);
        }
      }
    }

    const result: Area[] = [];
    const fieldIdsAdded: Set<string> = new Set();
    for (const site of this.observationSiteStore.activeSiteDetails) {
      const fieldId = this.siteIdToFieldId.get(site.site.id);
      if (fieldId == undefined) {
        result.push({ id: site.site.id, kind: 'site', siteDetails: site });
      } else if (fieldIdsAdded.has(fieldId) == false) {
        const field = this.fields.find(({ id }) => id == fieldId);
        const siteDetails = fieldIdToSites.get(fieldId);
        if (field != undefined && siteDetails != undefined) {
          const irrigationStatuses = siteDetails.map(getIrrigationStatus);
          fieldIdsAdded.add(fieldId);
          result.push({
            id: field.id,
            kind: 'field',
            fieldDetails: {
              ...field,
              color: getMarkerColorForIrrigationStatus(highestPriorityIrrigationStatus(...irrigationStatuses)),
              center: getFieldCenter(field),
              siteDetails,
            },
          });
        }
      }
    }
    for (const field of this.fields) {
      if (fieldIdsAdded.has(field.id) == false) {
        result.push({
          id: field.id,
          kind: 'field',
          fieldDetails: {
            ...field,
            color: getMarkerColorForIrrigationStatus(highestPriorityIrrigationStatus()),
            center: getFieldCenter(field),
            siteDetails: [],
          },
        });
      }
    }

    if (this.selectedFarm) {
      const siteAndFieldOrder = this.selectedFarm.siteAndFieldOrder;
      if (siteAndFieldOrder != undefined) {
        result.sort((a, b) => {
          const aIndex = siteAndFieldOrder.indexOf(a.id);
          const bIndex = siteAndFieldOrder.indexOf(b.id);
          if (aIndex == -1 && bIndex == -1) {
            return 0;
          }
          if (aIndex == -1) {
            return 1;
          }
          if (bIndex == -1) {
            return -1;
          }
          return aIndex - bIndex;
        });
      }

      if (
        siteAndFieldOrder == undefined ||
        siteAndFieldOrder.length == 0 ||
        siteAndFieldOrder.length > result.length
      ) {
        // if the farm doesn't have the siteAndFieldOrder yet,
        // initiate it with the current order that the user expects
        // so that it's possible to reorder sites and field from the UI
        this.firebase.farms.setSiteAndFieldOrder(
          this.selectedFarm?.id,
          result.map(({ id }) => id)
        );
      } else {
        if (siteAndFieldOrder.length != result.length) {
          // if the length doesn't match, append missing ids to the end
          const idsNotInSiteAndFieldOrder = result
            .map(({ id }) => id)
            .filter((id) => !siteAndFieldOrder.includes(id));

          this.firebase.farms.setSiteAndFieldOrder(this.selectedFarm?.id, [
            ...siteAndFieldOrder,
            ...idsNotInSiteAndFieldOrder,
          ]);
        }
      }
    }

    return result;
  }

  @computed get selectedFarmFields(): readonly FieldDetails[] {
    return ([] as FieldDetails[]).concat(
      ...this.areas.map((area) => (area.kind == 'field' ? [area.fieldDetails] : []))
    );
  }

  @action setSelectedDates(startDate: Moment, endDate: Moment): void {
    if (this.dateRange?.startDate.isSame(startDate) && this.dateRange?.endDate.isSame(endDate)) {
      return;
    }
    this.dateRange = { startDate, endDate };
    this.fetchPrecipitation();
    this.observationSiteStore.updateData();

    if (moment(endDate).startOf('day').isSame(moment().startOf('day'))) {
      window.localStorage.setItem('SoilSense.daysIntoPast', intervalDaysFromDateRange(this.dateRange).toString());
    }
  }

  @action updateAvailableFarms(): void {
    if (this.farms?.status == 'pending') {
      return;
    }

    if (this.userStore.currentUser == undefined) {
      this.farms = undefined;
      this.applyFarmChange(undefined);
      this.dateRange = dateRangeFromIntervalOptions();
      return;
    }

    const { currentUser } = this.userStore;
    this.fetchAndSetFarms(currentUser.farmPermissions?.map(({ id }) => id) ?? []);
  }

  /**
   * The asynchronous part of the previous action is separated out to ensure
   * observables (in this case `farmPermissions`) are read from within a tracked
   * function.
   *
   * @param ids non-observable array of farm identifiers to fetch
   */
  private async fetchAndSetFarms(ids: readonly string[]): Promise<void> {
    try {
      runInAction(() => {
        this.farms = { status: 'pending' };
      });
      const farms = await Promise.all(ids.map((id) => this.firebase.farms.getFarm(id)));
      const farmsWithoutUndefined = farms.filter((farm) => farm != undefined) as IFarm[];
      if (farmsWithoutUndefined.length == 0) {
        throw new Error('There is no farm associated with this user');
      }
      runInAction(() => {
        const searchResult = farmsWithoutUndefined.find((farm) => farm?.id == this.farmId);
        const farmId = searchResult == undefined ? farmsWithoutUndefined[0]?.id : this.farmId;
        this.farms = { status: 'ready', value: farmsWithoutUndefined };
        this.applyFarmChange(farmId);
      });
    } catch (error) {
      runInAction(() => {
        this.farms = { status: 'error', errorMessage: getErrorMessage(error), errorObject: error };
        this.applyFarmChange(undefined);
      });
    }
  }

  @action public setSelectedFarmId(farmId: string): void {
    this.applyFarmChange(farmId);
    saveToLocalStorage('farmId', farmId);
  }

  @action private applyFarmChange(farmId: string | undefined) {
    if (this.farmSubscription) {
      this.farmSubscription();
      this.farmSubscription = undefined;
    }

    this.farmId = farmId;
    this.fetchPrecipitation();
    this.observationSiteStore.updateSiteDetails();
    this.dataLoggerStatusStore.updateStatuses();
    this.gatewayStatusStore.updateGateways();

    if (farmId) {
      this.farmSubscription = onSnapshot(
        this.firebase.farms.getFarmDocRef(farmId),
        async (snapshot) => {
          if (snapshot.exists()) {
            const data = await FARM_DECODER.decodeToPromise(snapshot.data());
            runInAction(() => {
              if (this.farms?.status === 'ready') {
                const farmIndex = this.farms.value.findIndex((f) => f.id === farmId);
                if (farmIndex !== -1) {
                  this.farms.value[farmIndex] = {
                    id: farmId,
                    ...data,
                  };
                }
              }
            });
          }
        },
        (error) => {
          console.error('Error listening to farm document:', error);
        }
      );
    }

    const farm = this.selectedFarm;
    const user = this.userStore.currentUser;
    if (farm != undefined && user != undefined) {
      if (farm.defaultTimeRange != undefined) {
        this.setSelectedDates(
          moment(farm.defaultTimeRange[0]).startOf('day'),
          moment(farm.defaultTimeRange[1]).startOf('day')
        );
      }
    }
  }

  @action private fetchPrecipitation(): void {
    this.farmPrecipitation =
      this.selectedFarm == undefined || this.userStore.currentTokenProvider == undefined
        ? undefined
        : fetchPrecipitation(this.selectedFarm.id, toJS(this.selectedDates), this.userStore.currentTokenProvider);
  }

  @action public setObservationSiteUpdate(siteId: string, update: PendingSiteUpdate): void {
    const siteInfo = this.observationSiteStore.getSiteInfo(siteId);
    if (siteInfo != undefined) {
      siteInfo.setPendingUpdate(update);
    }
  }

  @action public cancelObservationSiteUpdate(siteId: string): void {
    const site = this.observationSiteStore.getSiteInfo(siteId);
    if (site != undefined) {
      site.setPendingUpdate({});
    }
  }

  @action public applyObservationSiteUpdate(siteId: string): Promise<void> {
    const siteInfo = this.observationSiteStore.getSiteInfo(siteId);
    if (siteInfo == undefined) {
      return Promise.resolve();
    }

    const { savedConfiguration, configuration } = siteInfo;
    const soilDataSourceHistory = [...siteInfo.site.soilDataSourceHistory];
    if (deepEqual(savedConfiguration, configuration) === false) {
      soilDataSourceHistory.push({
        startTimestamp: Date.now(),
        value: {
          deviceNumber: Number(siteInfo.deviceIds.id),
          deviceName: siteInfo.deviceIds.deviceName,
          configuration,
        },
      });
    }

    const plantAvailableWaterSafeRange = siteInfo.site.safeRanges.plantAvailableWater ?? [
      DEFAULT_BOT_THRESHOLD,
      DEFAULT_TOP_THRESHOLD,
    ];

    const site = {
      ...siteInfo.site,
      safeRanges: {
        ...siteInfo.site.safeRanges,
        plantAvailableWater: plantAvailableWaterSafeRange,
      },
      soilDataSourceHistory,
    };
    return this.updateObservationSites([[siteInfo.site.id, site]]);
  }

  @action public updateSoilDataSourceHistory(
    siteId: string,
    soilDataSourceHistory: ValueHistory<SoilDataSource | undefined>
  ): Promise<void> {
    const siteInfo = this.observationSiteStore.getSiteInfo(siteId);
    if (siteInfo == undefined) {
      return Promise.reject(new Error('Observation site not found'));
    }

    const plantAvailableWaterSafeRange = siteInfo.site.safeRanges.plantAvailableWater ?? [
      DEFAULT_BOT_THRESHOLD,
      DEFAULT_TOP_THRESHOLD,
    ];

    const site = {
      ...siteInfo.site,
      safeRanges: {
        ...siteInfo.site.safeRanges,
        plantAvailableWater: plantAvailableWaterSafeRange,
      },
      soilDataSourceHistory,
    };
    return this.updateObservationSites([[siteInfo.site.id, site]]);
  }

  @action public updateObservationSites(updates: readonly [string, ObservationSite][]): Promise<void> {
    if (this.availableFarmsAndIndex == undefined) {
      return Promise.resolve();
    }

    const [availableFarms, farmIndex] = this.availableFarmsAndIndex;
    const farm = availableFarms[farmIndex];
    const observationSites = { ...farm.observationSites };
    const observationSiteOrder = [...(farm.observationSiteOrder ?? [])];
    const existingSiteIds = new Set(farm.observationSiteOrder);
    const dataLoggerIdsToAssignToFarm: Set<string> = new Set();
    for (const [id, site] of updates) {
      observationSites[id] = site;
      if (existingSiteIds.has(id) == false) {
        observationSiteOrder.push(id);
        const lastSoilDataSource = getLastSoilDataSource(site);
        if (lastSoilDataSource != undefined) {
          dataLoggerIdsToAssignToFarm.add(lastSoilDataSource.deviceNumber.toString());
        }
      }
    }
    return this.firebase.api
      .runTransaction(async (transaction) => {
        this.firebase.farms.updateObservationSites(farm.id, updates, transaction);
        dataLoggerIdsToAssignToFarm.forEach((sensorId) => {
          this.firebase.sensors.assignToFarm(sensorId, farm.id, transaction);
        });
      })
      .then(() => {
        availableFarms[farmIndex] = { ...farm, observationSites, observationSiteOrder };
        this.observationSiteStore.updateSiteDetails();
        this.dataLoggerStatusStore.updateStatuses();
        this.gatewayStatusStore.updateGateways();
      });
  }

  async addField(field: FieldWithId): Promise<void> {
    if (this.selectedFarm == undefined) {
      throw new Error('No farm information is available');
    }

    await this.firebase.farms.updateField(this.selectedFarm.id, field);

    if (this.availableFarmsAndIndex == undefined) {
      throw new Error('Farms were modified during the update');
    }

    const [availableFarms, farmIndex] = this.availableFarmsAndIndex;
    const farm = availableFarms[farmIndex];
    runInAction(() => {
      availableFarms[farmIndex] = {
        ...farm,
        fields: { ...farm.fields, [field.id]: { name: field.name, path: field.path } },
      };
    });
  }

  async deleteField(field: FieldWithId): Promise<void> {
    if (this.selectedFarm == undefined) {
      throw new Error('No farm information available');
    }

    await this.firebase.farms.deleteField(this.selectedFarm.id, field);

    if (this.availableFarmsAndIndex == undefined) {
      throw new Error('Farms were modified during the update');
    }

    const [availableFarms, farmIndex] = this.availableFarmsAndIndex;
    const farm = availableFarms[farmIndex];
    const fields = { ...farm.fields };
    delete fields[field.id];
    runInAction(() => {
      availableFarms[farmIndex] = { ...farm, fields };
    });
  }

  generateNewId(): string {
    return this.firebase.farms.generateNewId();
  }

  async addFarm(
    name: string,
    ownerId: string,
    ownerEmail: string
  ): Promise<IConfirmation<IFarmWithoutId> | undefined> {
    return await this.firebase.farms.addFarm(name, ownerId, ownerEmail);
  }

  async getFarmRef(farmId: string): Promise<DocumentReference<any>> {
    return this.firebase.farms.getFarmDocRef(farmId);
  }

  async getFarmWithRef(farmId: string): Promise<{ farm?: IFarm; ref?: DocumentReference }> {
    return this.firebase.farms.getFarmWithRef(farmId);
  }

  async updateFarmSettings(farmId: string, farmSettings: IFarmSettings): Promise<void> {
    await this.firebase.farms.updateFarmSettings(farmId, farmSettings);
  }

  async updateFarmPartial(farmId: string, farm: Partial<IFarm>): Promise<void> {
    await this.firebase.farms.updateFarmPartial(farmId, farm);
  }

  currentFarmToCsv(options: FarmExportOptions): string {
    const includeSalinity =
      options.includeSalinity &&
      this.observationSiteStore.allSiteDetails.some(
        (each) => each.transformed.status == 'ready' && each.transformed.value.containsSalinity
      );
    const { includeSoilTemperature } = options;
    const includeAirTemperature =
      options.includeAirTemperature &&
      this.observationSiteStore.allSiteDetails.some(
        (each) => each.transformed.status == 'ready' && each.transformed.value.containsBoxTemperature
      );
    const includeTemperature = includeSoilTemperature || includeAirTemperature;

    const rows: (string | number)[][] = [];
    rows.push(
      ['Site', 'Sensor', 'Timestamp'].concat(
        percentLabels('Moisture'),
        includeSalinity ? salinityLabels('Salinity') : [],
        includeTemperature ? temperatureLabels('Temperature') : []
      )
    );

    for (const site of this.observationSiteStore.allSiteDetails) {
      if (site.kind == 'archived' && options.includeArchivedSites == false) {
        continue;
      }
      if (site.transformed.status != 'ready') {
        throw new Error('Observation data is not available');
      }
      const siteName = site.site.name;
      const {
        configuration: { cableTop, cableMiddle, cableBottom },
      } = site;
      const topSensorName = moistureSensorName(cableTop);
      const middleSensorName = cableMiddle == undefined ? undefined : moistureSensorName(cableMiddle);
      const bottomSensorName = moistureSensorName(cableBottom);
      for (const each of site.transformed.value.data) {
        const timestamp = new Date(each.timestamp).toISOString();
        rows.push(
          [siteName, topSensorName, timestamp, each.vwcTop ?? ''].concat(
            includeSalinity ? [each.salTop ?? ''] : [],
            includeTemperature ? [includeSoilTemperature ? each.tempTop ?? '' : ''] : []
          )
        );
        if (middleSensorName != undefined) {
          rows.push(
            [siteName, middleSensorName, timestamp, each.vwcMid ?? ''].concat(
              includeSalinity ? [each.salMid ?? ''] : [],
              includeTemperature ? [includeSoilTemperature ? each.tempMid ?? '' : ''] : []
            )
          );
        }
        rows.push(
          [siteName, bottomSensorName, timestamp, each.vwcBot ?? ''].concat(
            includeSalinity ? [each.salBot ?? ''] : [],
            includeTemperature ? [includeSoilTemperature ? each.tempBot ?? '' : ''] : []
          )
        );
        if (includeAirTemperature && each.airTemp != undefined) {
          const row: (string | number)[] = [siteName, 'Box', timestamp, ''];
          if (includeSalinity) {
            row.push('');
          }
          row.push(each.airTemp);
          rows.push(row);
        }
      }
    }
    return stringify(rows);
  }

  dispose(): void {
    if (this.farmSubscription) {
      this.farmSubscription();
      this.farmSubscription = undefined;
    }
  }
}

function dateRangeFromIntervalOptions(): IValidatedDateRangeObj {
  let intervalDays = Number(
    window.localStorage.getItem('SoilSense.daysIntoPast') ?? STARTING_DAYS_INTO_PAST.toString()
  );

  if (isNaN(intervalDays)) {
    intervalDays = STARTING_DAYS_INTO_PAST;
  }

  return {
    startDate: moment().startOf('day').subtract(intervalDays, 'day'),
    endDate: moment().startOf('day'),
  };
}

function intervalDaysFromDateRange(range: IValidatedDateRangeObj): number {
  return range.endDate.diff(range.startDate, 'days');
}
