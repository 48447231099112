import OpacityIcon from '@mui/icons-material/Opacity';
import SpeakerPhoneIcon from '@mui/icons-material/SpeakerPhone';
import { CircularProgress, InputAdornment, ListItem, TextField, Typography } from '@mui/material';
import type { SoilDataSource, ValueHistory } from '@soilsense/shared';
import Accordion from 'components/Accordion';
import NotInProductionWrapper from 'components/NotInProductionWrapper';
import { doc, updateDoc } from 'firebase/firestore';
import { observer } from 'mobx-react-lite';
import type { Moment } from 'moment';
import type { Dispatch } from 'react';
import { default as React } from 'react';
import { useDocument } from 'react-firebase-hooks/firestore';
import { FormattedMessage, useIntl } from 'react-intl';
import useTraceUpdate from 'utils/useTraceUpdate';
import {
  useDataLoggerStatusStore,
  useFarmStore,
  useFirebase,
  useFirestore,
  useObservationSiteStore,
} from '../../dataHandlers/RootStore';
import { veryLongDeviceDisplayName } from '../../dataHandlers/utils/formatters';
import { archivedObservationSite } from '../../interfaces/Farm';
import type { IFarm } from '../../interfaces/IFarm';
import formatWithFallback from '../../utils/formatWithFallback';
import NumberField from '../NumberField';
import { showErrorSnackBar } from '../SnackBar';
import { SensorDepths } from './SensorDepths';
import SleepTimeSetter from './SleepTimeSetter';
import { GenericSwitch } from './inputComponents';
import type { ISensorSetting, ISensorSettings } from './settingsState';
import { getLastUpdatedColor, SecondarySummaryColored, StatusCircle } from './statusComponents';
import { defaultColumnStyle, newDeviceColorStatusColor, useStyles } from './styles';

const SensorNodes = observer(
  (props: {
    sensorSettingsState: ISensorSettings;
    updateState: (sensorId: string, newPartialState: Partial<ISensorSetting>) => void;
  }) => {
    const { sensorSettingsState, updateState } = props;
    const dataLoggerStatusStore = useDataLoggerStatusStore();
    const intl = useIntl();

    return (
      <>
        {Object.values(sensorSettingsState).map((sensorSetting: ISensorSetting) => {
          return (
            <OneSensorSettings
              key={sensorSetting.id}
              id={sensorSetting.id}
              allSensorSettings={sensorSetting}
              lastUpdated={dataLoggerStatusStore.getLastReadingTime(sensorSetting.id)}
              lastUpdatedError={formatWithFallback(intl, undefined, dataLoggerStatusStore.error(sensorSetting.id))}
              updateState={updateState}
              loading={dataLoggerStatusStore.objectIsLoading(sensorSetting.id)}
            />
          );
        })}
      </>
    );
  }
);

type OneSensorProps = Readonly<{
  id: string;
  allSensorSettings: ISensorSetting;
  updateState: (sensorId: string, newPartialState: Partial<ISensorSetting>) => void;
  lastUpdated?: Moment;
  lastUpdatedError?: string;
  loading?: boolean;
}>;

const OneSensorSettings = (props: OneSensorProps) => {
  useTraceUpdate(props, `OneSensorSettings_${props.id}`);
  const { id, allSensorSettings, updateState, lastUpdated, lastUpdatedError, loading } = props;
  const classes = useStyles();
  const intl = useIntl();

  const updateThisSensorState: UpdateThisSensorStateFunc = (newPartialState: Partial<ISensorSetting>) =>
    updateState(id, newPartialState);

  const observationSiteStore = useObservationSiteStore();
  const newSensor =
    observationSiteStore.activeSiteIdentifiers.find(({ deviceIds: { id } }) => id == allSensorSettings.id) ==
    undefined;
  const currentObservationSite = observationSiteStore.activeSiteIdentifiers.find(
    ({ deviceIds: { id } }) => id == allSensorSettings.id
  );

  const firebase = useFirebase();
  const firestore = useFirestore();
  const sensorDoc = doc(firestore, `sensors/${id}`);
  const [sensorSnapshot, sensorLoading, error] = useDocument(sensorDoc);

  const farmStore = useFarmStore();
  const farmId = farmStore.selectedFarmId;
  const farmDoc = doc(firestore, `farms/${farmId}`);
  const [farmSnapshot] = useDocument(farmDoc);
  const farm = farmSnapshot?.data() as IFarm;

  const [newDeviceName, setNewDeviceName] = React.useState<string | undefined>(undefined);

  const archiveObservationSite = async () => {
    const clearSensor = async () => {
      await updateDoc(sensorDoc, { freeToAssign: true, farm: null });
    };
    const observationSites = farm.observationSites;
    const activeSiteIdentifier = observationSiteStore.activeSiteIdentifiers.find(
      ({ deviceIds: { id } }) => id == allSensorSettings.id
    );
    if (!activeSiteIdentifier?.id) {
      return;
    }
    console.log('Active site identifier', activeSiteIdentifier);
    console.log('Observation sites', observationSites);
    const observationSite = observationSites?.[activeSiteIdentifier?.id];
    if (!observationSite) {
      throw new Error('Observation site not found');
    }
    console.log('Archiving observation site', activeSiteIdentifier.id, observationSite);
    await farmStore.updateObservationSites([[activeSiteIdentifier.id, archivedObservationSite(observationSite)]]);
    await clearSensor();
  };

  // find the right observation site
  // append new entity to the soilDataSourceHistory
  // with deviceName and deviceNumber from the sensor
  // assign the sensor to the farm (add farm string id to the sensor)
  // and flip freeToAssign to false
  const replaceSensorInObervationSite = async (newDeviceName: string) => {
    // find the right observation site
    const observationSites = farm.observationSites;
    const activeSiteIdentifier = observationSiteStore.activeSiteIdentifiers.find(
      ({ deviceIds: { id } }) => id == allSensorSettings.id
    );
    if (!activeSiteIdentifier?.id) {
      return;
    }
    console.log('Active site identifier', activeSiteIdentifier);
    console.log('Observation sites', observationSites);
    const observationSite = observationSites?.[activeSiteIdentifier?.id];
    if (!observationSite) {
      throw new Error('Observation site not found');
    }
    console.log('Observation site', observationSite);
    // append new entity to the soilDataSourceHistory
    const soilDataSourceHistory = observationSite.soilDataSourceHistory;

    const lastIndex = soilDataSourceHistory.length - 1;
    const lastEntity = soilDataSourceHistory[lastIndex];

    if (!lastEntity || !lastEntity.value?.configuration) {
      console.error('No last entity or configuration');
      return;
    }

    const newDeviceNameObj = await firebase.deviceNames.getAssociatedDeviceNameObject(newDeviceName);
    if (!newDeviceNameObj) {
      console.error('No new device name object');
      showErrorSnackBar('No new device name object');
      return;
    }
    console.log('New device name object', newDeviceNameObj);
    const sensorBasedOnDeviceName = await firebase.sensors.getSensor(newDeviceNameObj.deviceId);
    if (!sensorBasedOnDeviceName.freeToAssign) {
      console.error('Sensor is not free to assign');
      showErrorSnackBar('Sensor is not free to assign');
      return;
    }

    console.log('Sensor based on device name', sensorBasedOnDeviceName);

    const newEntity: ValueHistory<SoilDataSource | undefined> = [
      {
        startTimestamp: lastEntity.startTimestamp,
        value: {
          ...lastEntity.value,
          configuration: {
            ...lastEntity.value.configuration,
          },
          deviceName: newDeviceNameObj?.id,
          deviceNumber: Number(newDeviceNameObj?.deviceId),
        },
      },
    ];

    const newSoilDataSourceHistory = [...soilDataSourceHistory, ...newEntity];

    await farmStore.updateSoilDataSourceHistory(activeSiteIdentifier.id, newSoilDataSourceHistory);
  };

  if (error) {
    console.error(error);
    return <div>Error: {error.message}</div>;
  }

  const sensor = sensorSnapshot?.data();

  if (loading || sensorLoading) {
    return (
      <Accordion
        primarySummary={veryLongDeviceDisplayName(allSensorSettings)}
        summaryIcon={<CircularProgress size={20} />}
      >
        <div className={classes.oneSensorWrapper}>
          <CircularProgress />
        </div>
      </Accordion>
    );
  }

  return (
    <Accordion
      primarySummary={veryLongDeviceDisplayName(allSensorSettings)}
      secondarySummary={
        newSensor ? (
          <FormattedMessage id='new_data_logger_save_to_see_status' />
        ) : (
          <SecondarySummaryColored
            lastUpdated={lastUpdated}
            lastUpdatedError={lastUpdatedError}
            loading={loading}
          />
        )
      }
      summaryIcon={
        <StatusCircle
          color={
            newSensor ? newDeviceColorStatusColor : !lastUpdatedError ? getLastUpdatedColor(lastUpdated) : 'grey'
          }
        >
          <SpeakerPhoneIcon fontSize={'large'} color='secondary' />
        </StatusCircle>
      }
      fullWidth
    >
      <NotInProductionWrapper>
        <div>Observation site id: {currentObservationSite?.id ?? ''}</div>
      </NotInProductionWrapper>
      <div className={classes.oneSensorWrapper}>
        <SensorName id={id} name={allSensorSettings.name} updateThisSensorState={updateThisSensorState} />
        <SensorDepths
          topDepth={allSensorSettings.topDepth}
          setTopDepth={(topDepth) => updateThisSensorState({ topDepth })}
          midDepth={allSensorSettings.midDepth}
          setMidDepth={(midDepth) => updateThisSensorState({ midDepth })}
          botDepth={allSensorSettings.botDepth}
          setBotDepth={(botDepth) => updateThisSensorState({ botDepth })}
          disabled
        />
        {allSensorSettings.calibrated ? (
          <>
            <SensorThresholds
              label={intl.formatMessage({ id: 'plant_available_water' })}
              bottomThreshold={allSensorSettings.pawStressThreshold}
              topThreshold={allSensorSettings.pawOverIrrigationThreshold}
              updateBottomThreshold={(pawStressThreshold) => {
                updateThisSensorState({ pawStressThreshold });
              }}
              updateTopThreshold={(pawOverIrrigationThreshold) => {
                updateThisSensorState({ pawOverIrrigationThreshold });
              }}
            />
            <SensorNotificationsList
              subscribeToPawStress={allSensorSettings.subscribeToPawStress}
              subscribeToPawOverIrrigation={allSensorSettings.subscribeToPawOverIrrigation}
              updateThisSensorState={updateThisSensorState}
            />
          </>
        ) : (
          <SensorThresholds
            label={intl.formatMessage({ id: 'water_content' })}
            bottomThreshold={allSensorSettings.vwcStressThreshold}
            topThreshold={allSensorSettings.vwcOverIrrigationThreshold}
            updateBottomThreshold={(vwcStressThreshold) => {
              updateThisSensorState({ vwcStressThreshold });
            }}
            updateTopThreshold={(vwcOverIrrigationThreshold) => {
              updateThisSensorState({ vwcOverIrrigationThreshold });
            }}
          />
        )}
        {sensor?.type === 'nbsensor' ? <SleepTimeSetter deviceId={sensor.id} deviceType={'nbsensor'} /> : null}
        {/* {sensor?.type === 'nbsensor' ? <SleepTime deviceId={'9999'} deviceType={'nbsensor'} /> : null} */}
        {/* add archive observation site button if in dev */}
        <NotInProductionWrapper>
          <button
            onClick={() => {
              console.log('Archive observation site');
              archiveObservationSite();
            }}
          >
            Archive observation site
          </button>
        </NotInProductionWrapper>
        <NotInProductionWrapper>
          <input
            type='text'
            placeholder='New device name'
            onChange={(e) => {
              console.log('New device name', e.target.value);
              setNewDeviceName(e.target.value);
            }}
          />
          <button
            onClick={() => {
              console.log('Replace sensor in observation site');
              if (!newDeviceName) {
                console.error('No new device name provided');
                showErrorSnackBar('No new device name provided');
                return;
              }
              replaceSensorInObervationSite(newDeviceName);
            }}
          >
            Replace sensor in observation site
          </button>
        </NotInProductionWrapper>
      </div>
    </Accordion>
  );
};

interface ISensorNameProps {
  id: string;
  name?: string;
  updateThisSensorState: UpdateThisSensorStateFunc;
}

const SensorName: React.FC<ISensorNameProps> = ({ id, name, updateThisSensorState }) => {
  const intl = useIntl();
  return (
    <TextField
      id={`sensor-name-${id}`}
      variant='outlined'
      label={intl.formatMessage({ id: 'name' })}
      value={name}
      onChange={(e) => {
        updateThisSensorState({ name: e.target.value });
      }}
    />
  );
};

type UpdateThisSensorStateFunc = (newPartialState: Partial<ISensorSetting>) => void;

interface ISensorThresholdsProps {
  label: string;
  bottomThreshold: number | undefined;
  topThreshold: number | undefined;
  updateBottomThreshold: Dispatch<number | undefined>;
  updateTopThreshold: Dispatch<number | undefined>;
}

const SensorThresholds: React.FC<ISensorThresholdsProps> = ({
  label,
  bottomThreshold,
  topThreshold,
  updateBottomThreshold,
  updateTopThreshold,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  return (
    <div style={defaultColumnStyle}>
      <div className={classes.thresholdRow}>
        <div style={{ display: 'flex' }}>
          <OpacityIcon fontSize='large' style={{ padding: 0 }} />
          <Typography style={{ lineHeight: 2.2, paddingLeft: 20, textAlign: 'center' }}>
            <FormattedMessage id='safe_range' defaultMessage='{label} safe range' values={{ label }} />
          </Typography>
          &nbsp;
        </div>
        <div style={{ display: 'flex' }}>
          <NumberField
            label={intl.formatMessage({ id: 'from' })}
            value={bottomThreshold}
            setValue={updateBottomThreshold}
            min={0}
            max={100}
            integer
            InputProps={{
              endAdornment: <InputAdornment position='end'> %</InputAdornment>,
            }}
            width='85px'
            hideHelperText
          />
          <div style={{ display: 'flex', padding: 8, alignItems: 'center' }}>-</div>
          <NumberField
            label={intl.formatMessage({ id: 'to' })}
            value={topThreshold}
            setValue={updateTopThreshold}
            min={0}
            max={100}
            integer
            InputProps={{
              endAdornment: <InputAdornment position='end'> %</InputAdornment>,
            }}
            width='85px'
            hideHelperText
          />
        </div>
      </div>
    </div>
  );
};

interface ISensorNotificationsListPros {
  subscribeToPawStress: boolean;
  subscribeToPawOverIrrigation: boolean;
  updateThisSensorState: UpdateThisSensorStateFunc;
}

const SensorNotificationsList: React.FC<ISensorNotificationsListPros> = ({
  subscribeToPawStress,
  subscribeToPawOverIrrigation,
  updateThisSensorState,
}) => {
  const intl = useIntl();
  return (
    <>
      <ListItem>
        <GenericSwitch
          id={'subscribe-to-plant-stress'}
          title={intl.formatMessage({ id: 'subscribe_to_plant_stress_notifications' })}
          description={intl.formatMessage({
            id: 'subscribe_to_plant_stress_notifications_description',
          })}
          name='subscribeToPlantStress'
          value={subscribeToPawStress}
          onChange={(e) => {
            updateThisSensorState({ subscribeToPawStress: e.target.checked });
          }}
        />
      </ListItem>
      <ListItem>
        <GenericSwitch
          id={'subscribe-to-overirrigation'}
          title={intl.formatMessage({ id: 'subscribe_to_over_irrigation_notifications' })}
          description={intl.formatMessage({
            id: 'subscribe_to_over_irrigation_notifications_description',
          })}
          name='subscribeToOverirrigation'
          value={subscribeToPawOverIrrigation}
          onChange={(e) => {
            updateThisSensorState({ subscribeToPawOverIrrigation: e.target.checked });
          }}
        />
      </ListItem>
    </>
  );
};

export default SensorNodes;
