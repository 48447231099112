import { Box } from '@mui/material';
import { DEFAULT_BOT_DEPTH, DEFAULT_TOP_DEPTH } from '@soilsense/shared';
import AddDeviceAccordion from 'components/DeviceWizards/AddDeviceAccordion';
import type { AddGatewayCallback, AddSensorCallback } from 'components/DeviceWizards/AddDeviceFlow';
import type { MoveDataLoggerCallback } from 'components/DeviceWizards/MoveDeviceAccordion';
import MoveDeviceAccordion from 'components/DeviceWizards/MoveDeviceAccordion';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { cropTypeOptions } from '../../consts/cropTypeToPaw';
import { DEFAULT_BOT_THRESHOLD, DEFAULT_TOP_THRESHOLD } from '../../interfaces/ISensor';
import useTraceUpdate from '../../utils/useTraceUpdate';
import type { GatewayInformation } from './Gateways';
import Gateways from './Gateways';
import SensorNodes from './Nodes';
import type { ISensorSetting, ISensorSettings } from './settingsState';

type Props = Readonly<{
  gateways: readonly GatewayInformation[];
  setGateways: React.Dispatch<React.SetStateAction<readonly GatewayInformation[]>>;
  sensorSettingsState: ISensorSettings;
  setSensorSettingsState: React.Dispatch<React.SetStateAction<ISensorSettings>>;
  refetchStatuses: () => void;
}>;

const DeviceSettingsComponent: React.FC<Props> = observer((props) => {
  useTraceUpdate(props);
  const { gateways, setGateways, sensorSettingsState, setSensorSettingsState, refetchStatuses } = props;
  const updateSensorSettingState = React.useCallback(
    (sensorId: string, newPartialState: Partial<ISensorSetting>) => {
      if (sensorId) {
        setSensorSettingsState((prev: ISensorSettings) => {
          const newState = { ...prev };
          newState[sensorId] = {
            ...prev[sensorId],
            ...newPartialState,
          };
          return newState;
        });
      }
    },
    [setSensorSettingsState]
  );

  const addNewSensor = React.useCallback<AddSensorCallback>(
    (sensorId, deviceName, name, type, location, topDepth, midDepth, botDepth, cropType) => {
      const cropTypeOption = cropType && cropTypeOptions.find((o) => o.label == cropType);
      updateSensorSettingState(sensorId, {
        id: sensorId,
        deviceName,

        moveDevice: false,

        name,
        topDepth: topDepth ?? DEFAULT_TOP_DEPTH,
        midDepth: midDepth ?? 'disabled',
        botDepth: botDepth ?? DEFAULT_BOT_DEPTH,
        pawStressThreshold: cropTypeOption ? cropTypeOption.low : DEFAULT_BOT_THRESHOLD,
        pawOverIrrigationThreshold: cropTypeOption ? cropTypeOption.high : DEFAULT_TOP_THRESHOLD,
        vwcStressThreshold: undefined,
        vwcOverIrrigationThreshold: undefined,
        subscribeToPawStress: true,
        subscribeToPawOverIrrigation: false,
        type,
        location,
      });
    },
    [updateSensorSettingState]
  );

  const moveDataLogger = React.useCallback<MoveDataLoggerCallback>(
    (sensorId, deviceName, name, location, topDepth, midDepth, botDepth, cropType) => {
      const cropTypeOption = cropType && cropTypeOptions.find((o) => o.label == cropType);

      updateSensorSettingState(sensorId, {
        id: sensorId,
        deviceName,

        moveDevice: true,

        name,
        topDepth: topDepth ?? DEFAULT_TOP_DEPTH,
        midDepth: midDepth ?? 'disabled',
        botDepth: botDepth ?? DEFAULT_BOT_DEPTH,
        pawStressThreshold: cropTypeOption ? cropTypeOption.low : DEFAULT_BOT_THRESHOLD,
        pawOverIrrigationThreshold: cropTypeOption ? cropTypeOption.high : DEFAULT_TOP_THRESHOLD,
        vwcStressThreshold: undefined,
        vwcOverIrrigationThreshold: undefined,
        location: location,
      });
    },
    [updateSensorSettingState]
  );

  const addNewGateway: AddGatewayCallback = (id, deviceName, coordinates, nbsensor = false) => {
    const gateway = {
      id,
      deviceName,
      coordinates,
      nbsensor,
    };
    setGateways((previous) => [...previous, gateway]);
  };

  return (
    <Box py={1}>
      {gateways && <Gateways gateways={gateways} />}
      {sensorSettingsState && (
        <SensorNodes sensorSettingsState={sensorSettingsState} updateState={updateSensorSettingState} />
      )}
      <AddDeviceAccordion
        addNewSensor={addNewSensor}
        addNewGateway={addNewGateway}
        refetchAllStatuses={() => {
          refetchStatuses();
        }}
        // disabled={!isMobile()}
      />
      <MoveDeviceAccordion moveDataLogger={moveDataLogger} />
      <div style={{ height: '20px' }} />
    </Box>
  );
});

export default DeviceSettingsComponent;
