import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Hidden, Icon, IconButton, Menu, MenuItem, Select, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useFarmStore, useUserStore } from 'dataHandlers/RootStore';
import { observer } from 'mobx-react-lite';
import React from 'react';
import theme from 'theme';
import { FLAGS, LANGUAGE_LABELS, LOCALES } from '../i18n/locales';
import type { IFarm } from '../interfaces/IFarm';
import BarWithDrawer from './BarWithDrawer';
import LogOut from './LogOut';
import SettingsComponent from './Settings/index';

const useStyles = makeStyles(() => ({
  titlePrefix: {
    paddingRight: 10,
  },
  selectRoot: {
    background: 'blue',
  },
  selectWhiteColor: {
    color: 'white',
    padding: 3,
    paddingLeft: 9,
  },
  selectIcon: {
    color: 'white',
  },
  select: {
    display: 'flex',
  },
  menuPaper: {
    marginTop: '2px',
  },
}));

const TopBar = observer(
  ({
    farmName,
    farms,
    selectedFarm,
    setSelectedFarmId,
    titleSize,
    updateAvailableFarms,
    ...props
  }: {
    farmName?: string;
    farms: readonly IFarm[];
    selectedFarm?: IFarm;
    setSelectedFarmId: (id: string) => void;
    titleSize: 'h6' | 'h5';
    updateAvailableFarms: () => void;
    backgroundImage: string;
    titleImage: string;
    topSpacing: number;
    zIndex: number;
  }) => {
    const classes = useStyles();
    const titleSuffix = farmName ? `: ${farmName}` : '';
    const smDown = useMediaQuery(theme.breakpoints.down('md'));
    const title = smDown ? farmName : `SoilSense${titleSuffix}`;
    const farmStore = useFarmStore();
    const selectedFarmId = selectedFarm?.id;

    const menuPaperProps = smDown
      ? {
          style: {
            width: 'calc(100% - 32px)',
            maxWidth: 'calc(100% - 32px)',
            margin: 'auto auto',
          },
        }
      : {};

    const [farmSelectOpen, setFarmSelectOpen] = React.useState(false);

    const titleElement = () => {
      if (farms && farms.length > 1 && selectedFarm) {
        return (
          <>
            <Typography variant={titleSize} noWrap display='inline'>
              {smDown ? '' : 'SoilSense'}
            </Typography>
            <Box width={15} />
            <Select
              open={farmSelectOpen}
              onOpen={() => setFarmSelectOpen(true)}
              onClose={() => setFarmSelectOpen(false)}
              classes={{
                select: classes.selectWhiteColor,
                icon: classes.selectIcon,
              }}
              labelId='selected-farm-label'
              id='selected-farm'
              value={selectedFarmId}
              onChange={(e) => setSelectedFarmId(e.target.value)}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                classes: {
                  paper: classes.menuPaper,
                },
                PaperProps: menuPaperProps,
              }}
            >
              <MenuItem
                onClick={() => setFarmSelectOpen(false)}
                style={{ display: 'flex', justifyContent: 'flex-end' }}
              >
                <CloseIcon style={{ color: 'black' }} onClick={() => setFarmSelectOpen(false)} />
              </MenuItem>
              {farms.map((farm: IFarm, i: number) => {
                if (!farm) {
                  return null;
                }
                return (
                  <MenuItem
                    key={i + 1}
                    value={farm.id}
                    style={{
                      paddingRight: theme.spacing(3),
                      paddingLeft: theme.spacing(3),
                      paddingBottom: theme.spacing(1),
                      paddingTop: theme.spacing(1),
                    }}
                  >
                    {farm.name ? farm.name : farm.id}
                  </MenuItem>
                );
              })}
            </Select>
            {farmStore.isAdmin && selectedFarmId && (
              <SettingsComponent updateAvailableFarms={updateAvailableFarms} selectedFarm={selectedFarm} />
            )}
            {/* {!sensorInfoStore.isLoading && <EventsComponent />} */}
          </>
        );
      }

      return (
        <>
          <Typography variant={titleSize} noWrap display='inline'>
            {title}
          </Typography>
          {farms.length >= 1 && farmStore.isAdmin && selectedFarm && (
            <SettingsComponent updateAvailableFarms={updateAvailableFarms} selectedFarm={selectedFarm} />
          )}
        </>
      );
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <BarWithDrawer id={3} titleElement={titleElement()} {...props}>
          <Hidden smDown>
            <LanguagePicker />
            <div style={{ width: 20 }}></div>
            <LogOut />
          </Hidden>
          <Hidden mdUp>
            <>
              <IconButton
                aria-label='more'
                id='long-button'
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
              >
                {open ? (
                  <MenuIcon
                    style={{
                      color: 'white',
                      transform: 'rotate(185deg)',
                      transition: 'transform 0.2s ease-in-out',
                    }}
                  />
                ) : (
                  <MenuIcon
                    style={{
                      color: 'white',
                      transition: 'transform 0.2s ease-in-out',
                    }}
                  />
                )}
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                marginThreshold={0}
                PaperProps={{
                  style: {
                    width: '100%',
                    maxWidth: '100%',
                    left: 0,
                    right: 0,
                  },
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <MenuItem>
                  <LanguagePicker withText={true} />
                </MenuItem>
                <MenuItem
                  style={{
                    paddingLeft: 25,
                  }}
                >
                  <LogOut
                    style={{
                      color: 'black',
                    }}
                  />
                </MenuItem>
              </Menu>
            </>
          </Hidden>
        </BarWithDrawer>
      </>
    );
  }
);

export const LanguagePicker = observer(
  (
    props: {
      withText?: boolean;
    } = { withText: false }
  ) => {
    const userStore = useUserStore();
    const classes = useStyles();
    return (
      <Select
        value={userStore.locale}
        classes={{
          select: props.withText ? classes.select : classes.selectWhiteColor,
          icon: props.withText ? '' : classes.selectIcon,
        }}
        style={{
          width: props.withText ? '100%' : 'None',
          flexDirection: 'row',
        }}
      >
        {Object.values(LOCALES).map((locale) => {
          return (
            <MenuItem
              key={locale}
              value={locale}
              onClick={() => {
                userStore.updateLocale(locale);
              }}
              style={{
                height: 40,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Icon
                style={{
                  paddingTop: 4,
                  height: 20,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {FLAGS[locale]}
              </Icon>
              {props.withText && (
                <Typography style={{ paddingLeft: 10, color: 'black' }}>{LANGUAGE_LABELS[locale]}</Typography>
              )}
            </MenuItem>
          );
        })}
      </Select>
    );
  }
);

export default TopBar;
