import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import React, { type FC, useEffect, useState } from 'react';
import { useCurrentUser, useFarmStore, useUserStore } from '../../dataHandlers/RootStore';

interface NoFarmPermissionsDialogProps {
  isOpen: boolean;
}

const NoFarmPermissionsDialog: FC<NoFarmPermissionsDialogProps> = observer(({ isOpen }) => {
  const [isWaitingForPermissions, setIsWaitingForPermissions] = useState(true);
  const [newFarmName, setNewFarmName] = useState('');
  const [isCreatingFarm, setIsCreatingFarm] = useState(false);
  const currentUser = useCurrentUser()!;

  const farmStore = useFarmStore();
  const userStore = useUserStore();

  useEffect(() => {
    if (isOpen) {
      setIsWaitingForPermissions(true);
      const timer = setTimeout(() => {
        setIsWaitingForPermissions(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  const handleCreateFarm = async () => {
    if (!newFarmName) return;

    setIsCreatingFarm(true);
    try {
      const confirmation = await farmStore.addFarm(newFarmName, currentUser.uid, currentUser.email ?? '');
      if (confirmation) {
        await userStore.updateUser(currentUser.uid, {
          farmPermissions: [
            {
              id: confirmation.id,
              isAdmin: true,
            },
          ],
        });
      }
    } catch (error) {
      console.error('Failed to create farm:', error);
    }
    setTimeout(() => {
      setIsCreatingFarm(false);
    }, 1000);
  };

  return (
    <Dialog open={isOpen} disableEscapeKeyDown onClose={() => {}}>
      <DialogTitle>{isWaitingForPermissions ? 'Loading Permissions...' : 'Create Your First Farm'}</DialogTitle>
      <DialogContent>
        {isWaitingForPermissions ? (
          <div style={{ textAlign: 'center', padding: '20px' }}>
            <CircularProgress />
            <p>Loading your farm permissions...</p>
          </div>
        ) : (
          <div style={{ padding: '20px' }}>
            <p>You don't have access to any farms yet. Let's create one for you!</p>
            <TextField
              fullWidth
              label='Farm Name'
              value={newFarmName}
              onChange={(e) => setNewFarmName(e.target.value)}
              margin='normal'
            />
            <Button
              fullWidth
              variant='contained'
              color='primary'
              onClick={handleCreateFarm}
              disabled={!newFarmName || isCreatingFarm}
              style={{ marginTop: '20px' }}
            >
              {isCreatingFarm ? <CircularProgress size={24} /> : 'Create Farm'}
            </Button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
});

export default NoFarmPermissionsDialog;
