import Box from '@mui/material/Box';
import type { LoadScriptNextProps } from '@react-google-maps/api';
import { LoadScriptNext } from '@react-google-maps/api';
import { observer } from 'mobx-react-lite';
import type { CSSProperties } from 'react';
import React, { forwardRef, useEffect, useState } from 'react';
import { useFarmStore } from '../../dataHandlers/RootStore';
import Loading from '../Loading';

const googleMapsLibraries: LoadScriptNextProps['libraries'] = ['drawing', 'visualization', 'places'];

type Props = Readonly<{
  style: CSSProperties;
}>;

const MapWrapper = observer(
  forwardRef<HTMLDivElement, Props & { children?: React.ReactNode }>(({ style, children }, ref) => {
    const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
    const [loadError, setLoadError] = useState<Error>();
    const [showMap, setShowMap] = useState(false);
    const [mapsLoaded, setMapsLoaded] = useState(false);
    const farmStore = useFarmStore();

    useEffect(() => {
      if (farmStore.selectedFarmId) {
        const timer = setTimeout(() => {
          setShowMap(true);
        }, 1000);
        return () => clearTimeout(timer);
      } else {
        setShowMap(false);
      }
    }, [farmStore.selectedFarmId]);

    if (!farmStore.selectedFarmId || !showMap) {
      return (
        <Box
          ref={ref}
          style={{
            zIndex: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            position: 'relative',
            ...style,
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Loading color='secondary' />
          </div>
        </Box>
      );
    }

    console.log('mapsLoaded', mapsLoaded);

    return (
      <Box
        ref={ref}
        style={{
          zIndex: 1,
          ...style,
        }}
      >
        {googleMapsApiKey == undefined ? (
          <div>Map cannot be loaded because no API key was provided</div>
        ) : (
          <LoadScriptNext
            googleMapsApiKey={googleMapsApiKey}
            libraries={googleMapsLibraries}
            loadingElement={<Loading color='secondary' style={{ margin: '0 auto' }} />}
            onError={setLoadError}
            onLoad={() => setMapsLoaded(true)}
            key={mapsLoaded ? 'loaded' : 'not-loaded'}
          >
            <>
              {loadError == undefined && mapsLoaded ? children : <div>Map cannot be loaded right now, sorry.</div>}
              {!mapsLoaded && <Loading color='secondary' style={{ margin: '0 auto' }} />}
            </>
          </LoadScriptNext>
        )}
      </Box>
    );
  })
);

export default MapWrapper;
