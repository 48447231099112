import { Box, Button, Container, CssBaseline, Grid, Link, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Routes } from '../Routes';
import Logo, { LogoColor } from './Logo';
import Progress from './Progress';

const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      overflow: 'hidden',
    },
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgb(255,255,255,0.65)',
    padding: 20,
    borderRadius: 10,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(2, 0, 2),
  },
  glowingText: {
    textShadow: `0 0 5px #fff`,
  },
}));

export interface ISignupForm {
  elements: {
    email: {
      value: string;
    };
    password: {
      value: string;
    };
    confirmPassword: {
      value: string;
    };
  };
}

interface IProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement & ISignupForm>) => void;
  loading?: boolean;
  prefilledEmail?: string;
}

const SignUp: React.FunctionComponent<IProps> = ({ onSubmit, loading, prefilledEmail }) => {
  const classes = useStyles();
  const intl = useIntl();
  const [emailHasBeenPrefilled, setEmailHasBeenPrefilled] = useState(Boolean(prefilledEmail));

  useEffect(() => {
    if (prefilledEmail) {
      setEmailHasBeenPrefilled(true);
    }
  }, [prefilledEmail]);

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box className={classes.paper} boxShadow={3}>
        <Logo color={LogoColor.red} width={100} />
        <Typography
          component='h1'
          variant='h5'
          style={{
            paddingTop: 10,
          }}
        >
          {/* Sign up */}
          <FormattedMessage id='sign_up' />
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='email'
                label={intl.formatMessage({ id: 'email_address' })}
                name='email'
                autoComplete='email'
                defaultValue={prefilledEmail}
                disabled={emailHasBeenPrefilled}
              />
              {emailHasBeenPrefilled && (
                <Typography
                  variant='caption'
                  color='textSecondary'
                  sx={{
                    mt: 1,
                    display: 'block',
                    fontSize: '0.75rem',
                    lineHeight: '1.1',
                  }}
                >
                  {intl.formatMessage({ id: 'signup_email_prefilled' })}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                name='password'
                label={intl.formatMessage({ id: 'password' })}
                type='password'
                id='password'
                autoComplete='new-password'
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                name='confirmPassword'
                label={intl.formatMessage({ id: 'confirm_password' })}
                type='password'
                id='confirmPassword'
                autoComplete='new-password'
              />
            </Grid>
          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}
            disabled={loading}
          >
            <FormattedMessage id='sign_up' />
            <Box mx={1.5} display='flex' alignItems='center'>
              {loading ? <Progress size={20} /> : undefined}
            </Box>
          </Button>
          <Grid container justifyContent='flex-end'>
            <Grid item>
              <Link href={Routes.LOGIN} variant='body2' className={classes.glowingText} underline='hover'>
                <FormattedMessage id='signup_already_have_account_sign_in' />
              </Link>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default SignUp;
