import HighlightIcon from '@mui/icons-material/Highlight';
import SignalWifi0BarIcon from '@mui/icons-material/SignalWifi0Bar';
import SignalWifi1BarIcon from '@mui/icons-material/SignalWifi1Bar';
import SignalWifi2BarIcon from '@mui/icons-material/SignalWifi2Bar';
import SignalWifi3BarIcon from '@mui/icons-material/SignalWifi3Bar';
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import {
  Avatar,
  Box,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import type { Sensor } from '@soilsense/shared';
import { doc, getDoc } from 'firebase/firestore';
import { observer } from 'mobx-react-lite';
import type { Moment } from 'moment';
import moment from 'moment';
import { default as React, useEffect, useState } from 'react';
import {
  useDataLoggerStatusStore,
  useFirebase,
  useFirestore,
  useTokenProvider,
} from '../../dataHandlers/RootStore';
import { BASE_URL } from '../../utils/consts';
import { fetchAndSet } from '../../utils/fetchAndSet';
import { useStyles } from './styles';

const isWithinAnHour = (date: Moment) => {
  return moment().diff(date, 'minutes') <= 60;
};

const getIconBasedOnRssi = (rssi: number | undefined) => {
  if (!rssi) return <WifiOffIcon />;

  if (rssi < -90) return <SignalWifi0BarIcon />;

  if (rssi < -70) return <SignalWifi1BarIcon />;

  if (rssi < -50) return <SignalWifi2BarIcon />;

  if (rssi < -30) return <SignalWifi3BarIcon />;

  return <SignalWifi4BarIcon />;
};

const NbLoggerStatuses = observer(() => {
  const classes = useStyles();
  const dataLoggerStatusStore = useDataLoggerStatusStore();
  const firebase = useFirebase();
  // sensors state
  const [sensors, setSensors] = useState<Sensor[]>([]);
  // const [sensorsLoading, setSensorsLoading] = useState(true);
  const [lastReadings, setLastReadings] = useState<
    Record<string, { rssi: number; timestamp: string } | undefined>
  >({});
  const [lastReadingsLoading, setLastReadingsLoading] = useState(true);
  const tokenProvider = useTokenProvider();
  const firestore = useFirestore();
  const [selectedFarmId, setSelectedFarmId] = useState<string | undefined>(undefined);

  // get everything from sensor collection in firestore
  useEffect(() => {
    const getNbLoggers = async () => {
      const sensors = await firebase.sensors.getAllNbLoggerSensors();
      setSensors(sensors as Sensor[]);
    };
    getNbLoggers();
  }, [firebase.sensors]);

  useEffect(() => {
    const fetchLastReadings = async () => {
      const token = await tokenProvider?.();
      const lastReadings = (await Promise.all(
        sensors.map(async (sensor) => {
          const lastReading = await fetchAndSet(`${BASE_URL}/observations/last/${sensor.id}`, token);
          if (sensor.id === '9044') {
            console.log({ lastReading });
          }
          return lastReading;
        })
      )) as {
        rssi: number;
        timestamp: string;
      }[];

      const lastReadingsMap: Record<string, { rssi: number; timestamp: string } | undefined> = {};
      lastReadings.forEach((lastReading, index) => {
        lastReadingsMap[sensors[index].id] = lastReading;
      });
      setLastReadings(lastReadingsMap);
      setLastReadingsLoading(false);
    };

    fetchLastReadings();
  }, [sensors, tokenProvider]);

  // define function to refetch status for specific sensor
  // const updateStatus = useCallback(
  //   async (sensorId: string) => {
  //     setLastReadings((prev) => ({
  //       ...prev,
  //       [sensorId]: undefined,
  //     }));
  //     const token = await tokenProvider?.();
  //     const lastReading = await fetchAndSet(`${BASE_URL}/observations/last/${sensorId}`, token);
  //     console.log({ lastReading });
  //     setLastReadings((prev) => ({ ...prev, [sensorId]: lastReading as { rssi: number; timestamp: string } }));
  //   },
  //   [tokenProvider]
  // );

  const [farmData, setFarmData] = useState({} as Record<string, string>);

  // Fetch farm data for each unique farm ID
  useEffect(() => {
    const fetchFarmData = async () => {
      const uniqueFarmIds = Array.from(new Set(sensors.map((sensor) => sensor.farm).filter(Boolean)));
      const farmDataMap: Record<string, string> = {};

      for (const farmId of uniqueFarmIds) {
        if (!farmId) continue;
        const farmRef = doc(firestore, 'farms', farmId);
        const farmSnapshot = await getDoc(farmRef);
        farmDataMap[farmId] = farmSnapshot.exists() ? farmSnapshot.data().name : 'Unknown Farm';
      }

      setFarmData(farmDataMap);
    };

    fetchFarmData();
  }, [sensors, firestore]);

  const sensorsByFarm = React.useMemo(() => {
    const sensorsByFarm: Record<string, { sensors: Sensor[]; farmName: string }> = {};
    sensors.forEach((sensor) => {
      if (!sensor.farm) return;
      if (!farmData[sensor.farm]) return;
      if (!sensorsByFarm[sensor.farm]) {
        sensorsByFarm[sensor.farm] = {
          sensors: [],
          farmName: farmData[sensor.farm] || 'Unknown Farm',
        };
      }
      sensorsByFarm[sensor.farm].sensors.push(sensor);
    });

    return sensorsByFarm;
  }, [farmData, sensors]);

  const sensorsLoading = sensors.length === 0 || Object.keys(sensorsByFarm).length === 0;

  const SensorsByFarmElement = React.useMemo(() => {
    return Object.entries(sensorsByFarm)
      .filter(
        ([farmId, { sensors }]) =>
          !selectedFarmId || farmId === selectedFarmId || sensors.some((sensor) => !sensor.farm)
      )
      .map(([farmId, { sensors, farmName }]) => {
        return (
          <div key={farmId}>
            <Typography variant='h6' className={classes.title}>
              {`${farmName}: ${farmId}`}
            </Typography>
            <div className={classes.demo}>
              <List dense={true}>
                {sensors.map((sensor) => {
                  const sensorId = sensor.id;
                  const lastReading = lastReadings[sensorId];
                  const lastReadingTimestamp = lastReading?.timestamp ? moment(lastReading.timestamp) : undefined;
                  if (dataLoggerStatusStore.objectIsLoading(sensorId)) {
                    return (
                      <Item primaryText={sensorId} secondaryText={'Loading...'}>
                        <CircularProgress size={48} />
                      </Item>
                    );
                  }

                  if (lastReadingsLoading) {
                    return (
                      <Item primaryText={sensorId} secondaryText={'Loading...'}>
                        <CircularProgress size={48} />
                      </Item>
                    );
                  }

                  return (
                    <Item
                      key={sensorId}
                      primaryText={lastReading?.rssi ? `${sensorId} (rssi: ${lastReading?.rssi})` : sensorId}
                      secondaryText={lastReadingTimestamp?.format('MMM Do hh:mm:ss A (YYYY)')}
                      colorClass={
                        !lastReadingTimestamp
                          ? classes.gray
                          : isWithinAnHour(lastReadingTimestamp)
                          ? classes.green
                          : classes.pink
                      }
                    >
                      <ListItemSecondaryAction>
                        <IconButton
                          edge='end'
                          aria-label='delete'
                          onClick={() => {
                            dataLoggerStatusStore.updateStatus(sensorId);
                          }}
                          size='large'
                        >
                          {getIconBasedOnRssi(lastReading?.rssi)}
                        </IconButton>
                      </ListItemSecondaryAction>
                    </Item>
                  );
                })}
              </List>
            </div>
          </div>
        );
      });
  }, [classes, dataLoggerStatusStore, lastReadings, sensorsByFarm, selectedFarmId, lastReadingsLoading]);

  if (sensorsLoading) {
    return <CircularProgress />;
  }

  return (
    <div className={classes.root}>
      <Box display='flex' flexDirection='column' gap={1.5} paddingBottom={3}>
        <Typography variant='h6' className={classes.title}>
          Select Farm
        </Typography>
        <Select
          key={selectedFarmId}
          value={selectedFarmId}
          onChange={(e) => {
            setSelectedFarmId(e.target.value);
          }}
        >
          <MenuItem value=''>All</MenuItem>
          {Object.entries(sensorsByFarm).map(([farmId, { farmName }]) => (
            <MenuItem key={farmId} value={farmId}>
              {farmName}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box display='flex' flexDirection='column' gap={1.5}>
        <Typography variant='h6' className={classes.title}>
          Sensors
        </Typography>
        <Typography variant='caption'>(green if communicated within last hour)</Typography>
        <div className={classes.demo}>
          <List dense={true}>
            {SensorsByFarmElement}
            ----
            <Typography variant='h6' className={classes.title}>
              Sensors without a farm
            </Typography>
            {sensors
              .filter((sensor) => !sensor.farm)
              .map((sensor) => {
                const sensorId = sensor.id;
                // const lastReadingTimestamp = dataLoggerStatusStore.getLastReadingTime(sensorId);
                const lastReading = lastReadings[sensorId];
                const lastReadingTimestamp = lastReading?.timestamp ? moment(lastReading.timestamp) : undefined;
                if (dataLoggerStatusStore.objectIsLoading(sensorId)) {
                  return (
                    <Item primaryText={sensorId} secondaryText={'Loading...'}>
                      <CircularProgress size={48} />
                    </Item>
                  );
                }

                return (
                  <Item
                    key={sensorId}
                    primaryText={lastReading?.rssi ? `${sensorId} (rssi: ${lastReading?.rssi})` : sensorId}
                    secondaryText={lastReadingTimestamp?.format('MMM Do hh:mm:ss A (YYYY)')}
                    colorClass={
                      !lastReadingTimestamp
                        ? classes.gray
                        : isWithinAnHour(lastReadingTimestamp)
                        ? classes.green
                        : classes.pink
                    }
                  >
                    <ListItemSecondaryAction>
                      <IconButton
                        edge='end'
                        aria-label='delete'
                        onClick={() => {
                          // updateStatus(sensorId);
                          console.log('not implemented');
                        }}
                        size='large'
                      >
                        {getIconBasedOnRssi(lastReading?.rssi)}
                      </IconButton>
                    </ListItemSecondaryAction>
                  </Item>
                );
              })}
          </List>
        </div>
      </Box>
    </div>
  );
});

interface IItemProps {
  primaryText: string;
  secondaryText?: string;
  colorClass?: string;
  children: React.ReactNode;
}

const Item = ({ primaryText, secondaryText, colorClass, children }: IItemProps) => {
  return (
    <ListItem>
      <ListItemAvatar>
        <Avatar className={colorClass}>
          <HighlightIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={primaryText} secondary={secondaryText ? secondaryText : null} />
      {children}
    </ListItem>
  );
};

export default NbLoggerStatuses;
