import {
  cropsDanishMessages,
  cropsEnglishMessages,
  cropsGermanMessages,
  cropsItalianMessages,
  cropsSpanishMessages,
} from './crops';
import { LOCALES } from './locales';

export const messages: Record<LOCALES, Record<string, string>> = {
  [LOCALES.ENGLISH]: {
    ...cropsEnglishMessages,
    log_in: 'Log in',
    sign_up: 'Sign up',
    log_out: 'Log out',
    no_sensors_assigned: 'No sensors assigned to this farm',
    controls: 'Controls',
    forgot_password_email_sent:
      'Password reset email sent successfully! Check your email for further instructions.',
    login_success: 'Logged in successfully!',
    login_error: 'Something went wrong when logging in: {error}',
    signup_success: 'Signed up successfully!',
    signup_error: 'Something went wrong when signing up: {error}',
    signup_failed_creating_user: 'Failed creating the user: {email}',
    not_found_back_to: 'Back to',
    not_found_page_not_found: 'Page not found',
    cancel_confirm_no: 'No',
    cancel_confirm_yes: 'Yes',
    cancel: 'Cancel',
    accept: 'Accept',
    cancel_are_you_sure: 'Are you sure you want to cancel?',
    error_boundary_something_went_wrong: 'Something went wrong. Sorry',
    error_boundary_please_refresh: 'Please refresh the page to keep using the application.',
    forgot_password_title: 'Reset password',
    forgot_password_submit: 'Send reset password email',
    forgot_password_sign_in: 'Log in instead',
    loading_screen_loading_failed: 'Seems like the application failed to load',
    loading_screen_loading_failed_description:
      'Please make sure that you have a network connection. Reload the page to try loading again.',
    loading_screen_loading_failed_contact: 'If the problem persists, contact us at support@soilsense.io. Thanks!',
    login_title: 'Log in',
    email_address: 'Email Address',
    password: 'Password',
    login_sign_in: 'Log in',
    login_create_new_account: 'Create new account',
    login_forgot_password: 'Forgot your password? Reset it',
    number_field_please_enter_a_number: 'Please enter a number',
    number_field_please_enter_a_number_that_is_greater_or_equal_to:
      'Please enter a number that is greater or equal to {min}',
    number_field_please_enter_a_number_that_is_less_or_equal_to:
      'Please enter a number that is less or equal to {max}',
    number_field_please_enter_a_number_between: 'Please enter a number between {min} and {max}',
    from: 'From',
    to: 'To',
    farm_name: 'Farm name',
    signup_already_have_account_sign_in: 'Already have an account? Log in',
    close: 'Close',
    overview: 'Overview',
    charts: 'Charts',
    controls_ec_25_c_reference: 'EC (25°C reference)',
    controls_soil_temperature: 'Soil temperature',
    controls_box_temperature: 'Air temperature',
    controls_precipitation: 'Precipitation',
    controls_auto_scale: 'Auto scale',
    controls_archived_locations: 'Archived locations',
    download_csv: 'Download CSV',
    errors_your_session_expired: 'Your session has expired. Please refresh the page to keep using the application',
    errors_failed_reaching_the_data_servers: 'Failed reaching the data servers',
    errors_the_sensor_needs_a_timezone_set_in_order_to_perform_this_operation: `The sensor needs a timezone set in order to perform this operation`,
    errors_fetching_the_data_took_too_long_try_selecting_shorter_time_period_or_improving_your_network_connection:
      'Fetching the data took too long - try selecting shorter time period or improving your network connection',
    'Failed reaching the data servers': 'Failed reaching the data servers',
    farm: 'Farm',
    devices: 'Devices',
    gateway_updated_successfully: 'Gateway {id} updated successfully',
    could_not_update_gateway: 'Could not update gateway {id}',
    no_settings_updated: 'No settings have been updated',
    sensor_information_updated_successfully: 'Sensor information updated successfully',
    could_not_update_sensor_information: 'Could not update sensor information: {error}',
    user_settings_updated_successfully: 'User settings updated successfully',
    could_not_update_user_settings: 'Could not update user settings: {error}',
    farm_settings_updated_successfully: 'Farm settings updated successfully',
    could_not_update_farm_settings: 'Could not update farm settings: {error}',
    field_added: 'Field added successfully',
    field_not_added: 'Field could not be added: {message}',
    add_field: 'Add Field',
    click_to_choose_field_corners: 'Click to choose field corners on the map',
    area: 'Area: {area} ha',
    add: 'Add',
    field_name: 'Field name',
    settings_general: 'General',
    settings_notifications: 'Notifications',
    settings_use_datepicker: 'Use Datepicker',
    settings_paw_switch: 'PAW Switch',
    settings_farm_name: 'Farm Name',
    settings_enable_instant_notifications: 'Enable instant notifications',
    settings_receive_messages_instantly: 'Receive messages instantly about critical events',
    settings_instant_notifications_channel: 'Instant notifications channel',
    'Text message': 'Text message',
    settings_phone_numbers: 'Phone numbers',
    settings_type_and_press_enter_to_add_numbers: 'Type and press enter to add numbers',
    settings_choose_how_to_receive_the_instant_notifications: 'Choose how to receive the instant notifications',
    settings_this_setting_will_be_used_to_set_the_timezone_for_the_notifications: `This setting will be used to set the timezone for the notifications`,
    not_specified: 'Not specified',
    denmark: 'Denmark',
    hungary: 'Hungary',
    kenya: 'Kenya',
    lithuania: 'Lithuania',
    netherlands: 'Netherlands',
    peru: 'Peru',
    portugal: 'Portugal',
    spain: 'Spain',
    sweden: 'Sweden',
    country: 'Country',
    crop_type: 'Crop type',
    crop_type_not_found: 'Crop type not found. Please select "Other" and contact us if you need help.',
    field_capacity: 'Field Capacity',
    wilting_point: 'Wilting Point',
    sensor: 'Sensor',
    name: 'Name',
    safe_range: '{label} safe range',
    subscribe_to_plant_stress_notifications: 'Subscribe to plant stress notifications',
    subscribe_to_plant_stress_notifications_description:
      'Triggered when any of the sensor depths is below the safe range',
    subscribe_to_over_irrigation_notifications: 'Subscribe to over irrigation notifications',
    subscribe_to_over_irrigation_notifications_description:
      'Triggered when the deepest sensor is above the safe range',
    number_of_sensors: 'Number of sensors',
    sensors_plural: '{count, plural, one {# sensor} other {# sensors}}',
    top_sensor_depth: 'Top sensor depth',
    middle_sensor_depth: 'Middle sensor depth',
    bottom_sensor_depth: 'Bottom sensor depth',
    sensor_depth_hint: 'Depth below the surface where the sensor is installed',
    adjust_soil_settings: 'Adjust soil settings',
    calibrations_updated: 'Calibrations successfully updated',
    error_updating_calibrations: 'Error updating calibrations: {error}',
    calibration_suggestion: 'Calibration suggestion',
    apply_calibration_suggestion: 'Apply calibration suggestion',
    cancel_calibration_suggestion: 'Cancel calibration suggestion',
    calibration_history: 'Calibration history',
    delete_history_item: 'Delete history item',
    volumetric_water_content: 'Volumetric water content',
    water_content: 'Water content',
    new_data_logger_save_to_see_status: 'New data logger - save to see status',
    plant_available_water: 'Plant Available Water',
    this_device_cannot_be_added: 'Device with the provided ID cannot be added',
    provide_coordinates: 'Provide coordinates',
    connect_antennas_title: 'Connect the antennas',
    connect_antennas_description: 'Connect the antennas and then press next.',
    reset_and_check_signal_title: 'Reset and check signal strength',
    reset_and_check_signal_description:
      'Swipe the reset magnet next to the reset sticker on the gateway. Press next if the LED diode lights up white and then green.',
    gateway_could_not_be_added: 'Gateway could not be added',
    done: 'Done',
    data_logger_could_not_be_added: 'Data logger could not be added',
    nb_data_logger_could_not_be_added: 'NB data logger could not be added',
    add_new_device: 'Add a new device',
    gateway_done_a: 'Congratulations, you successfully added a gateway!',
    gateway_done_b:
      'In about 5 minutes check that it has a green circle next to it on the device list above which indicates that it successfully connected to the internet',
    sensor_done_a: 'Congratulations, you successfully added a data logger!',
    sensor_done_b:
      'In about 30 minutes check that it has a green circle next to it on the device list above which indicates that it successfully connected to the gateway and transmitted data',
    nb_logger_done_b:
      'In about an hour, check that it has a green circle next to it on the device list above, which indicates that it is transmitting data',
    go_to_installation_location: 'Go to installation location',
    go_to_installation_location_content:
      'Before proceeding, make sure that you are located where you are going to place the device. Click next if you are standing where the device will be placed.',
    reset_and_check_signal_strength: 'Reset and check signal strength',
    reset_and_check_signal_strength_content:
      'Please, swipe the reset magnet next to the reset sticker on the device. Press next if the light below the sticker lights up white and then green',
    reset_and_check_signal_strength_content_nb:
      'Please, turn on the datalogger using the green button. Press next if the light below the sticker lights up white and then green',
    select_device_to_move: 'Select device to move',
    previous_location_archived:
      'The previous location {originalLocationName} will get archived when you save the settings.',
    move_device: 'Move device',
    provide_device_id: 'Provide device ID',
    device_id: 'Device ID',
    get_gps_coordinates: 'Get GPS Coordinates',
    geolocation_in_progress: 'It takes a few seconds to obtain accurate position estimates',
    geolocation_denied:
      'Please make sure to set location permissions to "Allow" and reload the application to use this feature',
    geographic_longitude_hint: 'Geographic longitude (from 0 to 180°)',
    geographic_latitude_hint: 'Geographic latitude (from 0 to 90°)',
    latitude: 'Latitude',
    longitude: 'Longitude',
    or_type_manually: 'Or provide the coordinates manually',
    accuracy: 'Accuracy',
    location_name: 'Location Name',
    configure_location_title: 'Configure new location',
    field_deleted: 'Field deleted successfully',
    field_not_deleted: 'Field could not be deleted: {message}',
    cannot_download_csv_because_no_farm: 'Cannot download CSV because no farm is selected',
    successfully_calculated_new_field_capacity: 'Successfully calculated a new Field Capacity',
    successfully_calculated_new_wilting_point: 'Successfully calculated a new Wilting Point',
    soil_parameters: 'Soil Parameters',
    successfully_updated_sensor_information: 'Successfully updated sensor information',
    failed_updating_sensor_information: 'Failed updating sensor information: {error}',
    adjust_field_capacity: 'Adjust Field Capacity',
    apply: 'Apply',
    farm_country_not_specified: 'Farm country not specified',
    forgot_password_error: 'Something went wrong when resetting password: {error}',
    finish: 'Finish',
    next: 'Next',
    save: 'Save',
    CANNOT_FETCH_SENSOR_DATA_NO_USER_LOGGED_IN: 'Cannot fetch sensor data: No user is logged in',
    DEVICE_SETTINGS_REQUEST_FAILED: 'Device settings request failed. {details}',
    CANNOT_FETCH_SENSOR_DATA_NO_PRECIPITATION_DATA:
      'Cannot fetch sensor data: No precipitation data available. {details}',
    NO_DATA_SOURCE_ON_OBSERVATION_SITE: 'Could not find data source on observation site. {details}',
    NO_DATA_FOR_SELECTED_DATES: 'No data was found for the selected dates. {details}',
    data_logger_sleep_time_saved_successfully: 'Data logger sleep time saved successfully',
    data_logger_sleep_time: 'Data logger sleep time',
    new_gateway_save_to_see_status: 'New gateway. Save to see status',
    min: 'min',
    last_ack_value: 'Last acknowledged value: {minutes_string} {seconds_string} ({fromNow})',
    irrigation: 'Irrigation',
    rainfall: 'Rainfall',
    moisture_safe_range: 'Moisture safe range',
    total_rainfall: 'Total rainfall',
    total_irrigation: 'Total irrigation',
    salinity_safe_range: 'EC safe range',
    depth_names_moisture: 'Moisture',
    box_temperature: 'Air temperature (inside datalogger)',
    depth_names_soil_temperature: 'Soil temperature',
    soil_status_label_ok: 'All good',
    soil_status_label_dry: 'Dry',
    soil_status_label_wilting: 'Very dry',
    soil_status_label_overIrrigated: 'Too wet',
    soil_status_label_unknown: 'No data',
    last_online: 'Last online: {lastOnline}',
    no_status_available: 'No status available',
    fetching: 'Fetching',
    'Box temperature': 'Box temperature',
    waiting_for_heavy_irrigation: 'Moisture safe range is shown when you have irrigated or after heavy rain fall',
    select_suggestion: 'Select suggestion',
    turn_on_the_device_title: 'Turn on the device',
    turn_on_the_device_description:
      'Please, turn on the datalogger using the green button. Press next if the light lights up white.',
    box_temperature_warning:
      'while the air temperature inside the datalogger is often very close to the real air temperature, it can be significantly affected by direct sunlight. Therefore the readings might be too high during the day.',
    controls_air_temperature: 'Air temperature',
    coordinates: 'Coordinates',
    settings_max_phone_numbers_reached: 'You have reached the maximum number of phone numbers',
    warming_up_the_gps: 'The GPS is initializing. It might take a few moments',
    search_for_a_location_or_enter_coordinates: 'Search for a location or enter coordinates',
    settings_readers: 'Readers',
    user_not_found: 'User not found',
    reader_added_successfully: 'Reader added successfully',
    could_not_add_reader: 'Could not add reader: {error}',
    reader_removed_successfully: 'Reader removed successfully',
    could_not_remove_reader: 'Could not remove reader: {error}',
    will_be_invited_on_save: '(will be invited on save)',
    invited: '(invited)',
    admin_label: '(Admin)',
    add_reader: 'Add reader',
    passwords_do_not_match: 'Passwords do not match',
    confirm_password: 'Confirm Password',
    welcome_to_farm: 'Welcome to the {farmName}!',
    setup_instructions:
      "Behind the scenes, we make sense of your data so that you don't have to. To let you know when your plant needs more water, we need your phone number.",
    phone_number: 'Phone Number',
    enabled_notifications:
      'We will enable SMS notifications for you. You can change this anytime by clicking on the settings icon next to the farm name.',
    signup_email_prefilled:
      'We prefilled the email field for you based on the farm invitation you received to your email.',
    could_not_save_settings:
      'Could not save settings, please try again another time or contact support@soilsense.io if the problem persists',
    'No sensors assigned to this farm': 'No sensors assigned to this farm',
  },
  [LOCALES.DANISH]: {
    ...cropsDanishMessages,
    log_in: 'Log ind',
    sign_up: 'Opret bruger',
    log_out: 'Log ud',
    no_sensors_assigned: 'Ingen sensorer tildelt denne konto',
    controls: 'Instillinger',
    forgot_password_email_sent:
      'E-mail til nulstilling af adgangskode sendt med succes! Tjek din e-mail for yderligere instruktioner.',
    login_success: 'Du er nu logget ind!',
    login_error: 'Noget gik galt ved login: {error}',
    signup_success: 'Du har nu oprettet en bruger!',
    signup_error: 'Noget gik galt ved tilmelding: {error}',
    signup_failed_creating_user: 'Kunne ikke oprette brugeren: {email}',
    not_found_back_to: 'Tilbage til',
    not_found_page_not_found: 'Siden blev ikke fundet',
    cancel_confirm_no: 'Nej',
    cancel_confirm_yes: 'Ja',
    cancel: 'Annuller',
    accept: 'Accepter',
    cancel_are_you_sure: 'Er du sikker på, at du vil annullere?',
    error_boundary_something_went_wrong: 'Noget gik galt. Undskyld',
    error_boundary_please_refresh: 'Opdater siden for at fortsætte med at bruge appen.',
    forgot_password_title: 'Nulstil adgangskode',
    forgot_password_submit: 'Send e-mail til nulstilling af adgangskode',
    forgot_password_sign_in: 'Log ind i stedet',
    loading_screen_loading_failed: 'Det ser ud til, at applikationen ikke kunne indlæses',
    loading_screen_loading_failed_description:
      'Sørg for, at du har en netværksforbindelse. Genindlæs siden for at prøve igen.',
    loading_screen_loading_failed_contact: 'Hvis problemet fortsætter, kontakt os på support@soilsense.io. Tak!',
    login_title: 'Log ind',
    email_address: 'E-mailadresse',
    password: 'Adgangskode',
    login_sign_in: 'Log ind',
    login_create_new_account: 'Opret ny konto',
    login_forgot_password: 'Glemt din adgangskode? Nulstil',
    number_field_please_enter_a_number: 'Indtast venligst et tal',
    number_field_please_enter_a_number_that_is_greater_or_equal_to:
      'Indtast venligst et tal, der er større end eller lig med {min}',
    number_field_please_enter_a_number_that_is_less_or_equal_to:
      'Indtast venligst et tal, der er mindre end eller lig med {max}',
    number_field_please_enter_a_number_between: 'Indtast venligst et tal mellem {min} og {max}',
    from: 'Fra',
    to: 'Til',
    farm_name: 'Konto navn',
    signup_already_have_account_sign_in: 'Har allerede en konto? Log ind',
    close: 'Luk',
    overview: 'Oversigt',
    charts: 'Data plot',
    controls_ec_25_c_reference: 'EC (25°C reference)',
    controls_soil_temperature: 'Jordtemperatur',
    controls_box_temperature: 'Air temperatur (inde i datalogger)',
    controls_precipitation: 'Nedbør',
    controls_auto_scale: 'Auto skalering',
    controls_archived_locations: 'Arkiverede målinger',
    download_csv: 'Download CSV',
    errors_your_session_expired:
      'Din session er udløbet. Opdater siden for at fortsætte med at bruge applikationen',
    errors_failed_reaching_the_data_servers: 'Kunne ikke nå dataserverne',
    errors_the_sensor_needs_a_timezone_set_in_order_to_perform_this_operation:
      'Sensoren skal have en tidszone for at kunne udføre denne handling',
    errors_fetching_the_data_took_too_long_try_selecting_shorter_time_period_or_improving_your_network_connection:
      'Det tog for lang tid at hente dataene - prøv at vælge en kortere tidsperiode eller forbedre din netværksforbindelse',
    'Failed reaching the data servers': 'Kunne ikke nå dataserverne',
    farm: 'Konto',
    devices: 'Enheder',
    gateway_updated_successfully: 'Gateway {id} opdateret med succes',
    could_not_update_gateway: 'Kunne ikke opdatere gateway {id}',
    no_settings_updated: 'Ingen indstillinger er blevet opdateret',
    sensor_information_updated_successfully: 'Sensorinformation opdateret med succes',
    could_not_update_sensor_information: 'Kunne ikke opdatere sensorinformation: {error}',
    user_settings_updated_successfully: 'Brugerindstillinger opdateret med succes',
    could_not_update_user_settings: 'Kunne ikke opdatere brugerindstillinger: {error}',
    farm_settings_updated_successfully: 'Kontoindstillinger opdateret med succes',
    could_not_update_farm_settings: 'Kunne ikke opdatere gårdsindstillinger: {error}',
    field_added: 'Område tilføjet med succes',
    field_not_added: 'Område kunne ikke tilføjes: {message}',
    add_field: 'Tilføj område',
    click_to_choose_field_corners: 'Klik for at vælge feltets hjørner på kortet',
    area: 'Areal: {area} ha',
    add: 'Tilføj',
    field_name: 'Områdenavn',
    settings_general: 'Generelt',
    settings_notifications: 'Notifikationer',
    settings_use_datepicker: 'Brug datovælger',
    settings_paw_switch: 'PAW toggle',
    settings_farm_name: 'Kontonavn',
    settings_enable_instant_notifications: 'Aktiver notifikationer',
    settings_receive_messages_instantly: 'Modtag notifikationer direkte på din telefon',
    settings_instant_notifications_channel: 'Hvordan vil du modtage dine notifikationer',
    'Text message': 'Tekstbesked',
    settings_phone_numbers: 'Telefonnumre',
    settings_type_and_press_enter_to_add_numbers: 'Skriv og tryk enter for at tilføje numre',
    settings_choose_how_to_receive_the_instant_notifications: 'Vælg beskedtjeneste',
    settings_this_setting_will_be_used_to_set_the_timezone_for_the_notifications:
      'Denne indstilling vil blive brugt til at indstille tidszonen for notifikationerne',
    not_specified: 'Ikke angivet',
    denmark: 'Danmark',
    hungary: 'Ungarn',
    kenya: 'Kenya',
    lithuania: 'Litauen',
    netherlands: 'Holland',
    peru: 'Peru',
    portugal: 'Portugal',
    spain: 'Spanien',
    sweden: 'Sverige',
    country: 'Land',
    crop_type: 'Afgrødetype',
    crop_type_not_found:
      'Afgrødetype ikke fundet. Vælg venligst "Andet" og kontakt os, hvis du har brug for hjælp.',
    field_capacity: 'Mark kapacitet',
    wilting_point: 'Visnepunkt',
    sensor: 'Sensor',
    name: 'Navn',
    safe_range: '{label} optimalt interval',
    subscribe_to_plant_stress_notifications: 'Få notifikationer om plante stress',
    subscribe_to_plant_stress_notifications_description:
      'Udløses, når målingerne fra en af sensorerne er under det optimale interval',
    subscribe_to_over_irrigation_notifications: 'Få notifikationer om overvanding',
    subscribe_to_over_irrigation_notifications_description:
      'Udløses, når målingen fra den nederste sensor er over det optimale interval',
    number_of_sensors: 'Antal sensorer',
    sensors_plural: '{count, plural, one {# sensor} other {# sensorer}}',
    top_sensor_depth: 'Top sensor dybde',
    middle_sensor_depth: 'Mellem sensor dybde',
    bottom_sensor_depth: 'Bund sensor dybde',
    sensor_depth_hint: 'Dybde under overfladen, hvor sensoren er installeret',
    adjust_soil_settings: 'Justér jordindstillinger',
    calibrations_updated: 'Kalibreringer er opdateret succesfuldt',
    error_updating_calibrations: 'Fejl ved opdatering af kalibreringer: {error}',
    calibration_suggestion: 'Kalibreringsforslag',
    apply_calibration_suggestion: 'Anvend kalibreringsforslag',
    cancel_calibration_suggestion: 'Annuller kalibreringsforslag',
    calibration_history: 'Kalibreringshistorik',
    delete_history_item: 'Slet historikpunkt',
    volumetric_water_content: 'Volumetrisk vandindhold',
    water_content: 'Vandindhold',
    new_data_logger_save_to_see_status: 'Ny datalogger - gem for at se status',
    plant_available_water: 'Plante Tilgængeligt Vand',
    this_device_cannot_be_added: 'Enhed med det angivne ID kan ikke tilføjes',
    provide_coordinates: 'Angiv koordinater',
    connect_antennas_title: 'Tilslut antennerne',
    connect_antennas_description: 'Tilslut antennerne og tryk derefter på næste.',
    reset_and_check_signal_title: 'Nulstil og tjek signalstyrke',
    reset_and_check_signal_description:
      'Stryg nulstillingsmagneten forbi nulstillingsklistermærket på gatewayen. Tryk på næste, hvis LED-dioden lyser hvidt og derefter grønt.',
    gateway_could_not_be_added: 'Gatewayen kunne ikke tilføjes',
    done: 'Færdig',
    data_logger_could_not_be_added: 'Dataloggeren kunne ikke tilføjes',
    nb_data_logger_could_not_be_added: 'NB dataloggeren kunne ikke tilføjes',
    add_new_device: 'Tilføj en ny enhed',
    gateway_done_a: 'Tillykke, du har succesfuldt tilføjet en gateway!',
    gateway_done_b:
      'Tjek om cirka 5 minutter, at der er en grøn cirkel ved siden af den på enhedslisten ovenfor, hvilket indikerer, at den er forbundet til internettet.',
    sensor_done_a: 'Tillykke, du har succesfuldt tilføjet en datalogger!',
    sensor_done_b:
      'Tjek om cirka 30 minutter, at der er en grøn cirkel ved siden af den på enhedslisten ovenfor, hvilket indikerer, at den er forbundet til gatewayen og har sendt data.',
    nb_logger_done_b:
      'Tjek om cirka en time, at der er en grøn cirkel ved siden af den på enhedslisten ovenfor, hvilket indikerer, at den sender data.',
    go_to_installation_location: 'Gå til installationsstedet',
    go_to_installation_location_content:
      'Før du fortsætter, skal du sikre dig, at du er placeret, hvor du vil placere enheden. Klik på næste, hvis du står, hvor enheden skal placeres.',
    reset_and_check_signal_strength: 'Nulstil og tjek signalstyrke',
    reset_and_check_signal_strength_content:
      'Stryg venligst nulstillingsmagneten forbi nulstillingsklistermærket på enheden. Tryk på næste, hvis lyset under klistermærket lyser hvidt og derefter grønt.',
    reset_and_check_signal_strength_content_nb:
      'Tænd venligst dataloggeren ved at trykke på den grønne knap. Tryk på næste, hvis lyset under klistermærket lyser hvidt og derefter grønt.',
    select_device_to_move: 'Vælg enhed at flytte',
    previous_location_archived:
      'Den tidligere placering {originalLocationName} vil blive arkiveret, når du gemmer indstillingerne.',
    move_device: 'Flyt enhed',
    provide_device_id: 'Angiv enheds-ID',
    device_id: 'Enheds-ID',
    get_gps_coordinates: 'Find GPS-koordinater',
    geolocation_in_progress: 'Det tager et par sekunder at opnå nøjagtige positionsestimater',
    geolocation_denied:
      'Sørg for at indstille placeringstilladelser til "Tillad" og genindlæs applikationen for at bruge denne funktion',
    geographic_longitude_hint: 'Geografisk længdegrad (fra 0 til 180°)',
    geographic_latitude_hint: 'Geografisk breddegrad (fra 0 til 90°)',
    latitude: 'Breddegrad',
    longitude: 'Længdegrad',
    or_type_manually: 'Eller angiv koordinaterne manuelt',
    accuracy: 'Nøjagtighed',
    location_name: 'Placeringsnavn',
    configure_location_title: 'Konfigurer ny placering',
    field_deleted: 'Område slettet succesfuldt',
    field_not_deleted: 'Område kunne ikke slettes: {message}',
    cannot_download_csv_because_no_farm: 'Kan ikke downloade CSV, fordi ingen konto er valgt',
    successfully_calculated_new_field_capacity: ' Ny mark kapacitet beregnet med succes',
    successfully_calculated_new_wilting_point: 'Nyt visnepunkt beregnet med succes',
    soil_parameters: 'Jordparametre',
    successfully_updated_sensor_information: 'Sensorinformation opdateret succesfuldt',
    failed_updating_sensor_information: 'Opdatering af sensorinformation mislykkedes: {error}',
    adjust_field_capacity: 'Justér mark kapacitet',
    apply: 'Anvend',
    farm_country_not_specified: 'Land ikke angivet',
    forgot_password_error: 'Noget gik galt ved nulstilling af adgangskode: {error}',
    finish: 'Afslut',
    next: 'Næste',
    save: 'Gem',
    CANNOT_FETCH_SENSOR_DATA_NO_USER_LOGGED_IN: 'Kan ikke hente sensordata: Ingen bruger er logget ind',
    DEVICE_SETTINGS_REQUEST_FAILED: 'Anmodning om enhedsindstillinger mislykkedes. {details}',
    CANNOT_FETCH_SENSOR_DATA_NO_PRECIPITATION_DATA:
      'Kan ikke hente sensordata: Ingen nedbørdata tilgængelige. {details}',
    NO_DATA_SOURCE_ON_OBSERVATION_SITE: 'Kunne ikke finde datakilde på observationsstedet. {details}',
    NO_DATA_FOR_SELECTED_DATES: 'Ingen data fundet for de valgte datoer. {details}',
    data_logger_sleep_time_saved_successfully: 'Data logger sovetid gemt med succes',
    data_logger_sleep_time: 'Data logger sovetid',
    new_gateway_save_to_see_status: 'Ny gateway. Gem for at se status',
    min: 'min',
    last_ack_value: 'Sidste godkendte værdi: {minutes_string} {seconds_string} ({fromNow})',
    irrigation: 'Vanding',
    rainfall: 'Nedbør',
    moisture_safe_range: 'Optimalt interval',
    total_rainfall: 'Samlet nedbør',
    total_irrigation: 'Samlet vanding',
    salinity_safe_range: 'Optimalt EC interval',
    depth_names_moisture: 'Jordfugt',
    box_temperature: 'Lufttemperatur (inde i datalogger)',
    depth_names_soil_temperature: 'Jordtemperatur',
    soil_status_label_ok: 'Alt godt',
    soil_status_label_dry: 'Tørt',
    soil_status_label_wilting: 'Meget tørt',
    soil_status_label_overIrrigated: 'For vådt',
    soil_status_label_unknown: 'Ingen data',
    last_online: 'Sidst online: {lastOnline}',
    no_status_available: 'Ingen status tilgængelig',
    fetching: 'Henter',
    'Box temperature': 'Box temperatur',
    waiting_for_heavy_irrigation:
      'Det optimale interval for jordfugtighed vises, når du har vandet eller efter kraftig nedbør',
    select_suggestion: 'Vælg forslag',
    turn_on_the_device_title: 'Tænd enheden',
    turn_on_the_device_description:
      'Tænd venligst dataloggeren ved at trykke på den grønne knap. Tryk på næste, hvis lyset lyser hvidt.',
    box_temperature_warning:
      'Mens box temperaturen ofte er meget tæt på lufttemperaturen, kan den blive påvirket af direkte sollys. Dette kan resultere i unøjagtige aflæsninger, især om dagen.',
    controls_air_temperature: 'Lufttemperatur',
    coordinates: 'Koordinater',
    settings_max_phone_numbers_reached: 'Du har nået det maksimale antal telefonnumre',
    warming_up_the_gps: `GPS'en initialiseres. Det kan tage et par øjeblikke`,
    search_for_a_location_or_enter_coordinates: 'Søg efter et sted eller indtast koordinater',
    settings_readers: 'Leser',
    user_not_found: 'Benutzer nicht gefunden',
    reader_added_successfully: 'Leser erfolgreich hinzugefügt',
    could_not_add_reader: 'Leser konnte nicht hinzugefügt werden: {error}',
    reader_removed_successfully: 'Leser erfolgreich entfernt',
    could_not_remove_reader: 'Leser konnte nicht entfernt werden: {error}',
    will_be_invited_on_save: '(vil blive inviteret ved gem)',
    invited: '(inviteret)',
    admin_label: '(Administrator)',
    add_reader: 'Tilføj læser',
    passwords_do_not_match: 'Adgangskoderne matcher ikke',
    confirm_password: 'Bekræft adgangskode',
    welcome_to_farm: 'Velkommen til {farmName}!',
    setup_instructions:
      'Bag kulisserne analyserer vi dine data, så du ikke behøver at gøre det. For at kunne fortælle dig, når dine planter har brug for mere vand, har vi brug for dit telefonnummer.',
    phone_number: 'Telefonnummer',
    enabled_notifications:
      'Vi vil aktivere SMS-notifikationer for dig. Du kan ændre dette når som helst ved at klikke på indstillingsikonet ved siden af kontonavnet.',
    signup_email_prefilled:
      'Vi har udfyldt e-mailfeltet for dig baseret på den invitation, du modtog på din e-mail.',
    could_not_save_settings:
      'Kunne ikke gemme indstillinger. Prøv igen senere eller kontakt support@soilsense.io hvis problemet fortsætter',
    'No sensors assigned to this farm': 'Ingen sensorer tilknyttet denne farm',
  },
  [LOCALES.GERMAN]: {
    ...cropsGermanMessages,
    log_in: 'Anmelden',
    sign_up: 'Anmelden',
    log_out: 'Ausloggen',
    no_sensors_assigned: 'Dieser Farm sind noch keine Sensoren zugewiesen',
    controls: 'Kontrollen',
    forgot_password_email_sent:
      'Passwort zurücksetzen E-Mail erfolgreich gesendet! Überprüfen Sie Ihre E-Mail für weitere Anweisungen.',
    login_success: 'Erfolgreich eingeloggt!',
    login_error: 'Etwas ist schief gelaufen beim Einloggen: {error}',
    signup_success: 'Erfolgreich angemeldet!',
    signup_error: 'Etwas ist schief gelaufen bei der Anmeldung: {error}',
    signup_failed_creating_user: 'Fehler beim Erstellen des Benutzers: {email}',
    not_found_back_to: 'Zurück zu',
    not_found_page_not_found: 'Seite nicht gefunden',
    cancel_confirm_no: 'Nein',
    cancel_confirm_yes: 'Ja',
    cancel: 'Abbrechen',
    accept: 'Akzeptieren',
    cancel_are_you_sure: 'Bist du sicher, dass du stornieren willst?',
    error_boundary_something_went_wrong: 'Etwas ist schief gelaufen. Entschuldigung',
    error_boundary_please_refresh: 'Aktualisieren Sie die Seite, um die Anwendung weiterhin zu nutzen.',
    forgot_password_title: 'Passwort zurücksetzen',
    forgot_password_submit: 'Passwort zurücksetzen E-Mail senden',
    forgot_password_sign_in: 'Stattdessen anmelden',
    loading_screen_loading_failed: 'Die Anwendung konnte offenbar nicht geladen werden',
    loading_screen_loading_failed_description:
      'Stellen Sie sicher, dass Sie eine Netzwerkverbindung haben. Laden Sie die Seite neu, um erneut zu versuchen.',
    loading_screen_loading_failed_contact:
      'Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte unter support@soilsense.io. Danke!',
    login_title: 'Anmelden',
    email_address: 'E-Mail-Adresse',
    password: 'Passwort',
    login_sign_in: 'Anmelden',
    login_create_new_account: 'Neues Konto erstellen',
    login_forgot_password: 'Passwort vergessen? Setze es zurück',
    number_field_please_enter_a_number: 'Bitte geben Sie eine Zahl ein',
    number_field_please_enter_a_number_that_is_greater_or_equal_to:
      'Bitte geben Sie eine Zahl ein, die größer oder gleich {min} ist',
    number_field_please_enter_a_number_that_is_less_or_equal_to:
      'Bitte geben Sie eine Zahl ein, die kleiner oder gleich {max} ist',
    number_field_please_enter_a_number_between: 'Bitte geben Sie eine Zahl zwischen {min} und {max} ein',
    from: 'Von',
    to: 'Zu',
    farm_name: 'Name der Farm',
    signup_already_have_account_sign_in: 'Haben Sie bereits ein Konto? Anmelden',
    close: 'Schließen',
    overview: 'Übersicht',
    charts: 'Diagramme',
    controls_ec_25_c_reference: 'EC (25°C Referenz)',
    controls_soil_temperature: 'Bodentemperatur',
    controls_box_temperature: 'Lufttemperatur (im Datenlogger)',
    controls_precipitation: 'Niederschlag',
    controls_auto_scale: 'Automatische Skalierung',
    controls_archived_locations: 'Archivierte Standorte',
    download_csv: 'CSV herunterladen',
    errors_your_session_expired:
      'Ihre Sitzung ist abgelaufen. Aktualisieren Sie die Seite, um die Anwendung weiterhin zu nutzen',
    errors_failed_reaching_the_data_servers: 'Die Daten-Server konnten nicht erreicht werden',
    errors_the_sensor_needs_a_timezone_set_in_order_to_perform_this_operation:
      'Der Sensor muss eine Zeitzone eingestellt haben, um diese Operation durchzuführen',
    errors_fetching_the_data_took_too_long_try_selecting_shorter_time_period_or_improving_your_network_connection:
      'Das Abrufen der Daten hat zu lange gedauert - versuchen Sie, einen kürzeren Zeitraum auszuwählen oder verbessern Sie Ihre Netzwerkverbindung',
    'Failed reaching the data servers': 'Die Daten-Server konnten nicht erreicht werden',
    farm: 'Farm',
    devices: 'Geräte',
    gateway_updated_successfully: 'Gateway {id} erfolgreich aktualisiert',
    could_not_update_gateway: 'Gateway {id} konnte nicht aktualisiert werden',
    no_settings_updated: 'Es wurden keine Einstellungen aktualisiert',
    sensor_information_updated_successfully: 'Sensorinformationen erfolgreich aktualisiert',
    could_not_update_sensor_information: 'Sensorinformationen konnten nicht aktualisiert werden: {error}',
    user_settings_updated_successfully: 'Benutzereinstellungen erfolgreich aktualisiert',
    could_not_update_user_settings: 'Benutzereinstellungen konnten nicht aktualisiert werden: {error}',
    farm_settings_updated_successfully: 'Farm-Einstellungen erfolgreich aktualisiert',
    could_not_update_farm_settings: 'Farm-Einstellungen konnten nicht aktualisiert werden: {error}',
    field_added: 'Feld erfolgreich hinzugefügt',
    field_not_added: 'Feld konnte nicht hinzugefügt werden: {message}',
    add_field: 'Feld hinzufügen',
    click_to_choose_field_corners: 'Klicken Sie, um die Feldecken auf der Karte auszuwählen',
    area: 'Fläche: {area} ha',
    add: 'Hinzufügen',
    field_name: 'Feldname',
    settings_general: 'Allgemein',
    settings_notifications: 'Benachrichtigungen',
    settings_use_datepicker: 'Datepicker verwenden',
    settings_paw_switch: 'PAW Switch',
    settings_farm_name: 'Hofname',
    settings_enable_instant_notifications: 'Sofortige Benachrichtigungen aktivieren',
    settings_receive_messages_instantly: 'Erhalten Sie sofort Benachrichtigungen über kritische Ereignisse',
    settings_instant_notifications_channel: 'Kanal für sofortige Benachrichtigungen',
    'Text message': 'SMS',
    settings_phone_numbers: 'Telefonnummern',
    settings_type_and_press_enter_to_add_numbers: 'Geben Sie ein und drücken Sie Enter, um Nummern hinzuzufügen',
    settings_choose_how_to_receive_the_instant_notifications:
      'Wählen Sie, wie Sie die sofortigen Benachrichtigungen erhalten möchten',
    settings_this_setting_will_be_used_to_set_the_timezone_for_the_notifications:
      'Diese Einstellung wird verwendet, um die Zeitzone für die Benachrichtigungen festzulegen',
    not_specified: 'Nicht spezifiziert',
    denmark: 'Dänemark',
    hungary: 'Ungarn',
    kenya: 'Kenia',
    lithuania: 'Litauen',
    netherlands: 'Niederlande',
    peru: 'Peru',
    portugal: 'Portugal',
    spain: 'Spanien',
    sweden: 'Schweden',
    country: 'Land',
    crop_type: 'Kulturart',
    crop_type_not_found:
      'Kulturart nicht gefunden. Wählen Sie "Andere" und kontaktieren Sie uns, wenn Sie Hilfe benötigen.',
    field_capacity: 'Feldkapazität',
    wilting_point: 'Welkepunkt',
    sensor: 'Sensor',
    name: 'Name',
    safe_range: '{label} sicherer Bereich',
    subscribe_to_plant_stress_notifications: 'Abonnieren Sie Pflanzenstress-Benachrichtigungen',
    subscribe_to_plant_stress_notifications_description:
      'Ausgelöst, wenn eine der Sensortiefen unter dem sicheren Bereich liegt',
    subscribe_to_over_irrigation_notifications: 'Abonnieren Sie Überbewässerungsbenachrichtigungen',
    subscribe_to_over_irrigation_notifications_description:
      'Ausgelöst, wenn der tiefste Sensor über dem sicheren Bereich liegt',
    number_of_sensors: 'Anzahl der Sensoren',
    sensors_plural: '{count, plural, one {# Sensor} other {# Sensoren}}',
    top_sensor_depth: 'Oberste Sensortiefe',
    middle_sensor_depth: 'Mittlere Sensortiefe',
    bottom_sensor_depth: 'Unterste Sensortiefe',
    sensor_depth_hint: 'Tiefe unter der Oberfläche, an der der Sensor installiert ist',
    adjust_soil_settings: 'Bodeneinstellungen anpassen',
    calibrations_updated: 'Kalibrierungen aktualisiert',
    error_updating_calibrations: 'Fehler beim Aktualisieren der Kalibrierungen: {error}',
    calibration_suggestion: 'Kalibrierungsvorschlag',
    apply_calibration_suggestion: 'Kalibrierungsvorschlag anwenden',
    cancel_calibration_suggestion: 'Kalibrierungsvorschlag abbrechen',
    calibration_history: 'Kalibrierungshistorie',
    delete_history_item: 'Historie löschen',
    volumetric_water_content: 'Volumetrischer Wassergehalt',
    water_content: 'Wassergehalt',
    new_data_logger_save_to_see_status: 'Neuer Datenlogger - speichern, um den Status zu sehen',
    plant_available_water: 'Verfügbares Pflanzenwasser',
    this_device_cannot_be_added: 'Das Gerät mit der angegebenen ID kann nicht hinzugefügt werden',
    provide_coordinates: 'Geben Sie Koordinaten an',
    connect_antennas_title: 'Schließen Sie die Antennen an',
    connect_antennas_description: 'Schließen Sie die Antennen an und drücken Sie dann auf Weiter.',
    reset_and_check_signal_title: 'Zurücksetzen und Signalqualität prüfen',
    reset_and_check_signal_description:
      'Streichen Sie den Reset-Magneten neben dem Reset-Aufkleber auf dem Gateway. Drücken Sie auf Weiter, wenn die LED weiß leuchtet und dann grün.',
    gateway_could_not_be_added: 'Gateway konnte nicht hinzugefügt werden',
    done: 'Fertig',
    data_logger_could_not_be_added: 'Datenlogger konnte nicht hinzugefügt werden',
    nb_data_logger_could_not_be_added: 'NB Datenlogger konnte nicht hinzugefügt werden',
    add_new_device: 'Neues Gerät hinzufügen',
    gateway_done_a: 'Glückwunsch, Sie haben ein Gateway hinzugefügt!',
    gateway_done_b:
      'Überprüfen Sie nach etwa 5 Minuten, ob es neben ihm in der Geräteliste oben einen grünen Kreis hat, der anzeigt, dass es mit dem Internet verbunden ist',
    sensor_done_a: 'Glückwunsch, Sie haben einen Datenlogger hinzugefügt!',
    sensor_done_b:
      'Überprüfen Sie nach etwa 30 Minuten, ob es neben ihm in der Geräteliste oben einen grünen Kreis hat, der anzeigt, dass es mit dem Gateway verbunden ist und Daten überträgt',
    nb_logger_done_b:
      'Überprüfen Sie nach etwa einer Stunde, ob es neben ihm in der Geräteliste oben einen grünen Kreis hat, der anzeigt, dass es Daten überträgt',
    go_to_installation_location: 'Gehen Sie zum Installationsort',
    go_to_installation_location_content:
      'Bevor Sie fortfahren, stellen Sie sicher, dass Sie sich dort befinden, wo Sie das Gerät platzieren möchten. Klicken Sie auf Weiter, wenn Sie sich dort befinden, wo das Gerät platziert werden soll.',
    reset_and_check_signal_strength: 'Zurücksetzen und Signalstärke prüfen',
    reset_and_check_signal_strength_content:
      'Streichen Sie den Reset-Magneten neben dem Reset-Aufkleber auf dem Gerät. Drücken Sie auf Weiter, wenn das Licht unter dem Aufkleber weiß und dann grün leuchtet',
    reset_and_check_signal_strength_content_nb:
      'Schalten Sie den Datenlogger ein, indem Sie auf die grüne Taste drücken. Drücken Sie auf Weiter, wenn das Licht unter dem Aufkleber weiß und dann grün leuchtet',
    select_device_to_move: 'Gerät zum Verschieben auswählen',
    previous_location_archived:
      'Der vorherige Standort {originalLocationName} wird archiviert, wenn Sie die Einstellungen speichern.',
    move_device: 'Gerät umsetzten',
    provide_device_id: 'Geräte-ID angeben',
    device_id: 'Geräte-ID',
    get_gps_coordinates: 'GPS-Koordinaten erhalten',
    geolocation_in_progress: 'Es dauert ein paar Sekunden, um genaue Positionsschätzungen zu erhalten',
    geolocation_denied:
      'Stellen Sie sicher, dass die Standortberechtigungen auf "Zulassen" eingestellt sind und laden Sie die Anwendung neu, um diese Funktion zu nutzen',
    geographic_longitude_hint: 'Geographische Länge (von 0 bis 180°)',
    geographic_latitude_hint: 'Geographische Breite (von 0 bis 90°)',
    latitude: 'Breitengrad',
    longitude: 'Längengrad',
    or_type_manually: 'Oder geben Sie die Koordinaten manuell ein',
    accuracy: 'Genauigkeit',
    location_name: 'Standortname',
    configure_location_title: 'Neuen Standort konfigurieren',
    field_deleted: 'Feld erfolgreich gelöscht',
    field_not_deleted: 'Feld konnte nicht gelöscht werden: {message}',
    cannot_download_csv_because_no_farm: 'CSV kann nicht heruntergeladen werden, da keine Farm ausgewählt ist',
    successfully_calculated_new_field_capacity: 'Neue Feldkapazität erfolgreich berechnet',
    successfully_calculated_new_wilting_point: 'Neuer Welkepunkt erfolgreich berechnet',
    soil_parameters: 'Bodenparameter',
    successfully_updated_sensor_information: 'Sensorinformationen erfolgreich aktualisiert',
    failed_updating_sensor_information: 'Sensorinformationen konnten nicht aktualisiert werden: {error}',
    adjust_field_capacity: 'Feldkapazität anpassen',
    apply: 'Anwenden',
    farm_country_not_specified: 'Farm-Land nicht angegeben',
    forgot_password_error: 'Etwas ist schief gelaufen beim Zurücksetzen des Passworts: {error}',
    finish: 'Fertig',
    next: 'Nächster',
    save: 'Speichern',
    CANNOT_FETCH_SENSOR_DATA_NO_USER_LOGGED_IN:
      'Sensordaten können nicht abgerufen werden: Kein Benutzer angemeldet',
    DEVICE_SETTINGS_REQUEST_FAILED: 'Geräteeinstellungsanforderung fehlgeschlagen. {details}',
    CANNOT_FETCH_SENSOR_DATA_NO_PRECIPITATION_DATA:
      'Sensordaten können nicht abgerufen werden: Keine Niederschlagsdaten verfügbar. {details}',
    NO_DATA_SOURCE_ON_OBSERVATION_SITE: 'Datenquelle auf Beobachtungsstelle nicht gefunden. {details}',
    NO_DATA_FOR_SELECTED_DATES: 'Keine Werte für die ausgewählten Daten gefunden. {details}',
    data_logger_sleep_time_saved_successfully: 'Schlafzeit des Datenloggers erfolgreich gespeichert',
    data_logger_sleep_time: 'Schlafzeit des Datenloggers',
    new_gateway_save_to_see_status: 'Neues Gateway. Speichern, um den Status zu sehen',
    min: 'min',
    last_ack_value: 'Letzter ACK-Wert: {minutes_string} {seconds_string} ({fromNow})',
    irrigation: 'Bewässerung',
    rainfall: 'Niederschlag',
    moisture_safe_range: 'Sicherer Bereich',
    total_rainfall: 'Gesamtniederschlag',
    total_irrigation: 'Gesamtbewässerung',
    salinity_safe_range: 'EC sicherer Bereich',
    depth_names_moisture: 'Feuchtigkeit',
    box_temperature: 'Lufttemperatur (im Datenlogger)',
    depth_names_soil_temperature: 'Bodentemperatur',
    soil_status_label_ok: 'Alles gut',
    soil_status_label_dry: 'Trocken',
    soil_status_label_wilting: 'Sehr trocken',
    soil_status_label_overIrrigated: 'Zu nass',
    soil_status_label_unknown: 'Keine Daten',
    last_online: 'Zuletzt online: {lastOnline}',
    no_status_available: 'Kein Status verfügbar',
    fetching: 'Abrufen',
    'Box temperature': 'Boxtemperatur',
    waiting_for_heavy_irrigation:
      'Der optimale Bereich für die Bodenfeuchtigkeit wird angezeigt, nachdem Sie bewässert oder nach starkem Regen',
    select_suggestion: 'Vorschlag auswählen',
    turn_on_the_device_title: 'Gerät einschalten',
    turn_on_the_device_description:
      'Schalten Sie den Datenlogger ein, indem Sie auf die grüne Taste drücken. Drücken Sie auf Weiter, wenn das Licht weiß leuchtet.',
    box_temperature_warning:
      'Während die Boxtemperatur oft sehr nahe an der Lufttemperatur liegt, kann sie durch direktes Sonnenlicht beeinflusst werden. Dies kann zu ungenauen Messwerten führen, insbesondere tagsüber.',
    controls_air_temperature: 'Lufttemperatur',
    coordinates: 'Koordinaten',
    settings_max_phone_numbers_reached: 'Sie haben die maximale Anzahl von Telefonnummern erreicht',
    warming_up_the_gps: 'GPS wird initialisiert. Es kann einen Moment dauern',
    search_for_a_location_or_enter_coordinates: 'Suche nach einem Ort oder geben Sie Koordinaten ein',
    settings_readers: 'Leser',
    user_not_found: 'Benutzer nicht gefunden',
    reader_added_successfully: 'Leser erfolgreich hinzugefügt',
    could_not_add_reader: 'Leser konnte nicht hinzugefügt werden: {error}',
    reader_removed_successfully: 'Leser erfolgreich entfernt',
    could_not_remove_reader: 'Leser konnte nicht entfernt werden: {error}',
    will_be_invited_on_save: '(wird beim Speichern eingeladen)',
    invited: '(eingeladen)',
    admin_label: '(Administrator)',
    add_reader: 'Leser hinzufügen',
    passwords_do_not_match: 'Passwörter stimmen nicht überein',
    confirm_password: 'Passwort bestätigen',
    welcome_to_farm: 'Willkommen bei {farmName}!',
    setup_instructions:
      'Hinter den Kulissen analysieren wir Ihre Daten, damit Sie es nicht tun müssen. Um Sie zu informieren, wenn Ihre Pflanzen mehr Wasser benötigen, brauchen wir Ihre Telefonnummer.',
    phone_number: 'Telefonnummer',
    enabled_notifications:
      'Wir werden SMS-Benachrichtigungen für Sie aktivieren. Sie können dies jederzeit ändern, indem Sie auf das Einstellungssymbol neben dem Farmnamen klicken.',
    signup_email_prefilled:
      'Wir haben das E-Mail-Feld für Sie vorausgefüllt, basierend auf der Einladung, die Sie per E-Mail erhalten haben.',
    could_not_save_settings:
      'Einstellungen konnten nicht gespeichert werden. Bitte versuchen Sie es später erneut oder kontaktieren Sie support@soilsense.io, wenn das Problem weiterhin besteht',
    'No sensors assigned to this farm': 'Keine Sensoren für diese Farm zugewiesen',
  },
  [LOCALES.SPANISH]: {
    ...cropsSpanishMessages,
    log_in: 'Iniciar sesión',
    sign_up: 'Regístrate',
    log_out: 'Cerrar sesión',
    no_sensors_assigned: 'No hay sensores asignados a esta granja',
    controls: 'Controles',
    forgot_password_email_sent:
      '¡Correo electrónico de restablecimiento de contraseña enviado con éxito! Consulte su correo electrónico para obtener más instrucciones.',
    login_success: '¡Iniciado sesión con éxito!',
    login_error: 'Algo salió mal al iniciar sesión: {error}',
    signup_success: '¡Registrado con éxito!',
    signup_error: 'Algo salió mal al registrarse: {error}',
    signup_failed_creating_user: 'Error al crear el usuario: {email}',
    not_found_back_to: 'Volver a',
    not_found_page_not_found: 'Página no encontrada',
    cancel_confirm_no: 'No',
    cancel_confirm_yes: 'Sí',
    cancel: 'Cancelar',
    accept: 'Aceptar',
    cancel_are_you_sure: '¿Estás seguro de que quieres cancelar?',
    error_boundary_something_went_wrong: 'Algo salió mal. Lo siento',
    error_boundary_please_refresh: 'Actualice la página para seguir utilizando la aplicación.',
    forgot_password_title: 'Restablecer la contraseña',
    forgot_password_submit: 'Enviar correo electrónico de restablecimiento de contraseña',
    forgot_password_sign_in: 'Inicia sesión en su lugar',
    loading_screen_loading_failed: 'Parece que la aplicación no se cargó',
    loading_screen_loading_failed_description:
      'Asegúrese de tener una conexión de red. Vuelva a cargar la página para intentar cargar de nuevo.',
    loading_screen_loading_failed_contact:
      'Si el problema persiste, contáctenos en support@soilsense.io. ¡Gracias!',
    login_title: 'Iniciar sesión',
    email_address: 'Dirección de correo electrónico',
    password: 'Contraseña',
    login_sign_in: 'Registrarse',
    login_create_new_account: 'Crear nueva cuenta',
    login_forgot_password: '¿Olvidaste tu contraseña? Restablecerlo',
    number_field_please_enter_a_number: 'Por favor ingrese un número',
    number_field_please_enter_a_number_that_is_greater_or_equal_to: 'Ingrese un número mayor o igual a {min}',
    number_field_please_enter_a_number_that_is_less_or_equal_to: 'Ingrese un número menor o igual a {max}',
    number_field_please_enter_a_number_between: 'Ingrese un número entre {min} y {max}',
    from: 'De',
    to: 'A',
    farm_name: 'Nombre de la granja',
    signup_already_have_account_sign_in: '¿Ya tienes una cuenta? Iniciar sesión',
    close: 'Cerrar',
    overview: 'Visión de conjunto',
    charts: 'Gráficos',
    controls_ec_25_c_reference: 'EC (25°C referencia)',
    controls_soil_temperature: 'Temperatura del suelo',
    controls_box_temperature: 'Temperatura de la caja',
    controls_precipitation: 'Precipitación',
    controls_auto_scale: 'Auto escala',
    controls_archived_locations: 'Ubicaciones archivadas',
    download_csv: 'Descargar CSV',
    errors_your_session_expired: 'Su sesión ha caducado. Actualice la página para seguir utilizando la aplicación',
    errors_failed_reaching_the_data_servers: 'Error al alcanzar los servidores de datos',
    errors_the_sensor_needs_a_timezone_set_in_order_to_perform_this_operation:
      'El sensor necesita una zona horaria establecida para realizar esta operación',
    errors_fetching_the_data_took_too_long_try_selecting_shorter_time_period_or_improving_your_network_connection:
      'La recuperación de datos tardó demasiado: intente seleccionar un período de tiempo más corto o mejorar su conexión de red',
    'Failed reaching the data servers': 'Error al alcanzar los servidores de datos',
    farm: 'Granja',
    devices: 'Dispositivos',
    gateway_updated_successfully: 'Gateway {id} actualizado con éxito',
    could_not_update_gateway: 'No se pudo actualizar la puerta de enlace {id}',
    no_settings_updated: 'No se han actualizado las configuraciones',
    sensor_information_updated_successfully: 'Información del sensor actualizada con éxito',
    could_not_update_sensor_information: 'No se pudo actualizar la información del sensor: {error}',
    user_settings_updated_successfully: 'Configuración de usuario actualizada con éxito',
    could_not_update_user_settings: 'No se pudo actualizar la configuración del usuario: {error}',
    farm_settings_updated_successfully: 'Configuración de la granja actualizada con éxito',
    could_not_update_farm_settings: 'No se pudo actualizar la configuración de la granja: {error}',
    field_added: 'Campo agregado con éxito',
    field_not_added: 'No se pudo agregar el campo: {message}',
    add_field: 'Agregar campo',
    click_to_choose_field_corners: 'Haga clic para elegir las esquinas del campo en el mapa',
    area: 'Área: {area} ha',
    add: 'Añadir',
    field_name: 'Nombre del campo',
    settings_general: 'General',
    settings_notifications: 'Notificaciones',
    settings_use_datepicker: 'Usar selector de fecha',
    settings_paw_switch: 'PAW Switch',
    settings_farm_name: 'Nombre de la granja',
    settings_enable_instant_notifications: 'Habilitar notificaciones instantáneas',
    settings_receive_messages_instantly: 'Reciba mensajes al instante sobre eventos críticos',
    settings_instant_notifications_channel: 'Canal de notificaciones instantáneas',
    'Text message': 'Mensaje de texto',
    settings_phone_numbers: 'Números de teléfono',
    settings_type_and_press_enter_to_add_numbers: 'Escriba y presione enter para agregar números',
    settings_choose_how_to_receive_the_instant_notifications: 'Elija cómo recibir las notificaciones instantáneas',
    settings_this_setting_will_be_used_to_set_the_timezone_for_the_notifications:
      'Esta configuración se utilizará para establecer la zona horaria de las notificaciones',
    not_specified: 'No especificado',
    denmark: 'Dinamarca',
    hungary: 'Hungría',
    kenya: 'Kenia',
    lithuania: 'Lituania',
    netherlands: 'Países Bajos',
    peru: 'Perú',
    portugal: 'Portugal',
    spain: 'España',
    sweden: 'Suecia',
    country: 'País',
    crop_type: 'Tipo de cultivo',
    crop_type_not_found: 'Tipo de cultivo no encontrado. Seleccione "Other" y contáctenos si necesita ayuda.',
    field_capacity: 'Capacidad de campo',
    wilting_point: 'Punto de marchitamiento',
    sensor: 'Sensor',
    name: 'Nombre',
    safe_range: '{label} rango seguro',
    subscribe_to_plant_stress_notifications: 'Suscríbase a las notificaciones de estrés de las plantas',
    subscribe_to_plant_stress_notifications_description:
      'Activado cuando cualquiera de las profundidades del sensor está por debajo del rango seguro',
    subscribe_to_over_irrigation_notifications: 'Suscríbase a las notificaciones de riego excesivo',
    subscribe_to_over_irrigation_notifications_description:
      'Activado cuando el sensor más profundo está por encima del rango seguro',
    number_of_sensors: 'Número de sensores',
    sensors_plural: '{count, plural, one {# sensor} other {# sensores}}',
    top_sensor_depth: 'Profundidad del sensor superior',
    middle_sensor_depth: 'Profundidad del sensor medio',
    bottom_sensor_depth: 'Profundidad del sensor inferior',
    sensor_depth_hint: 'Profundidad debajo de la superficie donde se instala el sensor',
    adjust_soil_settings: 'Ajustar la configuración del suelo',
    calibrations_updated: 'Calibraciones actualizadas',
    error_updating_calibrations: 'Error al actualizar las calibraciones: {error}',
    calibration_suggestion: 'Sugerencia de calibración',
    apply_calibration_suggestion: 'Aplicar sugerencia de calibración',
    cancel_calibration_suggestion: 'Cancelar sugerencia de calibración',
    calibration_history: 'Historial de calibración',
    delete_history_item: 'Eliminar elemento de historial',
    volumetric_water_content: 'Contenido volumétrico de agua',
    water_content: 'Contenido de agua',
    new_data_logger_save_to_see_status: 'Nuevo registrador de datos - guardar para ver el estado',
    plant_available_water: 'Agua disponible para las plantas',
    this_device_cannot_be_added: 'El dispositivo con el ID proporcionado no se puede agregar',
    provide_coordinates: 'Proporcionar coordenadas',
    connect_antennas_title: 'Conecta las antenas',
    connect_antennas_description: 'Conecte las antenas y luego presione siguiente.',
    reset_and_check_signal_title: 'Restablecer y verificar la intensidad de la señal',
    reset_and_check_signal_description:
      'Deslice el imán de reinicio junto a la etiqueta de reinicio en la puerta de enlace. Presione siguiente si el LED se enciende en blanco y luego en verde.',
    gateway_could_not_be_added: 'No se pudo agregar la puerta de enlace',
    done: 'Hecho',
    data_logger_could_not_be_added: 'No se pudo agregar el registrador de datos',
    nb_data_logger_could_not_be_added: 'No se pudo agregar el registrador de datos NB',
    add_new_device: 'Agregar un nuevo dispositivo',
    gateway_done_a: '¡Felicitaciones, agregaste con éxito una puerta de enlace!',
    gateway_done_b:
      'En unos 5 minutos, verifique que tenga un círculo verde junto a él en la lista de dispositivos de arriba, lo que indica que se conectó correctamente a Internet',
    sensor_done_a: '¡Felicitaciones, agregaste con éxito un registrador de datos!',
    sensor_done_b:
      'En unos 30 minutos, verifique que tenga un círculo verde junto a él en la lista de dispositivos de arriba, lo que indica que se conectó correctamente a la puerta de enlace y transmitió datos',
    nb_logger_done_b:
      'En aproximadamente una hora, verifique que tenga un círculo verde junto a él en la lista de dispositivos de arriba, lo que indica que está transmitiendo datos',
    go_to_installation_location: 'Ir a la ubicación de instalación',
    go_to_installation_location_content:
      'Antes de continuar, asegúrese de estar ubicado donde colocará el dispositivo. Haga clic en siguiente si está parado donde se colocará el dispositivo.',
    reset_and_check_signal_strength: 'Restablecer y verificar la intensidad de la señal',
    reset_and_check_signal_strength_content:
      'Deslice el imán de reinicio junto a la etiqueta de reinicio en el dispositivo. Presione siguiente si la luz debajo de la etiqueta se enciende en blanco y luego en verde',
    reset_and_check_signal_strength_content_nb:
      'Encienda el registrador de datos presionando el botón verde. Presione siguiente si la luz debajo de la etiqueta se enciende en blanco y luego en verde',
    select_device_to_move: 'Seleccione el dispositivo para mover',
    previous_location_archived:
      'La ubicación anterior {originalLocationName} se archivará cuando guarde la configuración.',
    move_device: 'Mover dispositivo',
    provide_device_id: 'Proporcionar ID de dispositivo',
    device_id: 'ID de dispositivo',
    get_gps_coordinates: 'Obtener coordenadas GPS',
    geolocation_in_progress: 'Se necesitan unos segundos para obtener estimaciones de posición precisas',
    geolocation_denied:
      'Asegúrese de configurar los permisos de ubicación en "Permitir" y vuelva a cargar la aplicación para usar esta función',
    geographic_longitude_hint: 'Longitud geográfica (de 0 a 180°)',
    geographic_latitude_hint: 'Latitud geográfica (de 0 a 90°)',
    latitude: 'Latitud',
    longitude: 'Longitud',
    or_type_manually: 'O escriba manualmente las coordenadas',
    accuracy: 'Precisión',
    location_name: 'Nombre de ubicación',
    configure_location_title: 'Configurar nueva ubicación',
    field_deleted: 'Campo eliminado con éxito',
    field_not_deleted: 'No se pudo eliminar el campo: {message}',
    cannot_download_csv_because_no_farm: 'No se puede descargar CSV porque no se ha seleccionado ninguna granja',
    successfully_calculated_new_field_capacity: 'Se calculó con éxito una nueva capacidad de campo',
    successfully_calculated_new_wilting_point: 'Se calculó con éxito un nuevo punto de marchitamiento',
    soil_parameters: 'Parámetros del suelo',
    successfully_updated_sensor_information: 'Información del sensor actualizada con éxito',
    failed_updating_sensor_information: 'Error al actualizar la información del sensor: {error}',
    adjust_field_capacity: 'Ajustar la capacidad del campo',
    apply: 'Aplicar',
    farm_country_not_specified: 'País agrícola no especificado',
    forgot_password_error: 'Algo salió mal al restablecer la contraseña: {error}',
    finish: 'Terminar',
    next: 'Siguiente',
    save: 'Guardar',
    CANNOT_FETCH_SENSOR_DATA_NO_USER_LOGGED_IN:
      'No se pueden recuperar los datos del sensor: ningún usuario ha iniciado sesión',
    DEVICE_SETTINGS_REQUEST_FAILED: 'Error de solicitud de configuración del dispositivo. {details}',
    CANNOT_FETCH_SENSOR_DATA_NO_PRECIPITATION_DATA:
      'No se pueden recuperar los datos del sensor: no hay datos de precipitación disponibles. {details}',
    NO_DATA_SOURCE_ON_OBSERVATION_SITE:
      'No se pudo encontrar la fuente de datos en el sitio de observación. {details}',
    NO_DATA_FOR_SELECTED_DATES: 'No se encontraron datos para las fechas seleccionadas. {details}',
    data_logger_sleep_time_saved_successfully: 'Tiempo de suspensión del registrador de datos guardado con éxito',
    data_logger_sleep_time: 'Tiempo de suspensión del registrador de datos',
    new_gateway_save_to_see_status: 'Nueva puerta de enlace. Guardar para ver el estado',
    min: 'min',
    last_ack_value: 'Último valor reconocido: {minutes_string} {seconds_string} ({fromNow})',
    irrigation: 'Riego',
    rainfall: 'Precipitación',
    moisture_safe_range: 'Rango seguro',
    total_rainfall: 'Precipitación total',
    total_irrigation: 'Riego total',
    salinity_safe_range: 'Rango seguro de CE',
    depth_names_moisture: 'Humedad',
    box_temperature: 'Temperatura de la caja',
    depth_names_soil_temperature: 'Temperatura del suelo',
    soil_status_label_ok: 'Todo bien',
    soil_status_label_dry: 'Seco',
    soil_status_label_wilting: 'Muy seco',
    soil_status_label_overIrrigated: 'Demasiado húmedo',
    soil_status_label_unknown: 'Sin datos',
    last_online: 'Última conexión: {lastOnline}',
    no_status_available: 'No hay estado disponible',
    fetching: 'Recuperando',
    'Box temperature': 'Temperatura de la caja',
    waiting_for_heavy_irrigation:
      'El rango óptimo de humedad del suelo se mostrará después de regar o después de una lluvia fuerte',
    select_suggestion: 'Seleccionar sugerencia',
    turn_on_the_device_title: 'Encienda el dispositivo',
    turn_on_the_device_description:
      'Encienda el registrador de datos presionando el botón verde. Presione siguiente si la luz es blanca.',
    box_temperature_warning:
      'Mientras que la temperatura de la caja a menudo es muy cercana a la temperatura del aire, puede verse afectada por la luz solar directa. Esto puede resultar en lecturas inexactas, especialmente durante el día.',
    controls_air_temperature: 'Temperatura del aire',
    coordinates: 'Coordenadas',
    settings_max_phone_numbers_reached: 'Ha alcanzado el número máximo de números de teléfono',
    warming_up_the_gps: 'Inicializando el GPS. Puede tardar un momento',
    search_for_a_location_or_enter_coordinates: 'Buscar un lugar o ingresar coordenadas',
    settings_readers: 'Lectores',
    user_not_found: 'Usuario no encontrado',
    reader_added_successfully: 'Lector agregado con éxito',
    could_not_add_reader: 'No se pudo agregar el lector: {error}',
    reader_removed_successfully: 'Lector eliminado con éxito',
    could_not_remove_reader: 'No se pudo eliminar el lector: {error}',
    will_be_invited_on_save: '(será invitado al salvataggio)',
    invited: '(invitado)',
    admin_label: '(Administrador)',
    add_reader: 'Agregar lector',
    passwords_do_not_match: 'Las contraseñas no coinciden',
    confirm_password: 'Confirmar contraseña',
    welcome_to_farm: '¡Bienvenido a {farmName}!',
    setup_instructions:
      'Detrás de escena, analizamos sus datos para que usted no tenga que hacerlo. Para informarle cuando su planta necesite más agua, necesitamos su número de teléfono.',
    phone_number: 'Número de teléfono',
    enabled_notifications:
      'Habilitaremos las notificaciones por SMS para usted. Puede cambiar esto en cualquier momento haciendo clic en el icono de configuración junto al nombre de la granja.',
    signup_email_prefilled:
      'Hemos rellenado previamente el campo de correo electrónico según la invitación que recibiste en tu correo.',
    could_not_save_settings: 'No se pudieron guardar los ajustes: {error}',
    'No sensors assigned to this farm': 'No hay sensores asignados a esta granja',
  },
  [LOCALES.ITALIAN]: {
    ...cropsItalianMessages,
    log_in: 'Accedi',
    sign_up: 'Registrati',
    log_out: 'Esci',
    no_sensors_assigned: 'Nessun sensore assegnato a questa fattoria',
    controls: 'Controlli',
    forgot_password_email_sent:
      'Email di reset password inviata con successo! Controlla la tua email per ulteriori istruzioni.',
    login_success: 'Accesso effettuato con successo!',
    login_error: "Qualcosa è andato storto durante l'accesso: {error}",
    signup_success: 'Registrazione completata con successo!',
    signup_error: 'Qualcosa è andato storto durante la registrazione: {error}',
    signup_failed_creating_user: "Errore durante la creazione dell'utente: {email}",
    not_found_back_to: 'Torna a',
    not_found_page_not_found: 'Pagina non trovata',
    cancel_confirm_no: 'No',
    cancel_confirm_yes: 'Sì',
    cancel: 'Annulla',
    accept: 'Accetta',
    cancel_are_you_sure: 'Sei sicuro di voler annullare?',
    error_boundary_something_went_wrong: 'Ci scusiamo, ma qualcosa è andato storto.',
    error_boundary_please_refresh: "Aggiorna la pagina per continuare a utilizzare l'applicazione.",
    forgot_password_title: 'Reimposta la password',
    forgot_password_submit: 'Invia email di reset password',
    forgot_password_sign_in: 'Password dimenticata?',
    loading_screen_loading_failed: "Sembra che il caricamento dell'applicazione non sia avvenuto correttamente",
    loading_screen_loading_failed_description:
      'Assicurati di avere una connessione di rete. Ricarica la pagina per riprovare.',
    loading_screen_loading_failed_contact: 'Se il problema persiste, contattaci a support@soilsense.io. Grazie!',
    login_title: 'Accedi',
    email_address: 'Indirizzo Email',
    password: 'Password',
    login_sign_in: 'Accedi',
    login_create_new_account: 'Crea un  nuovo account',
    login_forgot_password: 'Password dimenticata? Reimpostala',
    number_field_please_enter_a_number: 'Inserisci un numero',
    number_field_please_enter_a_number_that_is_greater_or_equal_to:
      'Inserisci un numero maggiore o uguale a {min}',
    number_field_please_enter_a_number_that_is_less_or_equal_to: 'Inserisci un numero minore o uguale a {max}',
    number_field_please_enter_a_number_between: 'Inserisci un numero tra {min} e {max}',
    from: 'Da',
    to: 'A',
    farm_name: "Nome dell'azienda agricola",
    signup_already_have_account_sign_in: 'Hai già un account? Accedi',
    close: 'Chiudi',
    overview: 'Panoramica',
    charts: 'Grafici',
    controls_ec_25_c_reference: 'EC (riferimento 25°C)',
    controls_soil_temperature: 'Temperatura del suolo',
    controls_box_temperature: 'Temperatura della scatola',
    controls_precipitation: 'Precipitazioni',
    controls_auto_scale: 'Scala automatica',
    controls_archived_locations: 'Posizioni archiviate',
    download_csv: 'Scarica CSV',
    errors_your_session_expired:
      "La tua sessione è scaduta. Aggiorna la pagina per continuare a utilizzare l'applicazione",
    errors_failed_reaching_the_data_servers: 'Impossibile raggiungere i server dati',
    errors_the_sensor_needs_a_timezone_set_in_order_to_perform_this_operation:
      'Il sensore necessita di un fuso orario impostato per eseguire questa operazione',
    errors_fetching_the_data_took_too_long_try_selecting_shorter_time_period_or_improving_your_network_connection:
      'Il recupero dei dati ha richiesto troppo tempo - prova a selezionare un periodo più breve o a migliorare la connessione di rete',
    'Failed reaching the data servers': 'Impossibile raggiungere i server dati',
    farm: 'Azienda agricola',
    devices: 'Dispositivi',
    gateway_updated_successfully: 'Gateway {id} aggiornato con successo',
    could_not_update_gateway: 'Impossibile aggiornare il gateway {id}',
    no_settings_updated: 'Nessuna impostazione è stata aggiornata',
    sensor_information_updated_successfully: 'Informazioni del sensore aggiornate con successo',
    could_not_update_sensor_information: 'Impossibile aggiornare le informazioni del sensore: {error}',
    user_settings_updated_successfully: 'Impostazioni utente aggiornate con successo',
    could_not_update_user_settings: 'Impossibile aggiornare le impostazioni utente: {error}',
    farm_settings_updated_successfully: "Impostazioni dell'azienda agricola aggiornate con successo",
    could_not_update_farm_settings: "Impossibile aggiornare le impostazioni dell'azienda agricola: {error}",
    field_added: 'Campo aggiunto con successo',
    field_not_added: 'Impossibile aggiungere il campo: {message}',
    add_field: 'Aggiungi Campo',
    click_to_choose_field_corners: 'Clicca per scegliere gli angoli del campo sulla mappa',
    area: 'Area: {area} ha',
    add: 'Aggiungi',
    field_name: 'Nome del campo',
    settings_general: 'Generale',
    settings_notifications: 'Notifiche',
    settings_use_datepicker: 'Usa Selettore Data',
    settings_paw_switch: 'Interruttore Acqua Disponibile',
    settings_farm_name: 'Nome Azienda agricola',
    settings_enable_instant_notifications: 'Abilita notifiche istantanee',
    settings_receive_messages_instantly: 'Ricevi notifiche istantanee su eventi critici',
    settings_instant_notifications_channel: 'Canale notifiche istantanee',
    'Text message': 'Messaggio di testo',
    settings_phone_numbers: 'Numeri di telefono',
    settings_type_and_press_enter_to_add_numbers: 'Digita e premi invio per aggiungere numeri',
    settings_choose_how_to_receive_the_instant_notifications: 'Scegli come ricevere le notifiche istantanee',
    settings_this_setting_will_be_used_to_set_the_timezone_for_the_notifications:
      'Questa impostazione verrà utilizzata per impostare il fuso orario per le notifiche',
    not_specified: 'Non specificato',
    denmark: 'Danimarca',
    hungary: 'Ungheria',
    kenya: 'Kenya',
    lithuania: 'Lituania',
    netherlands: 'Paesi Bassi',
    peru: 'Perù',
    portugal: 'Portogallo',
    spain: 'Spagna',
    sweden: 'Svezia',
    country: 'Paese',
    crop_type: 'Tipo di coltura',
    crop_type_not_found: 'Tipo di coltura non trovato. Seleziona "Altro" e contattaci se hai bisogno di aiuto.',
    field_capacity: 'Capacità di campo',
    wilting_point: 'Punto di appassimento',
    sensor: 'Sensore',
    name: 'Nome',
    safe_range: 'Intervallo sicuro {label}',
    subscribe_to_plant_stress_notifications: 'Attiva le notifiche in caso di stress delle piante',
    subscribe_to_plant_stress_notifications_description:
      "Attivate quando una qualsiasi delle profondità del sensore è al di sotto dell'intervallo sicuro",
    subscribe_to_over_irrigation_notifications: 'Attiva le notifiche in caso di irrigazione eccessiva',
    subscribe_to_over_irrigation_notifications_description:
      "Attivate quando il sensore più profondo è al di sopra dell'intervallo sicuro",
    number_of_sensors: 'Numero di sensori',
    sensors_plural: '{count, plural, one {# sensore} other {# sensori}}',
    top_sensor_depth: 'Profondità sensore superiore',
    middle_sensor_depth: 'Profondità sensore medio',
    bottom_sensor_depth: 'Profondità sensore inferiore',
    sensor_depth_hint: 'Profondità sotto la superficie dove è installato il sensore',
    adjust_soil_settings: 'Regola impostazioni del suolo',
    calibrations_updated: 'Calibrazioni aggiornate con successo',
    error_updating_calibrations: "Errore durante l'aggiornamento delle calibrazioni: {error}",
    calibration_suggestion: 'Suggerimento di calibrazione',
    apply_calibration_suggestion: 'Applica suggerimento di calibrazione',
    cancel_calibration_suggestion: 'Annulla suggerimento di calibrazione',
    calibration_history: 'Cronologia calibrazione',
    delete_history_item: 'Elimina elemento dalla cronologia',
    volumetric_water_content: 'Contenuto volumetrico di acqua',
    water_content: 'Contenuto idrico',
    new_data_logger_save_to_see_status: 'Nuovo datalogger - salva per vedere lo stato',
    plant_available_water: 'Acqua disponibile per le piante',
    this_device_cannot_be_added: "Il dispositivo con l'ID fornito non può essere aggiunto",
    provide_coordinates: 'Fornisci coordinate',
    connect_antennas_title: 'Collega le antenne',
    connect_antennas_description: 'Collega le antenne e poi premi avanti.',
    reset_and_check_signal_title: 'Reimposta e controlla la potenza del segnale',
    reset_and_check_signal_description:
      "Passa il magnete di reset accanto all'adesivo di reset sul gateway. Premi avanti se il LED si illumina di bianco e poi verde.",
    gateway_could_not_be_added: 'Impossibile aggiungere il gateway',
    done: 'Fatto',
    data_logger_could_not_be_added: 'Impossibile aggiungere il datalogger',
    nb_data_logger_could_not_be_added: 'Impossibile aggiungere il data logger NB',
    add_new_device: 'Aggiungi nuovo dispositivo',
    gateway_done_a: 'Congratulazioni, hai aggiunto con successo un gateway!',
    gateway_done_b:
      'Tra circa 5 minuti controlla che abbia un cerchio verde accanto ad esso nella lista dei dispositivi sopra, che indica che si è connesso con successo a internet',
    sensor_done_a: 'Congratulazioni, hai aggiunto con successo un datalogger!',
    sensor_done_b:
      'Tra circa 30 minuti controlla il datalogger abbia un pallino verde accanto ad esso nella lista dei dispositivi sopra, che indica che si è connesso con successo al gateway e ha trasmesso i dati',
    nb_logger_done_b:
      "Tra circa un'ora, controlla che abbia un pallino verde accanto ad esso nella lista dei dispositivi sopra, che indica che sta trasmettendo i dati",
    go_to_installation_location: 'Vai al luogo di installazione',
    go_to_installation_location_content:
      'Prima di procedere, assicurati di essere nel punto in cui collocherai il dispositivo. Clicca avanti se sei nel punto in cui verrà posizionato il dispositivo.',
    reset_and_check_signal_strength: 'Reimposta e controlla la potenza del segnale',
    reset_and_check_signal_strength_content:
      "Passa il magnete di reset accanto all'adesivo di reset sul dispositivo. Premi avanti se la luce sotto l'adesivo si illumina di bianco e poi verde",
    reset_and_check_signal_strength_content_nb:
      "Accendi il datalogger usando il pulsante verde. Premi avanti se la luce sotto l'adesivo si illumina di bianco e poi verde",
    select_device_to_move: 'Seleziona dispositivo da spostare',
    previous_location_archived:
      'La posizione precedente {originalLocationName} verrà archiviata quando salvi le impostazioni.',
    move_device: 'Sposta dispositivo',
    provide_device_id: 'Fornisci ID dispositivo',
    device_id: 'ID dispositivo',
    get_gps_coordinates: 'Ottieni coordinate GPS',
    geolocation_in_progress: 'Ci vogliono alcuni secondi per ottenere una stima accurata della posizione',
    geolocation_denied:
      'Assicurati di impostare i permessi di localizzazione su "Consenti" e ricarica l applicazione per utilizzare questa funzione',
    geographic_longitude_hint: 'Longitudine geografica (da 0 a 180°)',
    geographic_latitude_hint: 'Latitudine geografica (da 0 a 90°)',
    latitude: 'Latitudine',
    longitude: 'Longitudine',
    or_type_manually: 'O inserisci manualmente le coordinate',
    accuracy: 'Precisione',
    location_name: 'Nome posizione',
    configure_location_title: 'Configura nuova posizione',
    field_deleted: 'Campo eliminato con successo',
    field_not_deleted: 'Impossibile eliminare il campo: {message}',
    cannot_download_csv_because_no_farm: 'Impossibile scaricare CSV perché nessuna azienda agricola è selezionata',
    successfully_calculated_new_field_capacity: 'Calcolata con successo una nuova Capacità di Campo',
    successfully_calculated_new_wilting_point: 'Calcolato con successo un nuovo Punto di Appassimento',
    soil_parameters: 'Parametri del suolo',
    successfully_updated_sensor_information: 'Informazioni del sensore aggiornate con successo',
    failed_updating_sensor_information: "Errore durante l'aggiornamento delle informazioni del sensore: {error}",
    adjust_field_capacity: 'Regola Capacità di Campo',
    apply: 'Applica',
    farm_country_not_specified: "Paese dell'azienda agricola non specificato",
    forgot_password_error: 'Qualcosa è andato storto durante il reset della password: {error}',
    finish: 'Termina',
    next: 'Avanti',
    save: 'Salva',
    CANNOT_FETCH_SENSOR_DATA_NO_USER_LOGGED_IN:
      "Impossibile recuperare i dati del sensore: Nessun utente ha effettuato l'accesso",
    DEVICE_SETTINGS_REQUEST_FAILED: 'Richiesta impostazioni dispositivo fallita. {details}',
    CANNOT_FETCH_SENSOR_DATA_NO_PRECIPITATION_DATA:
      'Impossibile recuperare i dati del sensore: Nessun dato sulle precipitazioni disponibile. {details}',
    NO_DATA_SOURCE_ON_OBSERVATION_SITE:
      'Impossibile trovare la fonte dei dati sul sito di osservazione. {details}',
    NO_DATA_FOR_SELECTED_DATES: 'Nessun dato trovato per le date selezionate. {details}',
    data_logger_sleep_time_saved_successfully: 'Tempo di sospensione del datalogger salvato con successo',
    data_logger_sleep_time: 'Tempo di sospensione del datalogger',
    new_gateway_save_to_see_status: 'Nuovo gateway. Salva per vedere lo stato',
    min: 'min',
    last_ack_value: 'Ultimo valore riconosciuto: {minutes_string} {seconds_string} ({fromNow})',
    irrigation: 'Irrigazione',
    rainfall: 'Precipitazioni',
    moisture_safe_range: 'Intervallo sicuro di umidità',
    total_rainfall: 'Precipitazioni totali',
    total_irrigation: 'Irrigazione totale',
    salinity_safe_range: 'Intervallo sicuro EC',
    depth_names_moisture: 'Umidità',
    box_temperature: "Temperatura dell'aria (dentro il datalogger)",
    depth_names_soil_temperature: 'Temperatura del suolo',
    soil_status_label_ok: 'Tutto bene',
    soil_status_label_dry: 'Secco',
    soil_status_label_wilting: 'Molto secco',
    soil_status_label_overIrrigated: 'Troppo umido',
    soil_status_label_unknown: 'Nessun dato',
    last_online: 'Ultimo accesso: {lastOnline}',
    no_status_available: 'Nessuno stato disponibile',
    fetching: 'Recupero',
    'Box temperature': 'Temperatura della scatola',
    waiting_for_heavy_irrigation:
      "L'intervallo ottimale di umidità del suolo verrà mostrato dopo l'irrigazione o dopo una forte pioggia",
    select_suggestion: 'Seleziona suggerimento',
    turn_on_the_device_title: 'Accendi il dispositivo',
    turn_on_the_device_description:
      'Accendi il datalogger usando il pulsante verde. Premi avanti se la luce è bianca.',
    box_temperature_warning:
      "Mentre la temperatura dell'aria all'interno del datalogger è spesso molto vicina alla temperatura dell'aria reale, può essere significativamente influenzata dalla luce solare diretta. Pertanto le letture potrebbero essere troppo alte durante il giorno.",
    controls_air_temperature: "Temperatura dell'aria",
    coordinates: 'Coordinate',
    settings_max_phone_numbers_reached: 'Hai raggiunto il numero massimo di numeri di telefono',
    warming_up_the_gps: 'Il GPS si sta inizializzando. Potrebbe volerci qualche momento',
    search_for_a_location_or_enter_coordinates: 'Cerca un luogo o inserisci le coordinate',
    settings_readers: 'Lettori',
    user_not_found: 'Utente non trovato',
    reader_added_successfully: 'Lettore aggiunto con successo',
    could_not_add_reader: 'Impossibile aggiungere il lettore: {error}',
    reader_removed_successfully: 'Lettore rimosso con successo',
    could_not_remove_reader: 'Impossibile rimuovere il lettore: {error}',
    will_be_invited_on_save: '(sarà invitato al salvataggio)',
    invited: '(invitato)',
    admin_label: '(Amministratore)',
    add_reader: 'Aggiungi lettore',
    passwords_do_not_match: 'Le password non corrispondono',
    confirm_password: 'Conferma password',
    welcome_to_farm: 'Benvenuto a {farmName}!',
    setup_instructions: 'Inserisci il tuo numero di telefono per ricevere le notifiche.',
    phone_number: 'Numero di telefono',
    enabled_notifications:
      "Abiliteremo le notifiche SMS per te. Puoi cambiare questa impostazione in qualsiasi momento cliccando sull'icona delle impostazioni accanto al nome dell'azienda agricola.",
    signup_email_prefilled:
      "Abbiamo precompilato il campo email in base all'invito che hai ricevuto nella tua email.",
    could_not_save_settings: 'Impossibile salvare le impostazioni: {error}',
    'No sensors assigned to this farm': 'Nessun sensore assegnato a questa azienda agricola',
  },
};

// count keys in each locale
const keys = Object.keys(messages[LOCALES.ENGLISH]);
for (const locale of Object.values(LOCALES)) {
  const localeKeys = Object.keys(messages[locale]);
  if (localeKeys.length !== keys.length) {
    console.error(`Locale ${locale} has ${localeKeys.length} keys, but English has ${keys.length}`);
  }

  // check for missing or extra keys
  const missingKeys = keys.filter((key) => Object.keys(messages[locale]).indexOf(key) === -1);
  const extraKeys = Object.keys(messages[locale]).filter((key) => keys.indexOf(key) === -1);
  if (missingKeys.length > 0) {
    console.error(`Locale ${locale} has missing keys: ${missingKeys.join(', ')}`);
  }
  if (extraKeys.length > 0) {
    console.error(`Locale ${locale} has extra keys: ${extraKeys.join(', ')}`);
  }
}
