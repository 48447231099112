import { Box, Button, Container, CssBaseline, Grid, Link, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router';
import { Routes } from '../Routes';
import Logo, { LogoColor } from './Logo';
import Progress from './Progress';

const useStyles = makeStyles((theme) => ({
  '@global': {
    html: {
      overflow: 'hidden',
    },
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'rgb(255, 255, 255, 0.65)',
    padding: 20,
    borderRadius: 10,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  glowingText: {
    textShadow: `0 0 5px #fff`,
  },
}));

export interface ILoginForm {
  elements: {
    email: {
      value: string;
    };
    password: {
      value: string;
    };
  };
}

interface IProps {
  onSubmit: (event: React.FormEvent<HTMLFormElement & ILoginForm>) => void;
  loading?: boolean;
}

const Login: React.FC<IProps> = ({ onSubmit, loading }) => {
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <Box className={classes.paper} boxShadow={3}>
        <Logo color={LogoColor.red} width={100} />
        <Typography
          component='h1'
          variant='h5'
          style={{
            paddingTop: 10,
          }}
        >
          <FormattedMessage id='login_title' />
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            id='email'
            label={intl.formatMessage({ id: 'email_address' })}
            name='email'
            autoComplete='email'
            autoFocus
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            name='password'
            label={intl.formatMessage({ id: 'password' })}
            type='password'
            id='password'
            autoComplete='current-password'
          />
          <Box display='flex' flexDirection='column' gap={2} my={2}>
            <Button
              id='sign-in-button'
              type='submit'
              fullWidth
              variant='contained'
              color='primary'
              disabled={loading}
            >
              <FormattedMessage id='login_sign_in' />
              <Box mx={1.5} display='flex' alignItems='center'>
                {loading ? <Progress size={20} /> : undefined}
              </Box>
            </Button>
            <Button
              id='sign-up-button'
              fullWidth
              variant='contained'
              color='secondary'
              disabled={loading}
              onClick={() => {
                history.push(Routes.SIGNUP);
              }}
            >
              <FormattedMessage id='login_create_new_account' />
            </Button>
          </Box>
          <Grid container justifyContent='flex-end' alignContent='flex-end'>
            <Grid item>
              <Link href={Routes.RESET_PASSWORD} variant='body2' className={classes.glowingText} underline='hover'>
                <FormattedMessage id='login_forgot_password' />
              </Link>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default Login;
