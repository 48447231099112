import {
  BOX_TEMPERATURE_ATTRIBUTE,
  SALINITY_ATTRIBUTE,
  TEMPERATURE_ATTRIBUTE,
  WATER_VOLUME_ATTRIBUTE,
} from '../../consts/backendAttributes';
import type { IBoxDataPoint, IDataPointPart, IMidDataPointPart } from '../../interfaces/IDataElement';
import type {
  PLANT_AVAILABLE_WATER_BOT,
  PLANT_AVAILABLE_WATER_MID,
  PLANT_AVAILABLE_WATER_TOP,
} from './constsAndTypes';
import {
  BOX_TEMPERATURE,
  SALINITY_BOT,
  SALINITY_MID,
  SALINITY_TOP,
  TEMPERATURE_BOT,
  TEMPERATURE_MID,
  TEMPERATURE_TOP,
  VOLUMETRIC_WATER_CONTENT_BOT,
  VOLUMETRIC_WATER_CONTENT_MID,
  VOLUMETRIC_WATER_CONTENT_TOP,
} from './constsAndTypes';

const SALINITY_SCALING_FACTOR = 1000;

export type TransformFunction = (vwc: number) => number | undefined;

export type ITransformFunctionsObj = Readonly<{
  transformTop: TransformFunction;
  transformMid: TransformFunction;
  transformBot: TransformFunction;
}>;

export const DEFAULT_TRANSFORM_FUNCTION: TransformFunction = () => undefined;

function temperatureAdjustedSalinity(point: IMidDataPointPart, amendSalinity: boolean): number | undefined {
  let salinity = point[SALINITY_ATTRIBUTE];
  const temperature = point[TEMPERATURE_ATTRIBUTE];
  if (salinity == undefined || temperature == undefined) {
    return undefined;
  }
  if (amendSalinity) {
    salinity /= 10;
  }
  return (salinity * (1 - 0.0216 * (temperature - 25))) / SALINITY_SCALING_FACTOR;
}

export const extractValues = (
  top: IDataPointPart,
  bot: IDataPointPart,
  box: IBoxDataPoint,
  mid: IMidDataPointPart | undefined,
  amendSalinity: boolean
): {
  [VOLUMETRIC_WATER_CONTENT_TOP]: number | undefined;
  [VOLUMETRIC_WATER_CONTENT_BOT]: number | undefined;
  [VOLUMETRIC_WATER_CONTENT_MID]: number | undefined;
  [SALINITY_TOP]: number | undefined;
  [SALINITY_MID]: number | undefined;
  [SALINITY_BOT]: number | undefined;
  [BOX_TEMPERATURE]: number | undefined;
  [TEMPERATURE_TOP]: number | undefined;
  [TEMPERATURE_MID]: number | undefined;
  [TEMPERATURE_BOT]: number | undefined;
} => ({
  [VOLUMETRIC_WATER_CONTENT_TOP]: top[WATER_VOLUME_ATTRIBUTE],
  [VOLUMETRIC_WATER_CONTENT_MID]: mid ? mid[WATER_VOLUME_ATTRIBUTE] : undefined,
  [VOLUMETRIC_WATER_CONTENT_BOT]: bot[WATER_VOLUME_ATTRIBUTE],
  [SALINITY_TOP]: temperatureAdjustedSalinity(top, amendSalinity),
  [SALINITY_MID]: mid ? temperatureAdjustedSalinity(mid, amendSalinity) : undefined,
  [SALINITY_BOT]: temperatureAdjustedSalinity(bot, amendSalinity),
  [BOX_TEMPERATURE]: box[BOX_TEMPERATURE_ATTRIBUTE],
  [TEMPERATURE_TOP]: top[TEMPERATURE_ATTRIBUTE],
  [TEMPERATURE_MID]: mid ? mid[TEMPERATURE_ATTRIBUTE] : undefined,
  [TEMPERATURE_BOT]: bot[TEMPERATURE_ATTRIBUTE],
});

export type ExtractedValuesPaw = {
  [PLANT_AVAILABLE_WATER_TOP]: number | undefined;
  [PLANT_AVAILABLE_WATER_MID]: number | undefined;
  [PLANT_AVAILABLE_WATER_BOT]: number | undefined;
  [SALINITY_TOP]: number | undefined;
  [SALINITY_MID]: number | undefined;
  [SALINITY_BOT]: number | undefined;
  [BOX_TEMPERATURE]: number | undefined;
  [TEMPERATURE_TOP]: number | undefined;
  [TEMPERATURE_MID]: number | undefined;
  [TEMPERATURE_BOT]: number | undefined;
  [VOLUMETRIC_WATER_CONTENT_TOP]: number | undefined;
  [VOLUMETRIC_WATER_CONTENT_MID]: number | undefined;
  [VOLUMETRIC_WATER_CONTENT_BOT]: number | undefined;
};

const extractValuesPaw = (
  top: IDataPointPart,
  bot: IDataPointPart,
  box: IBoxDataPoint,
  { transformTop, transformMid, transformBot }: ITransformFunctionsObj,
  mid: IMidDataPointPart | undefined,
  amendSalinity: boolean
): ExtractedValuesPaw => {
  const vals = extractValues(top, bot, box, mid, amendSalinity);
  const { vwcTop, vwcMid, vwcBot } = vals;

  const [pawTop, pawMid, pawBot] = [
    vwcTop != undefined ? transformTop(vwcTop) : undefined,
    vwcMid != undefined ? transformMid(vwcMid) : undefined,
    vwcBot != undefined ? transformBot(vwcBot) : undefined,
  ];

  // paw is in % so shouldn't be lower than 0
  const pawNonNegative = {
    pawTop: pawTop != undefined ? Math.max(0, pawTop) : undefined,
    pawMid: mid && pawMid != undefined ? Math.max(0, pawMid) : undefined,
    pawBot: pawBot != undefined ? Math.max(0, pawBot) : undefined,
  };

  return {
    ...vals,
    ...pawNonNegative,
  };
};

// takes paw and depth in mm and returns the
// mm of water at the depth of interest by
// extrapolating linearly between the top and bottom
export const extractValuesMm = (props: {
  top: {
    fc: number | undefined;
    vwc: number | undefined;
    depthMm: number;
  };
  bot: {
    fc: number | undefined;
    vwc: number | undefined;
    depthMm: number;
  };
  depthOfInterestMm: number;
  accuracyMm?: number;
}): {
  currentWaterContentMm: number | undefined;
  fieldCapacityMm: number | undefined;
  additionalCapacityMm: number | undefined;
} => {
  const { top, bot, depthOfInterestMm, accuracyMm } = props;
  const { vwc: vwcTop, fc: fcTop, depthMm: depthTop } = top;
  const { vwc: vwcBot, fc: fcBot, depthMm: depthBot } = bot;

  // Check if vwcTop or vwcBot is undefined
  if (vwcTop === undefined || vwcBot === undefined) {
    return {
      currentWaterContentMm: undefined,
      fieldCapacityMm: undefined,
      additionalCapacityMm: undefined,
    };
  }

  // Calculate slope for VWC
  const vwcSlope = (vwcBot - vwcTop) / (depthBot - depthTop);

  // Calculate slope for FC only if both fcTop and fcBot are defined
  const fcSlope = fcTop !== undefined && fcBot !== undefined ? (fcBot - fcTop) / (depthBot - depthTop) : undefined;

  // Function to calculate VWC or FC at a given depth
  const calculateValueAtDepth = (startValue: number, slope: number, startDepth: number, endDepth: number) => {
    return startValue + slope * (endDepth - startDepth);
  };

  // Calculate water content and field capacity in mm
  let currentWaterContentMm = 0;
  let fieldCapacityMm: number | undefined = fcSlope !== undefined ? 0 : undefined;
  const stepSize = accuracyMm ?? 1; // mm, for precise calculation

  for (let depth = 0; depth < depthOfInterestMm; depth += stepSize) {
    const vwc = calculateValueAtDepth(vwcTop, vwcSlope, depthTop, depth);

    // Calculate fc only if fcSlope is defined
    const fc =
      fcSlope !== undefined && fcTop !== undefined
        ? calculateValueAtDepth(fcTop, fcSlope, depthTop, depth)
        : undefined;

    // Convert percentages to mm of water
    currentWaterContentMm += vwc * stepSize;
    if (fieldCapacityMm !== undefined && fc !== undefined) {
      fieldCapacityMm += fc * stepSize;
    }
  }

  const additionalCapacityMm = fieldCapacityMm !== undefined ? fieldCapacityMm - currentWaterContentMm : undefined;

  return {
    currentWaterContentMm: currentWaterContentMm / 100,
    fieldCapacityMm: fieldCapacityMm !== undefined ? fieldCapacityMm / 100 : undefined,
    additionalCapacityMm: additionalCapacityMm !== undefined ? additionalCapacityMm / 100 : undefined,
  };
};

export const extractValuesMmLinearAverage = (props: {
  top: {
    fc: number | undefined;
    vwc: number | undefined;
    depthMm: number;
  };
  bot: {
    fc: number | undefined;
    vwc: number | undefined;
    depthMm: number;
  };
  depthOfInterestMm: number;
}): {
  currentWaterContentMm: number | undefined;
  fieldCapacityMm: number | undefined;
  additionalCapacityMm: number | undefined;
} => {
  const { top, bot, depthOfInterestMm } = props;
  const { vwc: vwcTop, fc: fcTop, depthMm: depthTop } = top;
  const { vwc: vwcBot, fc: fcBot, depthMm: depthBot } = bot;

  // Check if vwcTop or vwcBot is undefined
  if (vwcTop === undefined || vwcBot === undefined) {
    return {
      currentWaterContentMm: undefined,
      fieldCapacityMm: undefined,
      additionalCapacityMm: undefined,
    };
  }

  // Calculate VWC at depth of interest using linear interpolation
  const depthDifference = depthBot - depthTop;
  const vwcSlope = (vwcBot - vwcTop) / depthDifference;
  const vwcIntercept = vwcTop - vwcSlope * depthTop;
  const fcSlope = fcTop !== undefined && fcBot !== undefined ? (fcBot - fcTop) / depthDifference : undefined;
  const fcIntercept = fcTop !== undefined && fcSlope !== undefined ? fcTop - fcSlope * depthTop : undefined;

  const vwcAt0 = vwcIntercept;
  const vwcAtDepthOfInterest = vwcIntercept + vwcSlope * depthOfInterestMm;

  // Calculate average VWC
  console.log({ vwcAt0, vwcAtDepthOfInterest });
  const averageVwc = (vwcAt0 + vwcAtDepthOfInterest) / 2;

  // Calculate water content in mm
  const currentWaterContentMm = (averageVwc * depthOfInterestMm) / 1000;

  // Calculate field capacity if both fcTop and fcBot are defined
  let fieldCapacityMm: number | undefined;
  if (fcTop !== undefined && fcBot !== undefined && fcSlope !== undefined && fcIntercept !== undefined) {
    const fcAt0 = fcIntercept;
    const fcAtDepthOfInterest = fcIntercept + fcSlope * depthOfInterestMm;
    const averageFc = (fcAt0 + fcAtDepthOfInterest) / 2;
    fieldCapacityMm = (averageFc * depthOfInterestMm) / 1000;
  }

  const additionalCapacityMm = fieldCapacityMm !== undefined ? fieldCapacityMm - currentWaterContentMm : undefined;

  return {
    currentWaterContentMm,
    fieldCapacityMm,
    additionalCapacityMm,
  };
};

export default extractValuesPaw;
