import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, List, ListItem, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import Button from '@mui/material/Button';
import type { FC } from 'react';
import React, { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useUserStore } from '../../dataHandlers/RootStore';
import type { FarmSettingsUpdateFunc } from './index';
import {
  CustomList,
  FarmName,
  InstantNotificationChannel,
  WhatsappNotifications,
  WhatsappNumbers,
} from './inputComponents';
import type { IFarmSettings } from './settingsState';

type FarmUser = {
  uid?: string;
  email?: string | null;
  isAdmin?: boolean;
  isFarmAdmin: boolean;
};

const FarmSettingsComponent: FC<{
  state: IFarmSettings;
  updateState: FarmSettingsUpdateFunc;
}> = ({ state, updateState }) => {
  const intl = useIntl();
  const [newEmail, setNewEmail] = useState('');
  const [isAddingReader, setIsAddingReader] = useState(false);
  const [newUsers, setNewUsers] = useState<string[]>([]);

  const handleAddReader = async (email: string) => {
    updateState((prev) => ({
      ...prev,
      readers: [...(prev.readers ?? []), email],
      readerInfos: [...(prev.readerInfos ?? []), { id: 'invited', email, isAdmin: false }],
    }));
    setNewUsers((prev) => [...prev, email]);
    setNewEmail('');
    setIsAddingReader(false);
  };

  return (
    <>
      <CustomList
        // title='General'
        title={intl.formatMessage({ id: 'settings_general' })}
      >
        <FarmName value={state.farmName} update={updateState} />
        {/* <CountrySelector value={state.country} update={updateState} /> */}
      </CustomList>
      <CustomList
        // title='Notifications'
        title={intl.formatMessage({ id: 'settings_notifications' })}
      >
        <WhatsappNotifications value={state.enableWhatsappNotif} update={updateState} />
        {state.enableWhatsappNotif && <WhatsappNumbers value={state.phoneNumbers} update={updateState} />}
        {state.enableWhatsappNotif && (
          <InstantNotificationChannel value={state.instantNotificationChannel} update={updateState} />
        )}
      </CustomList>
      <CustomList
        title={intl.formatMessage({ id: 'settings_readers' })}
        action={
          isAddingReader ? (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                fontVariantCaps: 'normal',
                fontSize: '0.7rem',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            >
              <TextField
                size='small'
                style={{
                  fontSize: '0.7rem',
                  color: 'rgba(0, 0, 0, 0.6)',
                }}
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value.toLowerCase())}
                placeholder={intl.formatMessage({ id: 'enter_reader_email', defaultMessage: 'Email' })}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && newEmail) {
                    handleAddReader(newEmail);
                  }
                }}
                autoFocus
              />
              <IconButton
                onClick={() => handleAddReader(newEmail.toLowerCase())}
                color='primary'
                disabled={!newEmail}
                sx={{
                  backgroundColor: 'rgba(25, 118, 210, 0.04)',
                  '&:hover': {
                    backgroundColor: 'rgba(25, 118, 210, 0.1)',
                  },
                }}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                onClick={() => {
                  setIsAddingReader(false);
                  setNewEmail('');
                }}
                sx={{
                  '&:hover': {
                    backgroundColor: 'rgba(0, 0, 0, 0.04)',
                  },
                }}
              >
                <CloseIcon />
              </IconButton>
            </div>
          ) : (
            <Button
              startIcon={<AddIcon />}
              onClick={() => setIsAddingReader(true)}
              variant='text'
              sx={{ textTransform: 'none' }}
            >
              {intl.formatMessage({ id: 'add_reader', defaultMessage: 'Add reader' })}
            </Button>
          )
        }
      >
        <FarmReaders value={state.readerInfos ?? []} update={updateState} newUsers={newUsers} />
      </CustomList>
    </>
  );
};

export const FarmReaders: React.FC<{
  value: readonly { id: string; email: string; isAdmin?: boolean }[];
  update: FarmSettingsUpdateFunc;
  newUsers: string[];
}> = ({ value, update, newUsers }) => {
  const intl = useIntl();
  const userStore = useUserStore();
  const [deletedUsers, setDeletedUsers] = useState<string[]>([]);

  const filteredReaders = useMemo(() => {
    return value.filter((reader) => {
      const isSoilsenseEmail = reader.email.endsWith('@soilsense.io');
      const isCurrentUserSoilsense = userStore.currentUser?.email?.endsWith('@soilsense.io');

      if (isCurrentUserSoilsense) return true;

      return !isSoilsenseEmail;
    });
  }, [value, userStore.currentUser]);

  const [currentUserInfo, setCurrentUserInfo] = useState<FarmUser | null>(null);

  useEffect(() => {
    setCurrentUserInfo({
      uid: userStore.currentUser?.uid,
      email: userStore.currentUser?.email,
      isAdmin: userStore.currentUser?.isAdmin,
      isFarmAdmin: filteredReaders.find((reader) => reader.id === userStore.currentUser?.uid)?.isAdmin ?? false,
    });
  }, [filteredReaders, userStore.currentUser]);

  const handleRemoveReader = (readerId: string) => {
    const readerEmail = filteredReaders.find((reader) => reader.id === readerId)?.email;
    if (readerEmail) {
      setDeletedUsers((prev) => [...prev, readerEmail]);
    }
    update((prev) => ({
      ...prev,
      readers: prev.readers.filter((id) => id !== readerId),
      readerInfos: prev.readerInfos.filter((info) => info.id !== readerId),
    }));
  };

  const handleRoleChange = (readerId: string, isAdmin: boolean) => {
    update((prev) => ({
      ...prev,
      readerInfos: prev.readerInfos.map((info) => (info.id === readerId ? { ...info, isAdmin } : info)),
    }));
  };

  return (
    <List
      sx={{
        width: '100%',
        bgcolor: 'background.paper',
        borderRadius: 1,
        '& .MuiListItem-root': {
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
          '&:last-child': {
            borderBottom: 'none',
          },
        },
      }}
    >
      {filteredReaders.map((reader) => {
        const isNewUser = newUsers.includes(reader.id);
        const invitedMessage = isNewUser
          ? intl.formatMessage({ id: 'will_be_invited_on_save', defaultMessage: '(will be invited on save)' })
          : intl.formatMessage({ id: 'invited', defaultMessage: '(invited)' });

        return (
          <ListItem
            key={reader.id}
            secondaryAction={
              (currentUserInfo?.isAdmin || currentUserInfo?.isFarmAdmin) &&
              reader.email !== currentUserInfo?.email && (
                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                  <Select
                    size='small'
                    value={reader.isAdmin ? 'admin' : 'reader'}
                    onChange={(e) => handleRoleChange(reader.id, e.target.value === 'admin')}
                    sx={{
                      minWidth: 100,
                      '.MuiSelect-select': {
                        py: 0.5,
                        fontSize: '0.875rem',
                        color: 'rgba(0, 0, 0, 0.7)',
                      },
                      '.MuiOutlinedInput-notchedOutline': {
                        borderWidth: '1px',
                      },
                    }}
                  >
                    <MenuItem value='reader'>
                      {intl.formatMessage({ id: 'role_reader', defaultMessage: 'Reader' })}
                    </MenuItem>
                    <MenuItem value='admin'>
                      {intl.formatMessage({ id: 'role_admin', defaultMessage: 'Admin' })}
                    </MenuItem>
                  </Select>
                  <IconButton edge='end' onClick={() => handleRemoveReader(reader.id)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              )
            }
          >
            <ListItemText
              primary={reader.email}
              primaryTypographyProps={{
                style: {
                  color: 'rgba(0, 0, 0, 0.7)',
                  fontWeight: reader.email === currentUserInfo?.email ? 'bold' : 'normal',
                  textDecoration: 'none',
                },
              }}
              secondary={<>{reader.id === 'invited' ? invitedMessage : undefined}</>}
            />
          </ListItem>
        );
      })}
      {deletedUsers.map((email) => (
        <ListItem key={email}>
          <ListItemText
            primary={email}
            primaryTypographyProps={{ style: { color: 'rgba(0, 0, 0, 0.6)', textDecoration: 'line-through' } }}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default FarmSettingsComponent;
