// export const cropTypeOptions = [
//   {
//     label: 'Other (crop not on the list)',
//     low: 50,
//     high: 100,
//   },
//   ...[
//     {
//       label: 'Broccoli',
//       low: 55,
//       high: 100,
//     },
//     {
//       label: 'Brussel Sprouts',
//       low: 55,
//       high: 100,
//     },
//     {
//       label: 'Cabbage',
//       low: 55,
//       high: 100,
//     },
//     {
//       label: 'Carrots',
//       low: 65,
//       high: 100,
//     },
//     {
//       label: 'Cauliflower',
//       low: 55,
//       high: 100,
//     },
//     {
//       label: 'Celery',
//       low: 80,
//       high: 100,
//     },
//     {
//       label: 'Garlic',
//       low: 70,
//       high: 100,
//     },
//     {
//       label: 'Lettuce',
//       low: 70,
//       high: 100,
//     },
//     {
//       label: 'Onions',
//       low: 70,
//       high: 100,
//     },
//     {
//       label: 'Spinach',
//       low: 80,
//       high: 100,
//     },
//     {
//       label: 'Radishes',
//       low: 70,
//       high: 100,
//     },
//     {
//       label: 'Egg Plant',
//       low: 55,
//       high: 100,
//     },
//     {
//       label: 'Sweet Peppers (bell)',
//       low: 70,
//       high: 100,
//     },
//     {
//       label: 'Tomato',
//       low: 60,
//       high: 100,
//     },
//     {
//       label: 'Cucumber',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Pumpkin',
//       low: 65,
//       high: 100,
//     },
//     {
//       label: 'Squash, Zucchini',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Sweet Melons',
//       low: 60,
//       high: 100,
//     },
//     {
//       label: 'Watermelon',
//       low: 60,
//       high: 100,
//     },
//     {
//       label: 'Beets, table',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Cassava',
//       low: 65,
//       high: 100,
//     },
//     {
//       label: 'Parsnip',
//       low: 60,
//       high: 100,
//     },
//     {
//       label: 'Potato',
//       low: 65,
//       high: 100,
//     },
//     {
//       label: 'Sweet Potato',
//       low: 35,
//       high: 100,
//     },
//     {
//       label: 'Turnip (and Rutabaga)',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Sugar Beet',
//       low: 45,
//       high: 100,
//     },
//     {
//       label: 'Beans',
//       low: 55,
//       high: 100,
//     },
//     {
//       label: 'Chickpea',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Fababean (broad bean)',
//       low: 55,
//       high: 100,
//     },
//     {
//       label: 'Green Gram and Cowpeas',
//       low: 55,
//       high: 100,
//     },
//     {
//       label: 'Groundnut (Peanut)',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Lentils',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Peas',
//       low: 65,
//       high: 100,
//     },
//     {
//       label: 'Soybeans',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Artichokes',
//       low: 55,
//       high: 100,
//     },
//     {
//       label: 'Asparagus',
//       low: 55,
//       high: 100,
//     },
//     {
//       label: 'Mint',
//       low: 60,
//       high: 100,
//     },
//     {
//       label: 'Strawberries',
//       low: 80,
//       high: 100,
//     },
//     {
//       label: 'Cotton',
//       low: 35,
//       high: 100,
//     },
//     {
//       label: 'Flax',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Sisal',
//       low: 20,
//       high: 100,
//     },
//     {
//       label: 'Castorbean (Ricinus)',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Rapeseed, Canola',
//       low: 40,
//       high: 100,
//     },
//     {
//       label: 'Safflower',
//       low: 40,
//       high: 100,
//     },
//     {
//       label: 'Sesame',
//       low: 40,
//       high: 100,
//     },
//     {
//       label: 'Sunflower',
//       low: 55,
//       high: 100,
//     },
//     {
//       label: 'Barley',
//       low: 45,
//       high: 100,
//     },
//     {
//       label: 'Oats',
//       low: 45,
//       high: 100,
//     },
//     {
//       label: 'Spring Wheat',
//       low: 45,
//       high: 100,
//     },
//     {
//       label: 'Winter Wheat',
//       low: 45,
//       high: 100,
//     },
//     {
//       label: 'Maize, Field (grain) (field corn)',
//       low: 45,
//       high: 100,
//     },
//     {
//       label: 'Maize, Sweet (sweet corn)',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Millet',
//       low: 45,
//       high: 100,
//     },
//     {
//       label: 'Sorghum',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Rice',
//       low: 80,
//       high: 100,
//     },
//     {
//       label: 'Alfalfa',
//       low: 45,
//       high: 100,
//     },
//     {
//       label: 'Bermuda',
//       low: 45,
//       high: 100,
//     },
//     {
//       label: 'Clover hay, Berseem',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Rye Grass hay',
//       low: 40,
//       high: 100,
//     },
//     {
//       label: 'Grazing Pasture',
//       low: 40,
//       high: 100,
//     },
//     {
//       label: 'Turf grass',
//       low: 55,
//       high: 100,
//     },
//     {
//       label: 'Sugar Cane',
//       low: 35,
//       high: 100,
//     },
//     {
//       label: 'Banana',
//       low: 65,
//       high: 100,
//     },
//     {
//       label: 'Cacao',
//       low: 70,
//       high: 100,
//     },
//     {
//       label: 'Coffee',
//       low: 60,
//       high: 100,
//     },
//     {
//       label: 'Date Palms',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Palm Trees',
//       low: 35,
//       high: 100,
//     },
//     {
//       label: 'Pineapple',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Rubber Trees',
//       low: 60,
//       high: 100,
//     },
//     {
//       label: 'Tea (non-shaded)',
//       low: 60,
//       high: 100,
//     },
//     {
//       label: 'Tea (shaded)',
//       low: 55,
//       high: 100,
//     },
//     {
//       label: 'Berries (bushes)',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Grapes (table or raisin)',
//       low: 65,
//       high: 100,
//     },
//     {
//       label: 'Grapes (Wine)',
//       low: 55,
//       high: 100,
//     },
//     {
//       label: 'Hops',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Almonds',
//       low: 60,
//       high: 100,
//     },
//     {
//       label: 'Apples',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Cherries',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Pears',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Peaches',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Apricots',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Genera Stone Fruit',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Avocado',
//       low: 30,
//       high: 100,
//     },
//     {
//       label: 'Citrus',
//       low: 50,
//       high: 100,
//     },
//     {
//       label: 'Conifer Trees',
//       low: 30,
//       high: 100,
//     },
//     {
//       label: 'Kiwi',
//       low: 65,
//       high: 100,
//     },
//     {
//       label: 'Olives',
//       low: 35,
//       high: 100,
//     },
//     {
//       label: 'Pistachios',
//       low: 60,
//       high: 100,
//     },
//     {
//       label: 'Walnuts',
//       low: 50,
//       high: 100,
//     },
//   ].sort((a, b) => a.label.localeCompare(b.label)),
// ];

export const cropsEnglishMessages = {
  Broccoli: 'Broccoli',
  'Brussel Sprouts': 'Brussel Sprouts',
  Cabbage: 'Cabbage',
  Carrots: 'Carrots',
  Cauliflower: 'Cauliflower',
  Celery: 'Celery',
  Garlic: 'Garlic',
  Lettuce: 'Lettuce',
  Onions: 'Onions',
  Spinach: 'Spinach',
  Radishes: 'Radishes',
  'Egg Plant': 'Egg Plant',
  'Sweet Peppers (bell)': 'Sweet Peppers (bell)',
  Tomato: 'Tomato',
  Cucumber: 'Cucumber',
  Pumpkin: 'Pumpkin',
  'Squash, Zucchini': 'Squash, Zucchini',
  'Sweet Melons': 'Sweet Melons',
  Watermelon: 'Watermelon',
  'Beets, table': 'Beets, table',
  Cassava: 'Cassava',
  Parsnip: 'Parsnip',
  Potato: 'Potato',
  'Sweet Potato': 'Sweet Potato',
  'Turnip (and Rutabaga)': 'Turnip (and Rutabaga)',
  'Sugar Beet': 'Sugar Beet',
  Beans: 'Beans',
  Chickpea: 'Chickpea',
  'Fababean (broad bean)': 'Fababean (broad bean)',
  'Green Gram and Cowpeas': 'Green Gram and Cowpeas',
  'Groundnut (Peanut)': 'Groundnut (Peanut)',
  Lentils: 'Lentils',
  Peas: 'Peas',
  Soybeans: 'Soybeans',
  Artichokes: 'Artichokes',
  Asparagus: 'Asparagus',
  Mint: 'Mint',
  Strawberries: 'Strawberries',
  Cotton: 'Cotton',
  Flax: 'Flax',
  Sisal: 'Sisal',
  'Castorbean (Ricinus)': 'Castorbean (Ricinus)',
  'Rapeseed, Canola': 'Rapeseed, Canola',
  Safflower: 'Safflower',
  Sesame: 'Sesame',
  Sunflower: 'Sunflower',
  Barley: 'Barley',
  Oats: 'Oats',
  'Spring Wheat': 'Spring Wheat',
  'Winter Wheat': 'Winter Wheat',
  'Maize, Field (grain) (field corn)': 'Maize, Field (grain) (field corn)',
  'Maize, Sweet (sweet corn)': 'Maize, Sweet (sweet corn)',
  Millet: 'Millet',
  Sorghum: 'Sorghum',
  Rice: 'Rice',
  Alfalfa: 'Alfalfa',
  Bermuda: 'Bermuda',
  Citrus: 'Citrus',
  'Conifer Trees': 'Conifer Trees',
  Kiwi: 'Kiwi',
  Olives: 'Olives',
  Pistachios: 'Pistachios',
  Walnuts: 'Walnuts',
  'Other (crop not on the list)': 'Other (crop not on the list)',
  Peaches: 'Peaches',
  Apricots: 'Apricots',
  'Genera Stone Fruit': 'Genera Stone Fruit',
  Avocado: 'Avocado',
  'Clover hay, Berseem': 'Clover hay, Berseem',
  Ryegrass: 'Ryegrass',
  'Grazing Pasture': 'Grazing Pasture',
  'Turf grass': 'Turf grass',
  'Sugar Cane': 'Sugar Cane',
  Banana: 'Banana',
  Cacao: 'Cacao',
  Coffee: 'Coffee',
  'Date Palms': 'Date Palms',
  'Palm Trees': 'Palm Trees',
  Pineapple: 'Pineapple',
  'Rubber Trees': 'Rubber Trees',
  'Tea (non-shaded)': 'Tea (non-shaded)',
  'Tea (shaded)': 'Tea (shaded)',
  'Berries (bushes)': 'Berries (bushes)',
  'Grapes (table or raisin)': 'Grapes (table or raisin)',
  'Grapes (Wine)': 'Grapes (Wine)',
  Hops: 'Hops',
  Almonds: 'Almonds',
  Apples: 'Apples',
  Cherries: 'Cherries',
  Pears: 'Pears',
};

export const cropsGermanMessages = {
  Broccoli: 'Brokkoli',
  'Brussel Sprouts': 'Rosenkohl',
  Cabbage: 'Kohl',
  Carrots: 'Karotten',
  Cauliflower: 'Blumenkohl',
  Celery: 'Sellerie',
  Garlic: 'Knoblauch',
  Lettuce: 'Salat',
  Onions: 'Zwiebeln',
  Spinach: 'Spinat',
  Radishes: 'Radieschen',
  'Egg Plant': 'Aubergine',
  'Sweet Peppers (bell)': 'Süße Paprika (Glocke)',
  Tomato: 'Tomate',
  Cucumber: 'Gurke',
  Pumpkin: 'Kürbis',
  'Squash, Zucchini': 'Kürbis, Zucchini',
  'Sweet Melons': 'Süße Melonen',
  Watermelon: 'Wassermelone',
  'Beets, table': 'Rüben, Tisch',
  Cassava: 'Kassava',
  Parsnip: 'Pastinake',
  Potato: 'Kartoffel',
  'Sweet Potato': 'Süßkartoffel',
  'Turnip (and Rutabaga)': 'Rübe (und Steckrübe)',
  'Sugar Beet': 'Zuckerrübe',
  Beans: 'Bohnen',
  Chickpea: 'Kichererbse',
  'Fababean (broad bean)': 'Ackerbohne (dicke Bohne)',
  'Green Gram and Cowpeas': 'Mungbohne und Augenbohne',
  'Groundnut (Peanut)': 'Erdnuss',
  Lentils: 'Linsen',
  Peas: 'Erbsen',
  Soybeans: 'Sojabohnen',
  Artichokes: 'Artischocken',
  Asparagus: 'Spargel',
  Mint: 'Minze',
  Strawberries: 'Erdbeeren',
  Cotton: 'Baumwolle',
  Flax: 'Flachs',
  Sisal: 'Sisal',
  'Castorbean (Ricinus)': 'Rizinus',
  'Rapeseed, Canola': 'Raps, Canola',
  Safflower: 'Färberdistel',
  Sesame: 'Sesam',
  Sunflower: 'Sonnenblume',
  Barley: 'Gerste',
  Oats: 'Hafer',
  'Spring Wheat': 'Frühjahr Weizen',
  'Winter Wheat': 'Winterweizen',
  'Maize, Field (grain) (field corn)': 'Mais, Feld (Korn) (Feldmais)',
  'Maize, Sweet (sweet corn)': 'Mais, Süß (süßer Mais)',
  Millet: 'Hirse',
  Sorghum: 'Sorghum',
  Rice: 'Reis',
  Alfalfa: 'Luzerne',
  Bermuda: 'Bermuda',
  Citrus: 'Zitrus',
  'Conifer Trees': 'Nadelbäume',
  Kiwi: 'Kiwi',
  Olives: 'Oliven',
  Pistachios: 'Pistazien',
  Walnuts: 'Walnüsse',
  'Other (crop not on the list)': 'Andere (Ernte nicht auf der Liste)',
  Peaches: 'Pfirsiche',
  Apricots: 'Aprikosen',
  'Genera Stone Fruit': 'Steinobst',
  Avocado: 'Avocado',
  'Clover hay, Berseem': 'Klee Heu, Berseem',
  Ryegrass: 'Ryegrass',
  'Grazing Pasture': 'Weidefläche',
  'Turf grass': 'Rasen',
  'Sugar Cane': 'Zuckerrohr',
  Banana: 'Banane',
  Cacao: 'Kakao',
  Coffee: 'Kaffee',
  'Date Palms': 'Dattelpalmen',
  'Palm Trees': 'Palmbäume',
  Pineapple: 'Ananas',
  'Rubber Trees': 'Kautschukbäume',
  'Tea (non-shaded)': 'Tee (nicht beschattet)',
  'Tea (shaded)': 'Tee (beschattet)',
  'Berries (bushes)': 'Beeren (Büsche)',
  'Grapes (table or raisin)': 'Trauben (Tisch oder Rosine)',
  'Grapes (Wine)': 'Trauben (Wein)',
  Hops: 'Hopfen',
  Almonds: 'Mandeln',
  Apples: 'Äpfel',
  Cherries: 'Kirschen',
  Pears: 'Birnen',
};

export const cropsSpanishMessages = {
  Broccoli: 'Brócoli',
  'Brussel Sprouts': 'Col de Bruselas',
  Cabbage: 'Repollo',
  Carrots: 'Zanahorias',
  Cauliflower: 'Coliflor',
  Celery: 'Apio',
  Garlic: 'Ajo',
  Lettuce: 'Lechuga',
  Onions: 'Cebollas',
  Spinach: 'Espinacas',
  Radishes: 'Rábanos',
  'Egg Plant': 'Berenjena',
  'Sweet Peppers (bell)': 'Pimientos dulces (bell)',
  Tomato: 'Tomate',
  Cucumber: 'Pepino',
  Pumpkin: 'Calabaza',
  'Squash, Zucchini': 'Calabacín',
  'Sweet Melons': 'Melones dulces',
  Watermelon: 'Sandía',
  'Beets, table': 'Remolacha de mesa',
  Cassava: 'Yuca',
  Parsnip: 'Chirivía',
  Potato: 'Papa',
  'Sweet Potato': 'Camote',
  'Turnip (and Rutabaga)': 'Nabo (y colinabo)',
  'Sugar Beet': 'Remolacha azucarera',
  Beans: 'Frijoles',
  Chickpea: 'Garbanzo',
  'Fababean (broad bean)': 'Haba',
  'Green Gram and Cowpeas': 'Frijoles verdes y caupí',
  'Groundnut (Peanut)': 'Cacahuete',
  Lentils: 'Lentejas',
  Peas: 'Chícharos',
  Soybeans: 'Soja',
  Artichokes: 'Alcachofas',
  Asparagus: 'Espárragos',
  Mint: 'Menta',
  Strawberries: 'Fresas',
  Cotton: 'Algodón',
  Flax: 'Lino',
  Sisal: 'Sisal',
  'Castorbean (Ricinus)': 'Ricino',
  'Rapeseed, Canola': 'Colza, Canola',
  Safflower: 'Cártamo',
  Sesame: 'Sésamo',
  Sunflower: 'Girasol',
  Barley: 'Cebada',
  Oats: 'Avena',
  'Spring Wheat': 'Trigo de primavera',
  'Winter Wheat': 'Trigo de invierno',
  'Maize, Field (grain) (field corn)': 'Maíz de campo (grano) (maíz de campo)',
  'Maize, Sweet (sweet corn)': 'Maíz dulce (maíz dulce)',
  Millet: 'Mijo',
  Sorghum: 'Sorgo',
  Rice: 'Arroz',
  Alfalfa: 'Alfalfa',
  Bermuda: 'Bermuda',
  Citrus: 'Cítricos',
  'Conifer Trees': 'Árboles coníferos',
  Kiwi: 'Kiwi',
  Olives: 'Aceitunas',
  Pistachios: 'Pistachos',
  Walnuts: 'Nueces',
  'Other (crop not on the list)': 'Otro (cultivo no en la lista)',
  Peaches: 'Melocotones',
  Apricots: 'Albaricoques',
  'Genera Stone Fruit': 'Fruta de hueso genera',
  Avocado: 'Aguacate',
  'Clover hay, Berseem': 'Heno de trébol, Berseem',
  Ryegrass: 'Raygrass',
  'Grazing Pasture': 'Pasto de pastoreo',
  'Turf grass': 'Césped',
  'Sugar Cane': 'Caña de azúcar',
  Banana: 'Plátano',
  Cacao: 'Cacao',
  Coffee: 'Café',
  'Date Palms': 'Palmeras datileras',
  'Palm Trees': 'Palmeras',
  Pineapple: 'Piña',
  'Rubber Trees': 'Árboles de caucho',
  'Tea (non-shaded)': 'Té (sin sombra)',
  'Tea (shaded)': 'Té (sombreado)',
  'Berries (bushes)': 'Bayas (arbustos)',
  'Grapes (table or raisin)': 'Uvas (mesa o pasa)',
  'Grapes (Wine)': 'Uvas (vino)',
  Hops: 'Lúpulo',
  Almonds: 'Almendras',
  Apples: 'Manzanas',
  Cherries: 'Cerezas',
  Pears: 'Peras',
};

export const cropsDanishMessages = {
  Broccoli: 'Broccoli',
  'Brussel Sprouts': 'Brusselspirer',
  Cabbage: 'Kål',
  Carrots: 'Gulerødder',
  Cauliflower: 'Blomkål',
  Celery: 'Selleri',
  Garlic: 'Hvidløg',
  Lettuce: 'Salat',
  Onions: 'Løg',
  Spinach: 'Spinat',
  Radishes: 'Radiser',
  'Egg Plant': 'Aubergine',
  'Sweet Peppers (bell)': 'Søde peberfrugter (bell)',
  Tomato: 'Tomat',
  Cucumber: 'Agurk',
  Pumpkin: 'Græskar',
  'Squash, Zucchini': 'Squash, Zucchini',
  'Sweet Melons': 'Søde meloner',
  Watermelon: 'Vandmelon',
  'Beets, table': 'Rødbeder, bord',
  Cassava: 'Kassava',
  Parsnip: 'Pastinak',
  Potato: 'Kartoffel',
  'Sweet Potato': 'Søde kartofler',
  'Turnip (and Rutabaga)': 'Kålroe (og kålrabi)',
  'Sugar Beet': 'Sukkerroe',
  Beans: 'Bønner',
  Chickpea: 'Kikærter',
  'Fababean (broad bean)': 'Favabønner (bred bønne)',
  'Green Gram and Cowpeas': 'Grønne gram og cowpeas',
  'Groundnut (Peanut)': 'Jordnød',
  Lentils: 'Linser',
  Peas: 'Ærter',
  Soybeans: 'Soyabønner',
  Artichokes: 'Artiskokker',
  Asparagus: 'Asparges',
  Mint: 'Mynte',
  Strawberries: 'Jordbær',
  Cotton: 'Bomuld',
  Flax: 'Hør',
  Sisal: 'Sisal',
  'Castorbean (Ricinus)': 'Ricinus',
  'Rapeseed, Canola': 'Raps, Canola',
  Safflower: 'Safflower',
  Sesame: 'Sesam',
  Sunflower: 'Solsikke',
  Barley: 'Byg',
  Oats: 'Havre',
  'Spring Wheat': 'Forårs hvede',
  'Winter Wheat': 'Vinter hvede',
  'Maize, Field (grain) (field corn)': 'Majs, mark (korn) (mark majs)',
  'Maize, Sweet (sweet corn)': 'Majs, sød (sød majs)',
  Millet: 'Hirse',
  Sorghum: 'Sorghum',
  Rice: 'Ris',
  Alfalfa: 'Lucerne',
  Bermuda: 'Bermuda',
  Citrus: 'Citrus',
  'Conifer Trees': 'Nåletræer',
  Kiwi: 'Kiwi',
  Olives: 'Oliven',
  Pistachios: 'Pistacienødder',
  Walnuts: 'Valnødder',
  'Other (crop not on the list)': 'Andet (afgrøde ikke på listen)',
  Peaches: 'Ferskner',
  Apricots: 'Abrikoser',
  'Genera Stone Fruit': 'Genera Stone Fruit',
  Avocado: 'Avocado',
  'Clover hay, Berseem': 'Kløver hø, Berseem',
  Ryegrass: 'Ryegrass',
  'Grazing Pasture': 'Græsgang',
  'Turf grass': 'Turf græs',
  'Sugar Cane': 'Sukkerrør',
  Banana: 'Banana',
  Cacao: 'Kakao',
  Coffee: 'Kaffe',
  'Date Palms': 'Dato palmer',
  'Palm Trees': 'Palmer',
  Pineapple: 'Ananas',
  'Rubber Trees': 'Gummitræer',
  'Tea (non-shaded)': 'Te (ikke-skygge)',
  'Tea (shaded)': 'Te (skygge)',
  'Berries (bushes)': 'Bær (buske)',
  'Grapes (table or raisin)': 'Druer (bord eller rosiner)',
  'Grapes (Wine)': 'Druer (vin)',
  Hops: 'Humle',
  Almonds: 'Mandler',
  Apples: 'Æbler',
  Cherries: 'Kirsebær',
  Pears: 'Pærer',
};

export const cropsItalianMessages = {
  Broccoli: 'Broccoli',
  'Brussel Sprouts': 'Cavoletti di Bruxelles',
  Cabbage: 'Cavolo',
  Carrots: 'Carote',
  Cauliflower: 'Cavolfiore',
  Celery: 'Sedano',
  Garlic: 'Aglio',
  Lettuce: 'Lattuga',
  Onions: 'Cipolle',
  Spinach: 'Spinaci',
  Radishes: 'Ravanelli',
  'Egg Plant': 'Melanzana',
  'Sweet Peppers (bell)': 'Peperoni dolci',
  Tomato: 'Pomodoro',
  Cucumber: 'Cetriolo',
  Pumpkin: 'Zucca',
  'Squash, Zucchini': 'Zucchine',
  'Sweet Melons': 'Meloni dolci',
  Watermelon: 'Anguria',
  'Beets, table': 'Barbabietole da tavola',
  Cassava: 'Manioca',
  Parsnip: 'Pastinaca',
  Potato: 'Patata',
  'Sweet Potato': 'Patata dolce',
  'Turnip (and Rutabaga)': 'Rapa (e Rutabaga)',
  'Sugar Beet': 'Barbabietola da zucchero',
  Beans: 'Fagioli',
  Chickpea: 'Ceci',
  'Fababean (broad bean)': 'Fave',
  'Green Gram and Cowpeas': "Fagiolo mungo e Fagiolini dall'occhio",
  'Groundnut (Peanut)': 'Arachidi',
  Lentils: 'Lenticchie',
  Peas: 'Piselli',
  Soybeans: 'Soia',
  Artichokes: 'Carciofi',
  Asparagus: 'Asparagi',
  Mint: 'Menta',
  Strawberries: 'Fragole',
  Cotton: 'Cotone',
  Flax: 'Lino',
  Sisal: 'Sisal',
  'Castorbean (Ricinus)': 'Ricino',
  'Rapeseed, Canola': 'Colza',
  Safflower: 'Cartamo',
  Sesame: 'Sesamo',
  Sunflower: 'Girasole',
  Barley: 'Orzo',
  Oats: 'Avena',
  Ryegrass: 'Erba medica',
  'Spring Wheat': 'Grano primaverile',
  'Winter Wheat': 'Grano invernale',
  'Maize, Field (grain) (field corn)': 'Mais da campo (granella)',
  'Maize, Sweet (sweet corn)': 'Mais dolce',
  Millet: 'Miglio',
  Sorghum: 'Sorgo',
  Rice: 'Riso',
  Alfalfa: 'Erba medica',
  Bermuda: 'Gramigna',
  Citrus: 'Agrumi',
  'Conifer Trees': 'Conifere',
  Kiwi: 'Kiwi',
  Olives: 'Olive',
  Pistachios: 'Pistacchi',
  Walnuts: 'Noci',
  'Other (crop not on the list)': 'Altro (coltura non in elenco)',
  Peaches: 'Pesche',
  Apricots: 'Albicocche',
  'Genera Stone Fruit': 'Frutta con nocciolo',
  Avocado: 'Avocado',
  'Clover hay, Berseem': 'Fieno di trifoglio, Bersim',
  'Grazing Pasture': 'Pascolo',
  'Turf grass': 'Erba da prato',
  'Sugar Cane': 'Canna da zucchero',
  Banana: 'Banana',
  Cacao: 'Cacao',
  Coffee: 'Caffè',
  'Date Palms': 'Palme da datteri',
  'Palm Trees': 'Palme',
  Pineapple: 'Ananas',
  'Rubber Trees': 'Alberi della gomma',
  'Tea (non-shaded)': 'Tè (non ombreggiato)',
  'Tea (shaded)': 'Tè (ombreggiato)',
  'Berries (bushes)': 'Bacche (cespugli)',
  'Grapes (table or raisin)': 'Uva (da tavola o passa)',
  'Grapes (Wine)': 'Uva (da vino)',
  Hops: 'Luppolo',
  Almonds: 'Mandorle',
  Apples: 'Mele',
  Cherries: 'Ciliegie',
  Pears: 'Pere',
};

// check that all arrays contain the same keys
const arrays = [
  cropsEnglishMessages,
  cropsDanishMessages,
  cropsGermanMessages,
  cropsSpanishMessages,
  cropsItalianMessages,
];
const keys = arrays.map((arr) => Object.keys(arr));
for (const key of keys[0]) {
  if (!keys.every((k) => k.includes(key))) {
    throw new Error(`Key ${key} not found in all arrays`);
  }
}
