import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { OverlayView, Polygon } from '@react-google-maps/api';
import type { Coordinates } from '@soilsense/shared';
import React, { useMemo } from 'react';
import type { FieldWithId } from '../../interfaces/Field';
import { highlightColor } from '../../utils/consts';
import type { FieldColor } from '../../utils/getMarkerColorFromReadings';

type FieldLabelStyleProps = Readonly<{
  color: FieldColor;
}>;

type FieldLabelProps = Readonly<
  FieldLabelStyleProps & {
    coordinates: Coordinates;
    label: string;
  }
>;

const useStyles = makeStyles({
  fieldLabel: ({ color }: FieldLabelStyleProps) => ({
    color,
    padding: '3px 5px',
    borderRadius: '5px',
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    display: 'block',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    whiteSpace: 'nowrap',
  }),
});

const FieldLabel: React.FC<FieldLabelProps> = ({ coordinates, label, color }) => {
  const classes = useStyles({ color });

  if (!google) {
    return null;
  }

  return (
    <OverlayView mapPaneName='overlayMouseTarget' bounds={new google.maps.LatLngBounds(coordinates, coordinates)}>
      <Typography className={classes.fieldLabel}>{label}</Typography>
    </OverlayView>
  );
};

type Props = Readonly<{
  field: FieldWithId;
  center: Coordinates | undefined;
  strokeColor: FieldColor;
  fillColor: FieldColor;
  zoom?: number;
  selected?: boolean;
  editable?: boolean;
  onLoad?: (polygon: google.maps.Polygon) => void;
  onClick?: () => void;
  onEdit?: () => void;
}>;

export function FieldShape({
  field,
  center,
  strokeColor,
  fillColor,
  zoom,
  selected,
  editable,
  onLoad,
  onClick,
  onEdit,
}: Props): JSX.Element {
  // make a copy of the field coordinates just to make geolib / Google Maps types happy
  const path: Coordinates[] = useMemo(() => [...field.path], [field]);

  return (
    <>
      {selected ? (
        (
          [
            [1, 1.0],
            [3, 0.7],
            [7, 0.5],
          ] as const
        ).map(([strokeWeight, strokeOpacity], index) => (
          <Polygon
            key={strokeWeight}
            options={{
              strokeWeight,
              strokeColor: highlightColor(1.0),
              strokeOpacity,
              fillColor,
              fillOpacity: index == 0 ? undefined : 0.0,
            }}
            path={path}
            onLoad={onLoad}
            onClick={onClick}
          />
        ))
      ) : (
        <Polygon
          options={{ strokeWeight: 3, strokeColor, fillColor }}
          path={path}
          onLoad={onLoad}
          onClick={onClick}
          editable={editable}
          onMouseUp={onEdit}
        />
      )}
      {field.name.trim() != '' && center != undefined && (zoom ?? 16) >= 16 && (
        <FieldLabel label={field.name} coordinates={center} color={strokeColor} />
      )}
    </>
  );
}
